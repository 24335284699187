import { BASE_PATH_API, TIMEOUT_TIME, VARIANTS_PER_PORTAL } from 'constants/general'
import { decodeStorage } from 'functions/storageBase64'
import axios from 'axios'
import { getCartAdapterOld } from 'adapters/cart_Adapter'


//- Get Cart
export async function getCart () {
    const codigoPostal  = decodeStorage('codigoPostal')
    const byCart = decodeStorage('idCarrito')

    //const apiURL = `${BASE_PATH_API}/app/v1/cart/${byCart}${byCP}`
    const apiURL = `${BASE_PATH_API}/app/v1/cart/${byCart}`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    }

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        let newData = getCartAdapterOld(data)
        return {
            'headers': headers,
            'statusCode': status,
            'data': newData,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}


//- Get Count Card
export async function getCartCount () {
    const byCart = decodeStorage('idCarrito')
    
    const apiURL = `${BASE_PATH_API}/app/v1/cart-counter/${byCart}`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME
    }

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        // console.log(error)
        // console.log(error.request)
        // console.log(error.request.status)
        // console.log(error.request.response)
        // console.log(error.response)
        // console.log(error.response.data)
        // console.log(error.response.data.msg)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response
        }
    })
}



//- New Cart Services
export async function getCartNew () {
    // const codigoPostal  = decodeStorage('codigoPostal')
    const accessToken = "Bearer " + decodeStorage("access_token");
    const apiURL = `${BASE_PATH_API}/cart/api/v1`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        }
    }

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}

export async function postCartNew (productsArray) {
    // const codigoPostal  = decodeStorage('codigoPostal')
    const byCart = decodeStorage('idCarrito')
    const accessToken = "Bearer " + decodeStorage("access_token");
    const apiURL = `${BASE_PATH_API}/cart/api/v1`

    const newData = {
        "owner": byCart,
        "products": productsArray
    }

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
        data: newData
    }

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}

export async function putCartNew (productsArray) {
    // const codigoPostal  = decodeStorage('codigoPostal')
    const byCart = decodeStorage('idCarrito')
    const accessToken = "Bearer " + decodeStorage("access_token");
    const apiURL = `${BASE_PATH_API}/cart/api/v1`

    const newData = {
        "owner": byCart,
        "products": productsArray
    }

    const config = {
        method: 'put',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
        data: newData
    }

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}

export async function getCartProduct (idProducts) {
    // const codigoPostal  = decodeStorage('codigoPostal')
    // const byCart = decodeStorage('idCarrito')
    // const accessToken = "Bearer " + decodeStorage("access_token");

    const apiURL = `${BASE_PATH_API}/products/v1/products?filter_by[id]=${idProducts}&page_size=${idProducts ? idProducts.split(',')?.length : 20}`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    }

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}