import React from 'react'
import { MenuCategoryHeader } from "components/Header/MenuCategory"
import { Link } from "react-router-dom"

export function CategoryMenuHeader() {

    return(
        <nav className="categoryMenuHeader">
            <ul>
                <li className="productosNewCat">
                    <Link to="/categoria/">Categorías</Link>
                    <MenuCategoryHeader />
                </li>
            </ul>
        </nav>
    )
}