import React from 'react'
import { TemplateLandingAD, CardLandingAD } from '../TemplateLanding'
import '../style.sass'

export default function PoliticaDev2() {

    return (
        <>
            <TemplateLandingAD
                title={"¿Cómo podemos ayudarte?"}
                landing={true}
            >
                <CardLandingAD
                    tituloLanding={"¿Cómo cancelar mi producto?"}
                >
                    <p>Lamentamos informarte que no es posible cancelar tu pedido en este momento, ya que el artículo que has adquirido se encuentra en camino o en alguna condición de tránsito. Te recomendamos esperar a que el producto llegue a tu domicilio y realizar el proceso de devolución. En caso de que existan rechazos en la entrega debido al portaje hay que seguir un protocolo que se procederá con el reembolso correspondiente.</p>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿En cuánto tiempo me entregan mi pedido?"}
                >
                    <p>Puedes consultar la fecha estimada de entrega dentro de tu pedidos ingresando a <span className='textRed'>Mi Cuenta</span>. También se te enviarán correos con el progreso de tus pedidos y el seguimiento a cada uno de tus productos.</p>
                    <a href="https://www.claroshop.com/mi-cuenta" target="_blank" rel="noopener noreferrer" className='linkLandingAD'>https://www.claroshop.com/mi-cuenta/</a>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿Me informaron que mi pedido ya fue aprobado, ¿Cuánto tiempo tardaré en recibir mis artículos?"}
                >
                    <p>Si tu pedido ya fue aprobado, te notificaremos vía correo electrónico sobre el avance, también puedes dar seguimiento a tu pedido ingresando a la sección de Mis Pedidos en <span className='textRed'>Mi Cuenta</span>.</p>
                    <a href="https://www.claroshop.com/mi-cuenta" target="_blank" rel="noopener noreferrer" className='linkLandingAD'>https://www.claroshop.com/mi-cuenta/</a>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿Puedo solicitar el cambio de mi dirección de entrega?"}
                >
                    <p>Una vez tu pedido ha sido procesado este cambio de dirección no es posible, sin embargo, antes de que tu pedido haya sido procesado y haya sido enviado, puedes ingresar en el apartado de mi cuenta y en mis direcciones de envío las puedes modificar las direcciones de envío.</p>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿Cómo van a entregarme mi pedido?"}
                >
                    <p>Enviamos los productos por diversos mensajeros, para consultar cuál es el que llevará tus pedidos puedes ingresar a la sección de Mis Pedidos en <span className='textRed'>Mi Cuenta</span>. Aquí podrás conocer el número de rastreo con el que podrás dar seguimiento a la entrega.</p>
                    <a href="https://www.claroshop.com/mi-cuenta" target="_blank" rel="noopener noreferrer" className='linkLandingAD'>https://www.claroshop.com/mi-cuenta/</a>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿Qué hago si un envío excedió el tiempo de entrega?"}
                >
                    <p>Te invitamos a comunicarte a nuestro CAT para ayudarte con tu solicitud.</p>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿Con qué guía van a entregar mi pedido?"}
                >
                    <p>La guía se te hará llegar a través de correo electrónico, con ese número de rastreo podrás dar seguimiento por medio de los portales de las paqueterías.</p>
                </CardLandingAD>

            </TemplateLandingAD>
        </>
    )
}