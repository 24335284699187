import React from 'react'
import './style.sass'
import LazyLoad from 'react-lazy-load'
import { BASE_PATH_RESOURCES, LAZY_L } from 'constants/general'

function MetodosPago(){
    return(
        <div className="nhmetodospago">
            <span>Formas de pago</span>
            <div className="mplogos">
                {/*LAZY_L
                    ?
                        <LazyLoad height={15} width={107} offset={100} threshold={0.95}>
                            <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-telmex-gray.svg`} loading="lazy" alt="icon-telmex" width="107" height="15" fetchpriority="low" />
                        </LazyLoad>
                    :
                        <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-telmex-gray.svg`} loading="lazy" alt="icon-telmex" width="107" height="15" fetchpriority="low" />
                */}
                {LAZY_L
                    ?
                        <LazyLoad height={15} width={55} offset={100} threshold={0.95}>
                            <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-sears-gray.svg`} loading="lazy" alt="icon-sears" width="55" height="15" fetchpriority="low"/>
                        </LazyLoad>
                    :
                        <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-sears-gray.svg`} loading="lazy" alt="icon-sears" width="55" height="15" fetchpriority="low"/>
                }
                {LAZY_L
                    ?
                        <LazyLoad height={15} width={91} offset={100} threshold={0.95}>
                            <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-sanborns-gray.svg`} loading="lazy" alt="icon-sanborns" width="91" height="15" fetchpriority="low"/>
                        </LazyLoad>
                    :
                        <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-sanborns-gray.svg`} loading="lazy" alt="icon-sanborns" width="91" height="15" fetchpriority="low"/>
                }   
                {LAZY_L
                    ?
                        <LazyLoad height={15} width={57} offset={100} threshold={0.95}>
                            <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-inbursa-gray.svg`} loading="lazy" alt="icon-inbursa" width="57" height="15" fetchpriority="low"/>
                        </LazyLoad>
                    :
                        <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-inbursa-gray.svg`} loading="lazy" alt="icon-inbursa" width="57" height="15" fetchpriority="low"/>
                }
                {LAZY_L
                    ?
                        <LazyLoad height={15} width={43} offset={100} threshold={0.95}>
                            <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-visa-gray.svg`} loading="lazy" alt="icon-visa-gray" width="43" height="15" fetchpriority="low"/>
                        </LazyLoad>
                    :
                        <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-visa-gray.svg`} loading="lazy" alt="icon-visa-gray" width="43" height="15" fetchpriority="low"/>
                }
                {LAZY_L
                    ?
                        <LazyLoad height={15} width={24} offset={100} threshold={0.95}>
                            <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-mastercard-gray.svg`} loading="lazy" alt="icon-mastercard" width="24" height="15" fetchpriority="low"/>
                        </LazyLoad>
                    :
                        <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-mastercard-gray.svg`} loading="lazy" alt="icon-mastercard" width="24" height="15" fetchpriority="low"/>
                }
                {LAZY_L
                    ?
                        <LazyLoad height={15} width={15} offset={100} threshold={0.95}>
                            <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-amex-gray.svg`} loading="lazy" alt="icon-amex" width="15" height="15" fetchpriority="low"/>
                        </LazyLoad>
                    :
                        <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-amex-gray.svg`} loading="lazy" alt="icon-amex" width="15" height="15" fetchpriority="low"/>
                }
                {LAZY_L
                    ?
                        <LazyLoad height={15} width={57} offset={100} threshold={0.95}>
                            <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-paypal-gray.svg`} loading="lazy" alt="icon-paypal" width="57" height="15" fetchpriority="low"/>
                        </LazyLoad>
                    :
                        <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-paypal-gray.svg`} loading="lazy" alt="icon-paypal" width="57" height="15" fetchpriority="low"/>
                }
                {LAZY_L
                    ?
                        <LazyLoad height={15} width={40} offset={100} threshold={0.95}>
                            <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-oxxo-pay-gray.svg`} loading="lazy" alt="icon-oxxo" width="40" height="15" fetchpriority="low"/>
                        </LazyLoad>
                    :
                        <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-oxxo-pay-gray.svg`} loading="lazy" alt="icon-oxxo" width="40" height="15" fetchpriority="low"/>
                }
                {LAZY_L
                    ?
                        <LazyLoad height={15} width={55} offset={100} threshold={0.95}>
                            <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-bbva-gray.svg`} loading="lazy" alt="icon-bbva" width="55" height="15" fetchpriority="low"/>
                        </LazyLoad>
                    :
                        <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-bbva-gray.svg`} loading="lazy" alt="icon-bbva" width="55" height="15" fetchpriority="low"/>
                }
                {LAZY_L
                    ?
                        <LazyLoad height={15} width={95} offset={100} threshold={0.95}>
                            <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-banamex-gray.svg`} loading="lazy" alt="icon-citybanamex" width="95" height="15" fetchpriority="low"/>
                        </LazyLoad>
                    :
                        <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-banamex-gray.svg`} loading="lazy" alt="icon-citybanamex" width="95" height="15" fetchpriority="low"/>
                }
                {LAZY_L
                    ?
                        <LazyLoad height={15} width={73} offset={100} threshold={0.95}>
                            <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-santander-gray.svg`} loading="lazy" alt="icon-santander" width="73" height="15" fetchpriority="low"/>
                        </LazyLoad>
                    :
                        <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/newHome/icon-santander-gray.svg`} loading="lazy" alt="icon-santander" width="73" height="15" fetchpriority="low"/>
                }
                {/*LAZY_L
                    ?
                        <LazyLoad height={15} width={73} offset={100} threshold={0.95}>
                            <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/zipGray.png`} loading="lazy" alt="icon-zip" width="73" height="15" fetchpriority="low"/>
                        </LazyLoad>
                    :
                        <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/portalAssets/claroshop/img/zipGray.png`} loading="lazy" alt="icon-zip" width="73" height="15" fetchpriority="low"/>
                */}                  
            </div>
        </div>
    )
}

export default React.memo(MetodosPago)