import {BASE_PATH_API,TIMEOUT_TIME} from 'constants/general'
import { decodeStorage } from 'functions/storageBase64'
import axios from 'axios'

export default async function getAddress({id = ''} = {}){
    let  dirID
    id
        ? dirID = `/${id}`
        : dirID = ''
        
    const isLoged = decodeStorage('session_token')
    const apiURL = `${BASE_PATH_API}/app/v1/address${dirID}?token=${isLoged}`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8'
        }
    };

    return await axios(config)
    .then(res => {
        const {
            // config,
            // headers, 
            status, 
            data
        } = res
        //console.log(res)
        // console.log(config)
        //console.log(data)
        // console.log(headers)
        //console.log(status)
        if(status === 200){
            if(!Array.isArray(data)){
                const {
                    address
                } = data.data
                //console.log(data.data.address)
                return {
                    address
                }
            }
            else{
                return {'error': '404','response': data}
            }
        }
        return []
    })
    .catch(error =>{
        // console.log(error)
        // console.log(error.request)
        // console.log(error.request.status)
        if(error.message === 'Network Error'){
            console.log('Error de conexión')
        }
        else if(error.request){
            // console.log(error.request.responseText)
        }
        if(error.request.status === 0) {
            console.log('request 0')
            return {'error': 'cors', 'response': error.message}
        }else{
            if(error.response.status === 401) {
                console.log(error.response.data.msg)
                return {'error': 401, 'response': error.message}
            }
            else if(error.response.status === 404) {
                console.error(error.response);
                return {'error': 404, 'response': error.message}
            }
            else if(error.response.status === 440) {
                return {'error': 440, 'response': error.message}
            }
            else if(error.response.status === 500) {
                console.error(error.response);
                return {'error': 500, 'response': error.message}
            }
            else{
                console.error(error.response.status);
                console.error(error.response);
            }
        }
    })
}