// Carrito emarsys
export function funtionCartEmarsys(cartEmar){
    const pushCartEmarsys = [];
    
    if (cartEmar) {
        if (typeof cartEmar === "object") {
            if (cartEmar.length >= 1) {
                cartEmar.map(product =>
                    pushCartEmarsys.push({ 'item': product.id, 'price': product.sale_price, 'quantity': product.cart_quantity })
                    )
                    window.ScarabQueue.push(['cart', pushCartEmarsys]);
                    window.ScarabQueue.push(['go']);
                    // console.log('cartEM: ',pushCartEmarsys)
            } else {
                window.ScarabQueue.push(['cart', []]);
                window.ScarabQueue.push(['go']);
                // console.log('cartEM 1: []')
            }
        } else {
            window.ScarabQueue.push(['cart', []]);
            window.ScarabQueue.push(['go']);
            // console.log('cartEM 2: []')
        }
    }
}

export function functionCartEmarsysEmpty(){
    window.ScarabQueue.push(['cart', []]);
    window.ScarabQueue.push(['go']);
    //console.log('cartEM: []')
}