import React, {useState, useEffect} from 'react'
import './style.sass'
import './icons.sass'
import { Link } from "react-router-dom"
import getCategoryMenu from 'services/getCategoryMenu'
import { getCategoryMenuNew } from 'services/getCategoryMenu'
import { decodeStorage, decodeStorageLatin, encodeStorage, encodeStorageLatin, removeStorage } from 'functions/storageBase64'
import CategoryJSON from 'constants/category.json'
import { categoriaRoute } from 'constants/general'
import { capitalizeFirstLetter } from 'functions/repareDataService'

function MenuFirstLevel(props){
    const{
        catFisrt,
        nombre,
        subcat
    } = props

    const clickLink = () =>{
        removeStorage('searchBusqueda')
    }

    return(
        <div className="menuFirstLevel">
            <div className="dt">
                <Link to={`${categoriaRoute}${encodeURIComponent(nombre?.toLocaleLowerCase().trim())}${catFisrt != undefined ? `?id=${catFisrt}` : ''}`} className={`ico-`} onClick={clickLink}>{capitalizeFirstLetter(nombre)}</Link>
                <dl className="menuSecondLevel">
                    <dt>
                        <dl className="menuThirdLevel">
                            <MenuSecondLevel subcat={subcat} firstCat={nombre} />
                        </dl>
                    </dt>
                </dl>
            </div>
        </div>
    )
}


function MenuSecondLevel(props){
    const {
        subcat,
        firstCat
    } = props

    const [subCategorias, setSubCategorias] = useState(subcat)
    useEffect(()=>{
        if(subcat){
            setSubCategorias(subcat)
        }
    },[subcat])

    const clickLink = () =>{
        removeStorage('searchBusqueda')
    }

    return(
        subCategorias &&
            subCategorias.map(({ id, name, children }) =>
                <dt className="more" key={id}>
                    <Link to={`${categoriaRoute}${encodeURIComponent(firstCat.toLocaleLowerCase().trim())}/${encodeURIComponent(name.toLocaleLowerCase().trim())}${id != undefined ? `?id=${id}` : ''}`} onClick={clickLink}>{capitalizeFirstLetter(name)}</Link>
                    <MenuThirdLevel 
                        subcategorias={children}
                        id_parent={id}
                        firstCat={firstCat}
                        secondCat={name} 
                    />
                </dt>
            )
    )
}


function MenuThirdLevel(props){
    const {
        id_parent,
        secondCat,
        subcategorias,
        firstCat
    } = props

    const [subCatFinish, setSubCatFinish] = useState(subcategorias)

    useEffect(()=>{
        if(subcategorias){
            setSubCatFinish(subcategorias)
        }
    },[subcategorias])

    const clickLink = () =>{
        removeStorage('searchBusqueda')
    }

    return(
        <ul>
            {subCatFinish &&
                subCatFinish.map(({id, name}, index) =>
                            index < 6
                                ? 
                                    <li key={id} className="alone">
                                        <Link to={`${categoriaRoute}${encodeURIComponent(firstCat.toLocaleLowerCase().trim())}/${encodeURIComponent(secondCat.toLocaleLowerCase().trim())}/${encodeURIComponent(name.toLocaleLowerCase().trim())}${id != undefined ? `?id=${id}` : ''}`} onClick={clickLink}>{capitalizeFirstLetter(name)}</Link>
                                    </li>
                                : 
                                    index === 6
                                        ? <li key={id} className="alone">
                                            <Link to={`${categoriaRoute}${encodeURIComponent(firstCat.toLocaleLowerCase().trim())}/${encodeURIComponent(secondCat.toLocaleLowerCase().trim())}${id_parent != undefined ? `?id=${id_parent}` : ''}`} onClick={clickLink}>Ver más</Link>
                                            </li>
                                        : null
                )
            }
        </ul>
    )
}


export function MenuCategoryHeader(){

    const [response, setResponse] = useState()
    const [categorias, setCategorias] = useState([])
    const [responseSorage, setResponseStorage] = useState([])
    const updateCategory = sessionStorage.getItem('updateCategory')
    const [responseV1, setResponseV1] = useState()
    
    //- Funcion que va al servicio de categorias
    useEffect( () => {
        removeStorage('categoryTree')
        if(!decodeStorage('categoryTree') || updateCategory === null){
            getCategoryMenu()
            .then(setResponse)
            // getCategoryMenuNew()
            // .then(setResponse)
            sessionStorage.setItem('updateCategory',true)
        }else{
            //- Funcion que toma las categorias del storage
            setResponseStorage( decodeStorageLatin('categoryTree') )
        }
    },[])

    // useEffect( () => {
    //     getCategoryMenuV1()
    //     .then(setResponseV1)
    // },[])


    useEffect( () => {
        if(response){
            encodeStorageLatin('categoryTree', response)
            setResponseStorage(response)
        }
        else if(responseV1){
            if(responseV1.data){
                // console.log('')
            }
        }
    },[response, responseV1])
    
    //- Guarda en un estado la respuesta y evalua que sea 200 si no manda el error
    useEffect(()=>{
        if(responseSorage){
            if(responseSorage.statusCode === 200){
                if(typeof(responseSorage.data) === 'object'){
                    setCategorias({
                        'categoriasMenu': responseSorage.data
                    })
                }
                else{
                    setCategorias({})
                }
            }
        }
        else if(responseSorage.statusCode !== undefined){
            setCategorias({})
        }
        
    },[responseSorage])
    
    return(
        <div className="menuHeader">
            <div className="subMenu">
                {/*responseSorage &&
                    responseSorage.statusCode === 200
                        ?
                            categorias.categoriasMenu &&
                                <>
                                    {
                                        categorias.categoriasMenu.map(({nombre, icon, seo, id_cat, subcat, estatus, visible}) => 
                                            estatus !== 0  && visible !== 0
                                                ?
                                                    <MenuFirstLevel
                                                        key={id_cat}
                                                        catFisrt={id_cat}
                                                        nombre={nombre}
                                                        icon={icon}
                                                        seo={seo}
                                                        subcat={subcat}
                                                    />
                                                :
                                                    null
                                        )
                                    }
                                </>
                        : null
                                */}
                {CategoryJSON.map(({ name, id, children }) => (
                    <MenuFirstLevel
                        key={id}
                        catFisrt={id}
                        nombre={name}
                        subcat={children}
                    />
                ))}
            </div>
        </div>
    )
}