import React, {useState, useEffect} from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useIsMobile } from 'hooks/useIsMobile'
import { ErrorBoundary } from 'components/Error/ErrorBoundary'
import { CartAflilados } from 'services/Afiliados'
import { postCartBloque } from 'services/postProductCart'
import { decodeStorage } from 'functions/storageBase64';
import { useValidateSession } from 'hooks/useValidateSession'
import { useToken } from 'hooks/useToken';


import Header from 'pages/Header/HeaderNavegacion'
import Loading from 'components/Loading'
import BarraCP from 'components/BarCP/BarHeaderCP'
import Footer from 'pages/Footer/FooterDesktop'


function ErrorGo(){
    return(
        <section>
            <div className="errorProductServices">
                <img src="/img/maintenanceError.svg" alt="Página no encontrada" width="450" height="450" loading="lazy"/>
                <p className="titleError">¡No hay inventario disponible!</p>
                <p className="suggestion">Lo sentimos, los productos o la información que buscas al parecer ya no está disponible.</p>
                <Link className="btn rojo" to={`/`} title="Ir al inicio">Ir al inicio</Link>
            </div>
        </section>
    )
}

export default function Afiliados(){

    
    const params = useParams();
    const history = useHistory()
    const isLoged = decodeStorage('session_token');
    
    const { isMobile } = useIsMobile()
	const [ loading, setLoading ] = useState(true)
    const [errorBase, setErrorBase] = useState(false)
    
    
    const [responseCart, setResponseCart] = useState()
    const [respCart, setRespCart] = useState()
    const [cartAfil, setCartAfil] = useState()
    
    useEffect(()=>{
        if(!isLoged){
            history.replace(`/login?redirect=${window.location.href}`)
        }
    },[])


    /*  --------------------------- Inicio --------------------------------------  */
        //  Hooks para validar tiempo y refresh token mas state de boton
        const {checkValidateSession, atExp, actTime} = useValidateSession()
        const refreshCyR = useToken({firstActive: false, name: "detallePedidoDesckop", timeRef: 1500})
        const [actionOK, setActionOk] = useState(false)
    /*  --------------------------- Fin --------------------------------------  */

    useEffect(() => {
        if(cartAfil){
            setTimeout(function(){
                checkValidateSession(true)
                setActionOk('cartA')
            },700)
        }
    }, [cartAfil]);

    /*  --------------------------- Inicio --------------------------------------  */
        //  Ajusta Los tiempos y refresca token
        const [serviceRT, setServiceRT] = useState(false)
        const [uD, setUD] = useState(false)
        useEffect(() => {
            if(actionOK){
                let  actTimeLS = Date.now() 
                let  atExpLS = decodeStorage('access_token_exp') * 1000
                setUD(false)
                
                if(actTimeLS <= atExpLS){
                    setServiceRT(true)
                }
                else{
                    setTimeout(function(){
                        refreshCyR.forceToken(true)
                    },300)
                    setTimeout(function(){
                        setUD(true)
                    },900)
                }
            }
        }, [uD, actionOK])
    /*-------------------------------------------------*/
        // hace la peticion a servicio
        useEffect(() => {
            // Va al servicio para traer las direcciones
            if(actionOK === 'cartA'){
                setServiceRT(false)
                setActionOk(false)
                postCartBloque(cartAfil)
                .then(setRespCart)
            }
        }, [serviceRT])
    /*  --------------------------- Fin --------------------------------------  */

    useEffect(()=>{
        if(params?.hash_cart){
            if(!responseCart){
                CartAflilados({cart_Afiliados: params?.hash_cart})
                .then(setResponseCart)
            }
        }else{
            setErrorBase(true)
            setLoading(false)
        }
    },[params?.hash_cart])
    
    const [cartAB, setCartAB] = useState()
    useEffect(()=>{
        if(responseCart?.statusCode === 200 || responseCart?.statusCode === 201){ 
            setCartAB(responseCart?.data?.data?.products)
        }else if(responseCart?.statusCode === 400 || responseCart?.statusCode === 404){
            setErrorBase(true)
            setLoading(false)
        }
        else if(responseCart?.statusCode >= 500){
            setErrorBase(true)
            setLoading(false)
        }
    },[responseCart])

    useEffect(()=>{
        if(cartAB){
            setCartAfil(
                cartAB?.map((cartMap) =>  {
                    let cart = cartMap?.product_id ? {
                        csId: Number(cartMap?.product_id),
                        childrenSku: cartMap?.children_sku != null ? String(cartMap?.children_sku) : "0",
                        quantity: cartMap?.quantity ? cartMap?.quantity : 1,
                        tag: "afiliados",
                        indentifier: "string",
                        createdAt: 0
                    } : {}
                    return cart
                })
            )
        }
    },[cartAB])


    useEffect(()=>{
        if(respCart){
            history.replace('/carrito/detalle')
        }
    },[respCart])

    //recibe la direccion del CP
	const [updateCPBar, setUpdateCPBar] = useState()
	const reciveDirection = (val) =>{ setUpdateCPBar(val) }
	const [upCP, setUpCP] = useState()
    useEffect(()=>{ if(upCP){ /*console.log('')*/ } },[upCP, updateCPBar])

    return(
        <>
            { loading && <Loading /> }
            {isMobile === 'desktop'
                ?
                    <>
                        <ErrorBoundary>
                            <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection}/>
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <section className="barCpResponsive">
                                <BarraCP changeCP={reciveDirection} clase="barHeaderCP"/>	
                            </section>
                        </ErrorBoundary>
                        <main>
                            {errorBase
                                ? <ErrorGo />
                                : null
                            }
                        </main>
                        <Footer />
                    </>
                :  
                    <>
                        <ErrorBoundary>
                            <Header page='pageHome' setUpCP={setUpCP}/>
                            <Link className="fakeSearch" to="/buscador">
                                <span>¿Qué es lo que buscas?</span>
                            </Link>
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <BarraCP changeCP={reciveDirection} clase="barHeaderCP"/>
                        </ErrorBoundary>
                        <main>
                            {errorBase
                                ? <ErrorGo />
                                : null
                            }
                        </main>
                    </>
            }
        </>
    )
}