import {BASE_PATH_API,TIMEOUT_TIME,VARIANTS_PER_PORTAL} from 'constants/general'
import { decodeStorage } from 'functions/storageBase64'
import axios from 'axios'


export default async function postProductCart ({...props}) {
    const {
        id_product,
        children_sku = 0,
        quantity
    } = props

    const cp  = decodeStorage('codigoPostal')
    const byCart = decodeStorage('idCarrito')
    const accessToken = "Bearer " + decodeStorage("access_token");
    var byCP
    
    cp
        ? byCP = `/cp/${cp}`
        : byCP = ''
    
    const apiURL = BASE_PATH_API + `/app/v1/cart/${byCart}${byCP}`
    const data = JSON.stringify({"id": byCart, "products":[{"id_cs":id_product, "children_sku": children_sku, "quantity": quantity}]});

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
        data: data
    };

    return await axios(config)
    .then(res => {
        const {
            // config,
            // headers, 
            status, 
            data
        } = res
        //console.log(res)
        // console.log(config)
        // console.log(headers)
        //console.log(status)
        //console.log(data)
        const {
            data: dataWL,
            metadata
        } = data
        if(status === 201){
            if(!Array.isArray(dataWL)){
                const {
                    products,
                    total_price,
                    total_items
                } = dataWL
                return {
                    products,
                    total_price,
                    total_items,
                    metadata
                }
            }
            else{
                return {'error': '404','response': data}
            }
        }
        return []
    })
    .catch(error =>{
        // console.log(error)
        // console.log(error.request)
        // console.log(error.request.status)
        if(error.message === 'Network Error'){
            console.log('Error de conexión')
        }
        else if(error.request){
            console.log(error.request.responseText)
        }
        if(error.request.status === 0) {
            console.log('request 0')
            return {'error': 'cors', 'response': error.message}
        }else{
            if(error.response.status === 401) {
                console.log(error.response.data.msg)
                return {'error': 401, 'response': error.message}
            }
            else if(error.response.status === 404) {
                console.error(error.response);
                return {'error': 404, 'response': error.message}
            }
            else if(error.response.status === 440) {
                return {'error': 440, 'response': error.message}
            }
            else if(error.response.status === 500) {
                console.error(error.response);
                return {'error': 500, 'response': error.message}
            }
            else{
                console.error(error.response.status);
                console.error(error.response);
            }
        }
    })
}

export async function postCartBloque (products) {

    console.log('products service ', products)

    const cp  = decodeStorage('codigoPostal')
    const byCart = decodeStorage('idCarrito')
    const accessToken = "Bearer " + decodeStorage("access_token");
    var byCP
    
    cp
        ? byCP = `/cp/${cp}`
        : byCP = ''
    
    const apiURL = BASE_PATH_API + `/cart/api/v1`
    const data = JSON.stringify({"owner": byCart, "products": products });

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
        data: data
    };

    return await axios(config)
    .then(res => {
        const {
            // config,
            // headers, 
            status, 
            data
        } = res
        //console.log(res)
        // console.log(config)
        // console.log(headers)
        //console.log(status)
        //console.log(data)
        const {
            data: dataWL,
            metadata
        } = data
        if(status === 201){
            if(!Array.isArray(dataWL)){
                const {
                    products,
                    total_price,
                    total_items
                } = dataWL
                return {
                    products,
                    total_price,
                    total_items,
                    metadata
                }
            }
            else{
                return {'error': '404','response': data}
            }
        }
        return []
    })
    .catch(error =>{
        // console.log(error)
        // console.log(error.request)
        // console.log(error.request.status)
        if(error.message === 'Network Error'){
            console.log('Error de conexión')
        }
        else if(error.request){
            console.log(error.request.responseText)
        }
        if(error.request.status === 0) {
            console.log('request 0')
            return {'error': 'cors', 'response': error.message}
        }else{
            if(error.response.status === 401) {
                console.log(error.response.data.msg)
                return {'error': 401, 'response': error.message}
            }
            else if(error.response.status === 404) {
                console.error(error.response);
                return {'error': 404, 'response': error.message}
            }
            else if(error.response.status === 440) {
                return {'error': 440, 'response': error.message}
            }
            else if(error.response.status === 500) {
                console.error(error.response);
                return {'error': 500, 'response': error.message}
            }
            else{
                console.error(error.response.status);
                console.error(error.response);
            }
        }
    })
}