import {BASE_PATH_API,TIMEOUT_TIME, STAGE_DIRNAME, BASE_PATH_API_DEV, BASE_PATH_PORTAL, VARIANTS_PER_PORTAL} from 'constants/general'
import axios from 'axios'
import {SHA1} from 'functions/generateSHA1'
import { decodeStorage } from 'functions/storageBase64'

export async function postLogin ({raw} = {}){

    const newRaw = {
        "usuario": raw.email,
        "contrasena":raw.password
    }
    
    let apiURL = null
    if(STAGE_DIRNAME === "dev"){
        // apiURL = `${BASE_PATH_API_DEV.login}/app/v1/Login`
        apiURL = `${BASE_PATH_API_DEV.login}/app/ApiRest/Login/`
    }else{
        // apiURL = `${BASE_PATH_API}/app/v1/Login`
        apiURL = `${BASE_PATH_API}/app/ApiRest/Login/`
    }

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        data: newRaw
    };

    return await axios(config)
    .then(res => {
        const {
            // config,
            headers, 
            status, 
            data
        } = res
        let newData
        // if(data){
        //     if(data.data){
        //         newData = {
        //             token : data.data.session_token,
        //             idCarrito : data.data.cart_hash

        //         }
        //     }
        // }

         if(data){
            newData = {
                data: {
                    session_token : data.token,
                    cart_hash : data.idCarrito
                }
            }
        }
        return {
            'headers': headers,
            'statusCode': status,
            'data': newData,
            'error': ''
        }
    })
    .catch(error =>{
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.response.data,
            'error': error.response.data.msg
        }
    })
}

export async function refreshTokenV2 (){
    let raws = decodeStorage('refresh_token')
    const apiURL = `${BASE_PATH_API}/app/v1/refresh-token`
    //const newRaw = `refresh_token=${encodeURIComponent(raws)}`
    const newRaw = {
        refresh_token: raws
    }

    //console.log(raw)
    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        headers: {
            'Content-Type': 'application/json'
        },
        data: newRaw
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}


export async function refreshTokenOld (){
    let raws = decodeStorage('refresh_token')
    const apiURL = `${BASE_PATH_API}/app/v1/login?refresh_token=${encodeURIComponent(raws)}`
    //const newRaw = `refresh_token=${encodeURIComponent(raws)}`

    //console.log(raw)
    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
		crossdomain: true,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        }
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        //console.log(error)
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}

export async function postLoginOldPortal ({email, password, tkn_login} = {}){
    //const apiURL = PORTAL_URL + '/newlogin'
    const apiURL = BASE_PATH_PORTAL + '/newlogin'
    const dateActual = new Date()
    
    const data = `email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}&tkn_login=${encodeURIComponent( SHA1(email+'|'+dateActual) )}&action=login`
    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    return await axios(config)
    .then(res => {
        const {
            // config,
            headers,
            // cookies,
            // status, 
            data
        } = res
        // console.log('res ',res)
        // console.log('config ',config)
        // console.log('headers ',headers)
        // console.log('cookies ',cookies)
        //console.log(res)
        // console.log(config)
        //console.log(data)
        // console.log(headers)
        // console.log(status)
        return data
    })
    .catch(error =>{
        console.log(error.request.status)
        if(error.message === 'Network Error'){
            console.log('Error de conexión')
        }
        else if(error.request){
            console.log(error.request.responseText)
        }
        if(error.request.status === 0) {
            console.log('request 0')
        }else{
            if(error.response.status === 401) {
                //console.log(error.response.data.metadata.message)
                //return error.response.data.metadata
                console.log(error.response.data.msg)
                return { error: error.response.status, message: error.response.data.msg }
            }
            else if(error.response.status === 404) {
                console.error(error.response);
                console.log('request 404')
            }
            else if(error.response.status === 440) {
                console.log('request 440')
            }
            else if(error.response.status === 500) {
                console.error(error.response);
                console.log('request 500')
                return (error.response)
            }
            else{
                console.error(error.response.status);
                console.error(error.response);
            }
        }
    })
}

export async function postLogoutOldPortal (){
    //const apiURL = PORTAL_URL + '/newlogin'
    const apiURL = BASE_PATH_PORTAL + '/login/login/logout'

    const data = `a=`
    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        data: data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    return await axios(config)
    .then(res => {
        const {
            // config,
            headers,
            // cookies,
            // status, 
            data
        } = res
        // console.log('res ',res)
        // console.log('config ',config)
        // console.log('headers ',headers)
        // console.log('cookies ',cookies)
        //console.log(res)
        // console.log(config)
        //console.log(data)
        // console.log(headers)
        // console.log(status)
        return data
    })
    .catch(error =>{
        console.log(error.request.status)
        if(error.message === 'Network Error'){
            console.log('Error de conexión')
        }
        else if(error.request){
            console.log(error.request.responseText)
        }
        if(error.request.status === 0) {
            console.log('request 0')
        }else{
            if(error.response.status === 401) {
                //console.log(error.response.data.metadata.message)
                //return error.response.data.metadata
                console.log(error.response.data.msg)
                return { error: error.response.status, message: error.response.data.msg }
            }
            else if(error.response.status === 404) {
                console.error(error.response);
                console.log('request 404')
            }
            else if(error.response.status === 440) {
                console.log('request 440')
            }
            else if(error.response.status === 500) {
                console.error(error.response);
                console.log('request 500')
                return (error.response)
            }
            else{
                console.error(error.response.status);
                console.error(error.response);
            }
        }
    })
}


/*-- Ajuste para SSO de azu --*/
export async function postMiddleware (props){
    const {
        email,
        idSession
    } = props

    const profileData = decodeStorage('profileData')

    let correo
    let isLoged

    if(idSession){
        isLoged = idSession
    }else{
        isLoged = decodeStorage('session_token')
    }

    if(profileData.correo){
        correo = profileData.correo
    }else{
        correo = email
    }
    
    let apiURL = null
    if(STAGE_DIRNAME === "dev"){
        apiURL = `${BASE_PATH_API_DEV.userProfile}/sso-middleware/v1/protocol/openid-connect/token`
    }else{
        apiURL = `${BASE_PATH_API}/sso-middleware/v1/protocol/openid-connect/token`
    }

    const rawSSO = `grant_type=custom_token&client_secret=${isLoged}&client_id=claroshop-web&username=${correo}`

    // console.log('withCredentials: ', !VARIANTS_PER_PORTAL?.bManager)

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: rawSSO
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.response.data,
            'error': error.response.data.msg
        }
    })
}

export async function refreshToken (){
    const isLoged = decodeStorage('session_token')
    const profileData = decodeStorage('profileData')

    // console.log('withCredentials: ', !VARIANTS_PER_PORTAL?.bManager)
    
    let apiURL = null
    if(STAGE_DIRNAME === "dev"){
        apiURL = `${BASE_PATH_API_DEV.login}/sso-middleware/v1/protocol/openid-connect/token`
    }else{
        apiURL = `${BASE_PATH_API}/sso-middleware/v1/protocol/openid-connect/token`
    }

    const rawSSO = `grant_type=custom_token&client_secret=${isLoged}&client_id=claroshop-web&username=${profileData.correo}`

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: !VARIANTS_PER_PORTAL?.bManager,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: rawSSO
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.response.data,
            'error': error.response.data.msg
        }
    })
}