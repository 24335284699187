import { BASE_FIRE_CONFIG } from 'constants/general'
import { decodeStorage } from 'functions/storageBase64'
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserId, setUserProperties } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig =   {
    apiKey: BASE_FIRE_CONFIG.apiKey,
    authDomain: BASE_FIRE_CONFIG.authDomain,
    databaseURL: BASE_FIRE_CONFIG.databaseURL,
    projectId: BASE_FIRE_CONFIG.projectId,
    storageBucket: BASE_FIRE_CONFIG.storageBucket,
    messagingSenderId: BASE_FIRE_CONFIG.messagingSenderId,
    appId: BASE_FIRE_CONFIG.appId,
    measurementId: BASE_FIRE_CONFIG.measurementId
    }
    // Initialize Firebase
const appFirebase = initializeApp(firebaseConfig)
const analytics = getAnalytics(appFirebase)


// track Home
export function trackView({ name, arreglo }){

    const isLoged = decodeStorage('session_token');
    const profileData = decodeStorage('profileData');

    if(BASE_FIRE_CONFIG){
        console.log('firebaseConfig: ', BASE_FIRE_CONFIG)
        if(isLoged){
            if(profileData?.idClaro){
                //console.log('idClaro1: ', profileData?.idClaro)
                setUserId(analytics, String(profileData?.idClaro))
            }else{
                //console.log('idClaro2: ', profileData?.idClaro)
                setUserId(analytics, "")
            }
        }else{
            //console.log('idClaro3: ', profileData?.idClaro)
            setUserId(analytics, "")
        }
        
        // console.log('firebaseConfig: ', firebaseConfig)
        if(arreglo){
            // console.log(`name: ${name}, array: ${JSON.stringify(arreglo)}`)
            logEvent(analytics, name, arreglo)
        }else{
            console.log(`name: ${name}`)
            //console.log('analytics: ', window['analytics'])
            //console.log(window.analytics)
            logEvent(analytics, name)
        }

    }
}

// track button link
export function trackButton({ name, arreglo = null }){

    if(BASE_FIRE_CONFIG){
        // console.log('firebaseConfig: ', firebaseConfig)
        if(arreglo){
            // console.log(`name: ${name}, array: ${JSON.stringify(arreglo)}`)
            logEvent(analytics, name, arreglo)
        }else{
            // console.log(`name: ${name}`)
            logEvent(analytics, name)
        }
    }
}