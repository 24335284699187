import React, {useState, useEffect} from 'react'
import { Link, useParams, useHistory, useLocation } from 'react-router-dom'
import { useIsMobile } from 'hooks/useIsMobile'
import { ErrorBoundary } from 'components/Error/ErrorBoundary'
import { CartTerrific } from 'services/Afiliados'
import { postCartBloque } from 'services/postProductCart'
import { decodeStorage, encodeStorage } from 'functions/storageBase64';
import { useValidateSession } from 'hooks/useValidateSession'
import { useToken } from 'hooks/useToken';
import '../../components/Error/ErrorProduct/style.sass'
import { trackView } from 'functions/firebaseFunction'

import ModalPre from 'components/Modal'
import { ModalPreLogin } from 'components/LoginRegistro/modals'

import Footer from 'pages/Footer/FooterDesktop'
import Header from 'pages/Header/HeaderNavegacion'
import Loading from 'components/Loading'
import BarraCP from 'components/BarCP/BarHeaderCP'

function ErrorGo(){
    return(
        <section>
            <div className="errorProductServices">
                <img src="/img/maintenanceError.svg" alt="Página no encontrada" width="450" height="450" loading="lazy"/>
                <p className="titleError">Carrito no disponible</p>
                <p className="suggestion">Lo sentimos, los productos o la información que buscas no están disponibles.</p>
                <Link className="btn rojo" to={`/`} title="Ir al inicio">Ir al inicio</Link>
            </div>
        </section>
    )
}

export default function Afiliados(){

    // const searsT =  "2430"
    // const sanbornsT = "3496"
    const claroshopT = "2468"

    const tiendaTerrific = claroshopT
 
    const params = useParams();
    const history = useHistory()
    const isLoged = decodeStorage('session_token');
    
    const { isMobile } = useIsMobile()
	const [ loading, setLoading ] = useState(true)
    
    const [responseCart, setResponseCart] = useState()
    const [respCart, setRespCart] = useState()
    const [cartAfil, setCartAfil] = useState()
    const [cartTerrific, setCartTerrific] = useState()
    const [errorBase, setErrorBase] = useState(false)

    const [showModal, setShowModal] = useState(false)
    const location = useLocation()
    const hostName = window.location.origin + location.pathname
    const handleClose = () => {
        setShowModal(false)
        setLoading(true)
    }
    
    useEffect(()=>{
        window.ineum('page', 'Livestream');
        if(!isLoged){
            // history.replace(`/login?redirect=${window.location.href}`)
            setShowModal(true)
            setLoading(false)
        }
    },[isLoged])

    /*  --------------------------- Inicio --------------------------------------  */
        //  Hooks para validar tiempo y refresh token mas state de boton
        const {checkValidateSession, atExp, actTime} = useValidateSession()
        const refreshCyR = useToken({firstActive: false, name: "detallePedidoDesckop", timeRef: 1500})
        const [actionOK, setActionOk] = useState(false)
    /*  --------------------------- Fin --------------------------------------  */

    useEffect(() => {
        if(cartAfil){
            setTimeout(function(){
                // checkValidateSession(true)
                setActionOk('cartA')
            },1200)
        }
    }, [cartAfil]);

    /*  --------------------------- Inicio --------------------------------------  */
        //  Ajusta Los tiempos y refresca token
        const [serviceRT, setServiceRT] = useState(false)
        const [uD, setUD] = useState(false)
        useEffect(() => {
            if(actionOK){
                let  actTimeLS = Date.now() 
                let  atExpLS = decodeStorage('access_token_exp') * 1000
                setUD(false)
                
                if(actTimeLS <= atExpLS){
                    setServiceRT(true)
                }
                else{
                    setTimeout(function(){
                        refreshCyR.forceToken(true)
                    },300)
                    setTimeout(function(){
                        setUD(true)
                    },900)
                }
            }
        }, [uD, actionOK, refreshCyR])
    /*-------------------------------------------------*/
        // hace la peticion a servicio
        useEffect(() => {
            // Va al servicio para traer las direcciones
            if(actionOK === 'cartA'){
                setServiceRT(false)
                setActionOk(false)
                // console.log('cartAfil', cartAfil)
                // console.log('cartTerrific', cartTerrific)
                if(cartAfil[0]){
                    postCartBloque(cartAfil)
                    .then(setRespCart)
                    if(decodeStorage('profileData')?.idClaro){
                        window.localStorage.removeItem('fechaClickLive')
                        window.localStorage.removeItem('urlClickLive')
                        CartTerrific(cartTerrific)
                    }
                    console.log(cartAfil)
                    trackView({ name: 'cs_whatsapp', arreglo: { campania: cartAfil[0]?.tag, id_producto: cartAfil[0]?.csId, login: isLoged ? true : false } })
                }else{
                    setErrorBase(true)
                    setLoading(false)
                }
            }
        }, [actionOK, cartAfil, cartTerrific, serviceRT])
    /*  --------------------------- Fin --------------------------------------  */

    useEffect(()=>{
        if(params){
            let cartGo
            let hashCart

            if(window.location.pathname.indexOf('/referidos/') !== -1){
                hashCart = window.location.pathname.replace(window.location.search, '').split('/referidos/')
            }else if(window.location.pathname.indexOf('/livestream/') !== -1){
                hashCart = window.location.pathname.replace(window.location.search, '').split('/livestream/')
            }else if(window.location.pathname.indexOf('/external_cart') !== -1){
                hashCart = window.location.pathname.replace(window.location.search, '').split('/external_cart/')
            }

            try{
                try{
                    cartGo = JSON.parse( window.atob( params?.hash_cart ) )
                    setResponseCart(cartGo)
                }catch(e){
                    cartGo = JSON.parse( window.atob( hashCart[1] ) )
                    setResponseCart(cartGo)
                }
            }catch(e){
                // console.log(e)
                setErrorBase(true)
                setLoading(false)
            }
        }
    },[params])
    
    const [cartAB, setCartAB] = useState()
    useEffect(()=>{
        if(responseCart){
            if(responseCart?.products){
                setCartAB(responseCart?.products)
                let terrific = {
                    "paymentId": responseCart?.paymentId,
                    "redirectTo": responseCart?.redirectTo,
                    "webhook": responseCart?.webhook
                }
                encodeStorage("terrificData", terrific)
            }else{
                setCartAB(responseCart)
            }
        }
    },[responseCart])

    useEffect(()=>{

        
        if(cartAB){
            let fechaClickLive = Date.now() + 300000
            let fechaActual = Date.now()
            let fechaStorage = window.localStorage.getItem('fechaClickLive')
            let urlStorage = window.localStorage.getItem('urlClickLive')

            let datos = {
                "user_id": "0001",
                "email": 'clic@claroshop.com',
                "tienda_id": tiendaTerrific,
                "products": responseCart?.products,
                "source": cartAB[0]?.tag ? cartAB[0]?.tag : "clarolive"
            }

            if(fechaStorage){
                if(urlStorage !== String(window.location.pathname) ){
                    window.localStorage.setItem('fechaClickLive', fechaClickLive)
                    window.localStorage.setItem('urlClickLive', window.location.pathname)
                    CartTerrific(datos)
                    // console.log('datos', datos)
                }
                else if(fechaActual >= fechaStorage){
                    window.localStorage.removeItem('fechaClickLive')
                    window.localStorage.removeItem('urlClickLive')
                    CartTerrific(datos)
                    // console.log('datos', datos)
                }
            }else{
                window.localStorage.setItem('fechaClickLive', fechaClickLive)
                window.localStorage.setItem('urlClickLive', window.location.pathname)
                CartTerrific(datos)
                // console.log('datos', datos)
            }


            let cartFinal = cartAB.map((cartMap) =>  {
                let cart = cartMap?.product_id ? {
                    csId: Number(cartMap?.product_id),
                    childrenSku: cartMap?.children_sku != null ? String(cartMap?.children_sku) : "0",
                    quantity: cartMap?.quantity ? cartMap?.quantity : 1,
                    tag: cartMap?.tag ? cartMap?.tag : cartMap?.id_cart ? cartMap?.id_cart : "clarolive",
                    indentifier: cartMap?.id_cart,
                    createdAt: 0
                } : {}

                return cart
            })
            
            let totalCart = cartFinal.filter(elem => {
                if (Object.keys(elem).length !== 0) {
                    return true;
                }
                    return false;
                }
            );

            let terrificData = {
                "user_id": String( decodeStorage('profileData')?.idClaro ),
                "email": decodeStorage('profileData')?.correo,
                "tienda_id": tiendaTerrific,
                "products": responseCart?.products,
                "source": cartAB[0]?.tag ? cartAB[0]?.tag : "clarolive"
            } 

            if(isLoged){
                setCartAfil(totalCart)
                // console.log("setCartAfil : ", totalCart)
            }
            setCartTerrific(terrificData)
            // console.log("setCartTerrific :",terrificData)
        }
    },[cartAB, isLoged, responseCart, tiendaTerrific])


    useEffect(()=>{
        if(respCart){
            history.replace('/carrito/detalle')
        }
    },[history, respCart])

    //recibe la direccion del CP
	const [updateCPBar, setUpdateCPBar] = useState()
	const reciveDirection = (val) =>{ setUpdateCPBar(val) }
	const [upCP, setUpCP] = useState()
    useEffect(()=>{ if(upCP){ /*console.log('')*/ } },[upCP, updateCPBar])

    return(
        <>
            { loading && <Loading /> }
            {isMobile === 'desktop'
                ?
                    <>
                        <ErrorBoundary>
                            <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection}/>
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <section className="barCpResponsive">
                                <BarraCP changeCP={reciveDirection} clase="barHeaderCP"/>	
                            </section>
                        </ErrorBoundary>
                        <main>
                            {errorBase
                                ? <ErrorGo />
                                : null
                            }
                        </main>
                        <Footer />
                    </>
                :  
                    <>
                        <ErrorBoundary>
                            <Header page='pageHome' setUpCP={setUpCP}/>
                            <Link className="fakeSearch" to="/buscador">
                                <span>¿Qué es lo que buscas?</span>
                            </Link>
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <BarraCP changeCP={reciveDirection} clase="barHeaderCP"/>
                        </ErrorBoundary>
                        <main>
                            {errorBase
                                ? <ErrorGo />
                                : null
                            }
                        </main>
                    </>
            }
            {showModal &&
                <ModalPre onClose={handleClose} type="modalSB fullSinHead" title="">
                    <ModalPreLogin
                        icon="img/cart_modal_livestream.png"
                        title="¡A un paso de que sea tuyo!" 
                        subTitle="Por favor, inicia sesión para continuar."
                        type="livestream"
                        linkLogin={`/login?redirect=${hostName}`}
                        linkRegister={`/registro?redirect=${hostName}`}
                        hideClose={true}
                    />
                </ModalPre>
            }
        </>
    )
}