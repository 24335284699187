import React from 'react'
import { Link } from "react-router-dom"
import { BASE_PATH_PORTAL } from 'constants/general'

export function HeaderTiendasMenu() {

    return(
        <nav className="menuTiendas">
            <ul>
                <li>
                    <Link to="/tiendas" >Tiendas Oficiales</Link>
                </li>
                <li>
                    <a href={`${BASE_PATH_PORTAL}/l/faqs/`} rel="noopener noreferrer">¿Necesitas ayuda?</a>
                </li>
            </ul>
        </nav>
    )
}