import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import ModalPre from 'components/Modal'

export function ModalPreLogin(props) {
    const {
        icon,
        title,
        subTitle,
        type = 'clasic',
        strong,
        linkLogin,
        linkRegister,
        clickEvent
    } = props
    return (
        <>
        {type === 'clasic'
            ?
                <div className={`modalPreLogin`}>
                    <img src={icon} alt={title} />
                    <p>{title} <strong>{strong}</strong></p>
                    <Link to={linkLogin} title="Btn Login" className="btn rojo" onClick={clickEvent}>Iniciar sesión</Link>
                    <Link to={linkRegister} title="Btn Registro" className="btn blanco" onClick={clickEvent}>Registrarme</Link>
                </div>
            : 
                <div  className={`modalPreLogin ${type}`}>
                    <p> <strong>{title}</strong></p>
                    <img src={icon} alt={title} />
                    <p>{subTitle} <strong>{strong}</strong></p>
                    <Link to={linkLogin} title="Btn Login" className="btn rojo" onClick={clickEvent}>Iniciar sesión</Link>
                    <Link to={linkRegister} title="Btn Registro" className="btn blanco" onClick={clickEvent}>Registrarme</Link>
                </div>
        }
        </>
    )
}

export function ModalWishlistPreLogin(props) {
    const {
        route,
        clase,
        title,
        saveFavorite
    } = props

    const history = useHistory()
    const location = useLocation()
    const urlLink = window.location.href
    const hashLink = window.location.hash

    const [showModal, setShowModal] = useState(false)
    const [clickButon, setClickButon] = useState(false)

    useEffect(() => {
        if (hashLink === "#modalWishlist") {
            if (clickButon) {
                setShowModal(true)
            }
            else {
                //history.replace(location.pathname)
            }
        } else {
            setShowModal(false)
        }
    }, [hashLink, urlLink, clickButon])


    const handleCloseWL = () => {
        setShowModal(false)
        history.replace(location.pathname)
    }

    const showModalWL = () => {
        history.push('#modalWishlist')
        setClickButon(true)
    }

    return (
        <>
            <div
                className={clase}
                onClick={showModalWL}
                aria-label={title}>
            </div>
            {showModal &&
                <ModalPre onClose={handleCloseWL} type="" title="">
                    <ModalPreLogin
                        icon="img/heart_modal.svg"
                        title="Inicia sesión para agregar productos a tu "
                        strong=" lista de deseos."
                        linkLogin={`/login${route}`}
                        linkRegister={`/registro${route}`}
                        clickEvent={saveFavorite}
                    />
                </ModalPre>
            }
        </>
    )
}

export function ModalWishlistPreLoginDetalle(props) {
    const {
        route,
        clase,
        title,
        saveFavorite
    } = props

    const history = useHistory()
    const location = useLocation()
    const urlLink = window.location.href
    const hashLink = window.location.hash

    const [showModal, setShowModal] = useState(false)
    const [clickButon, setClickButon] = useState(false)

    useEffect(() => {
        if (hashLink === "#modalWishlist") {
            if (clickButon) {
                setShowModal(true)
            }
            else {
                //history.replace(location.pathname)
            }
        } else {
            setShowModal(false)
        }
    }, [hashLink, urlLink, clickButon])


    const handleCloseWL = () => {
        setShowModal(false)
        history.replace(location.pathname)
    }

    const showModalWL = () => {
        history.push('#modalWishlist')
        setClickButon(true)
    }

    return (
        <>
            <div
                className={clase}
                onClick={showModalWL}
                aria-label={title}>
                Agregar a favoritos
            </div>
            {showModal &&
                <ModalPre onClose={handleCloseWL} type="" title="">
                    <ModalPreLogin
                        icon="img/heart_modal.svg"
                        title="Inicia sesión para agregar productos a tu "
                        strong=" lista de deseos."
                        linkLogin={`/login${route}`}
                        linkRegister={`/registro${route}`}
                        clickEvent={saveFavorite}
                    />
                </ModalPre>
            }
        </>
    )
}