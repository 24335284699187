import React from 'react'
import './style.sass'
import { Link } from "react-router-dom"

export default function HeadeWishlist ({...props}) {
    const {ruta = '/login'} = props


    return(
        <Link
            to={ruta}
            className="heartIcon"
            aria-label="Ir a Wishlist"
        >
            <span className="textReference">
                <span className="smallText">Mi Lista</span> de deseos
            </span>
        </Link>
    )
}