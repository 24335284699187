import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './style.sass';

export default function BoxClaroshop(){

    const [menuView, setMenuView] = useState(false);

    const toggleClass = () => {
        setMenuView( !menuView );
    }

    return (
        <div className="boxClaroShop">
            <p onClick={toggleClass} className={menuView ? 'active' : null}>Claro Shop</p>
            <ul>
                <li><Link to={"/aviso-privacidad/"}  >Aviso de privacidad</Link></li>
                <li><Link to={"/terminos-y-condiciones/"} >Términos y condiciones</Link></li>
                <li><a href="https://blog.claroshop.com/" >Blog Claroshop</a></li>
            </ul>
        </div>
    )
}
