
import React, {useState, useEffect} from 'react'
import './styles.sass'
import { decodeStorage, encodeStorage, encodeStorageLatin, removeStorage } from 'functions/storageBase64'
import getAddress from 'services/getAddress'
import {getCartCount, getCart } from 'services/Cart'
import { useStorage64 } from 'hooks/useStorageBase64'
import { datalayerMedia } from 'functions/dataLayerFunction'
import { useIsMobile } from 'hooks/useIsMobile'
import { HeaderMobile } from'pages/Header/HeaderNavegacion/HeaderMobile'
import { HeaderDesktop } from'pages/Header/HeaderNavegacion/HeaderDesktop'
import { useLocation } from 'react-router-dom'
import { ErrorBoundary } from 'components/Error/ErrorBoundary'
import { funtionCartEmarsys, functionCartEmarsysEmpty } from 'functions/cartEmarsys'

// import getUserSingleProfile from 'services/getUserSingleProfile'
import { useValidateSession } from 'hooks/useValidateSession'
import { useToken } from 'hooks/useToken'
import { useUserInfo } from 'hooks/usePersonalData'


export default function HeaderCompleto(props) {
    const {
        page,
        setUpCP,
        upHeader = false,
        changeDirectionBar,
        searchWork
    } = props;

    const location = useLocation();

    const {} = useValidateSession()
    const validaToken = useToken({firstActive: true, name: "header Navigation"})
    const { profileGet:profileData } = useUserInfo()


    const { isMobile } = useIsMobile();
    const [isLoged] = useStorage64('session_token', '');
    const existDirPredeterminada = decodeStorage('existDirPredeterminada');
    const byCart = decodeStorage('idCarrito');
    const codigoPostal = decodeStorage('codigoPostal');
    // const [profileData, setProfileData] = useStorage64('profileData', '');
    const [direcctionsData, setDirecctionsData] = useStorage64('sendDirections', '');
    const [tItems] = useStorage64('total_items', '');
    const darkModeUser = decodeStorage('darkMode');
    const cartFormEmarsys = decodeStorage('cartFormEm');
    const [totalItems, setTotalItems] = useState(tItems);

    //- Datos que se mandan a instana
    const [cartNoNull, setCartNoNull] = useState(false) 
    useEffect(() => {
        if (isLoged) {
            if (profileData.idClaro) {
                window.ineum('meta', 'Id_Claro', profileData.idClaro);
                window.ineum('user', profileData.idClaro, profileData.nombre, profileData.correo);
                window.ScarabQueue.push(['setCustomerId', String(profileData.idClaro)]);
                window.ScarabQueue.push(['go']);
            }
            if (profileData.correo) {
                window.ineum('meta', 'User_Email', profileData.correo);
            }
            window.ineum('meta', 'Session_Id', isLoged);
            
            if(byCart === null || byCart === 'null'){
                // validaToken.forceToken(true)
                setCartNoNull(true)
            }else{
                setCartNoNull(false)
                window.ineum('meta', 'Cart', byCart);
            }

            if (navigator) {
                window.ineum('meta', 'User_Agent', navigator.userAgent);
                if (navigator.platform) {
                    window.ineum('meta', 'User_Platform', navigator.platform);
                }
                if (navigator.vendor) {
                    window.ineum('meta', 'User_Vendor', navigator.vendor);
                }
                if (navigator.connection) {
                    window.ineum('meta', 'User_Connection', navigator.connection.effectiveType);
                }
            }
            if (codigoPostal) {
                window.ineum('meta', 'Postal_Code', codigoPostal);
            }
        }
        else {
        }
        window.ineum('wrapEventHandlers', true);
        window.ineum('wrapTimers', true);
        window.ineum('ignoreErrorMessages', [/^script error/i]);
        removeStorage('modalPostalCode');
        document.body.classList.remove('is-dark-mode')
    }, [cartNoNull]);

    useEffect(() => {
        if (location.pathname.split('/')[1] != "producto") {
            document.querySelector('body').classList.remove('open');
            document.querySelector('body').classList.remove('openMobile');
        }
    }, [location]);

    //- Funcion que evalua el dark mode
    function changeMedia(mq) {
        if (sessionStorage.getItem('SelectUser') === "true") {
            if (darkModeUser) {
                document.body.classList.add('is-dark-mode');
                encodeStorage('darkMode', true);
            } else {
                document.body.classList.remove('is-dark-mode');
                encodeStorage('darkMode', false);
            }
        } else {
            if (mq.matches) {
                document.body.classList.add('is-dark-mode');
                encodeStorage('darkMode', false);
            } else {
                document.body.classList.remove('is-dark-mode');
                encodeStorage('darkMode', false);
            }
        }
    }

    //- Efecto que evalua si se encuentra con dark o light mode
    useEffect(() => {
        const mq = window.matchMedia('(prefers-color-scheme: dark)');
        try {
            mq.addEventListener('change', changeMedia(mq));
        }
        catch (e) {
            console.log('error:', e);
            mq.addListener(changeMedia(mq));
        }
        if (mq.matches) { document.body.classList.add('is-dark-mode'); }
        else { document.body.classList.remove('is-dark-mode'); }
    }, [window.matchMedia('(prefers-color-scheme: dark)').matches]);

    // - seleccion dark mode en switch
    useEffect(() => {
        if (window.matchMedia('(prefers-color-scheme: dark)')) {
            if (decodeStorage('darkMode')) { document.body.classList.add('is-dark-mode'); }
            else { document.body.classList.remove('is-dark-mode'); }
        }
    }, [decodeStorage('darkMode'), window.matchMedia('(prefers-color-scheme: dark)').matches]);

    //- Servicio que va por las direcciones del usuario si no hay direcciones en el storage
    const [direcctionsGet, setDirecctionsGet] = useState(direcctionsData);
    useEffect(() => {
        if (isLoged) {
            if (!direcctionsData) {
                getAddress()
                    .then(setDirecctionsGet)
            }
        }
    }, [isLoged, direcctionsData]);

    //- guarda las direcciones en el storage despues de la repsuesta del servicio
    const [updatePredFirst, setUpdatePredFirst] = useState();
    useEffect(() => {
        if (direcctionsGet) {
            if (direcctionsGet.address) {
                if (direcctionsGet.address[0]) {
                    if (!existDirPredeterminada) {
                        encodeStorage('codigoPostal', direcctionsGet.address[0].codigoPostal);
                        if (direcctionsGet.address[0].calle) {
                            encodeStorage('codigoPostal', direcctionsGet.address[0].codigoPostal);
                            encodeStorage('dirPredeterminada', { 'nombre': direcctionsGet.address[0].nombre, 'municipio': direcctionsGet.address[0].municipio, 'calle': direcctionsGet.address[0].calle });
                        } else {
                            encodeStorage('codigoPostal', direcctionsGet.address[0].codigoPostal);
                            encodeStorage('dirPredeterminada', { 'nombre': direcctionsGet.address[0].nombre, 'municipio': direcctionsGet.address[0].municipio, 'calle': direcctionsGet.address[0].direccion });
                        }
                        encodeStorage('selectId', direcctionsGet.address[0].id);
                        encodeStorage('existDirPredeterminada', 'true');
                        setUpdatePredFirst(true);
                        setUpCP('true');
                    } else {
                        setDirecctionsData(direcctionsGet);
                    }
                }
            } else {
                removeStorage('sendDirections');
            }
        }
    }, [direcctionsGet, updatePredFirst]);

    //- Servicio que va por los datos del usuario si no los encuentra en el storage
    // const [profileGet, setProfleGet] = useState(profileData);
    // useEffect(() => {
    //     if (isLoged) {
    //         if (!profileData) {
    //             getUserSingleProfile()
    //                 .then(setProfleGet)
    //         }
    //     }
    // }, [isLoged, profileData]);

    //- guarda los datos del usuario despues de la respuesta del servicio
    // useEffect(() => {
    //     if (profileGet) {
    //         setProfileData(profileGet);
    //     }
    // }, [profileGet]);

    //- Funcion que solicita al servicio la cantidad de productos del carrito
    const [cartCountResponse, setCartCountResponse] = useState();
    // useEffect(() => {
    //     if (isLoged) {
    //         getCartCount()
    //             .then(setCartCountResponse)
    //     }
    // }, []);

    //- llamada al carrito del portal para su payasada de emarsys
    const [respServEmar, setServRespEmar] = useState(cartFormEmarsys);
    const [okCartRespEmarsys, setOkCartRespEmarsys] = useState(false);
    useEffect(() => {
        if (isLoged) {
            if (!cartFormEmarsys) {
                getCart()
                    .then(setServRespEmar)
            }
        }
    }, []);

    useEffect(() => {
        if (respServEmar) {
            setOkCartRespEmarsys(true);
        }
    },[respServEmar])
    useEffect(()=>{
        if(okCartRespEmarsys){
            if(respServEmar){
                if(respServEmar.data){
                    if(respServEmar.data.data){
                        if(respServEmar.data.data.products){
                            try{
                                encodeStorage('cartFormEm', respServEmar.data.data.products)
                            }
                            catch{
                                encodeStorageLatin('cartFormEm', respServEmar.data.data.products)
                            }
                            setOkCartRespEmarsys(false)
                        }
                    }
                }
            }
        }
    }, [okCartRespEmarsys]);

    const [validTI, setValidTI] = useState(null)
    useEffect(() => {
        if (isLoged) {
            if (profileData.idClaro) {
                if(totalItems === 0 && validTI === 0){
                    encodeStorage('cartFormEm',null)
                    functionCartEmarsysEmpty()

                }
                else if (cartFormEmarsys) {
                    funtionCartEmarsys(cartFormEmarsys);
                }
            }
        } else {
            encodeStorage('cartFormEm',null)
            functionCartEmarsysEmpty()
        }
    }, [validTI, totalItems]);

    //- guarda los datos en el storage despues de la respuesta del servicio y actualiza el numero en el carrito
    useEffect(() => {
        if (cartCountResponse) {
            if (cartCountResponse.statusCode === 200) {
                if (typeof (cartCountResponse.data) === 'object') {
                    setTotalItems(cartCountResponse.data.data.cart.total_items);
                    setValidTI(cartCountResponse.data.data.cart.total_items)
                    encodeStorage('total_items', cartCountResponse.data.data.cart.total_items);
                }
                else {
                    console.log('cartCount', cartCountResponse);
                    if(cartCountResponse === 0){
                        console.log('asdasd', cartCountResponse)
                    }
                }
            }
        }
        else {
            setTotalItems(0);
        }
    }, [cartCountResponse]);

    //- hace el update de productos en el icono de carrito
    useEffect(() => {
        if (totalItems) {
            if (totalItems === 'undefined') {
                setTotalItems(0);
            } else {
                //console.log('add totalItems Storage is > 0')
            }
        } else {
            if (isLoged) {
                if (!totalItems) {
                    setTotalItems(totalItems);
                }
            } else {
                setTotalItems(0);
            }
        }

    }, [isLoged, totalItems]);

    useEffect(() => {
        if (upHeader) {
            setTotalItems(upHeader);
        }
    }, [upHeader]);

    useEffect(() => {
        if (isMobile === 'desktop') {
            window.localStorage.setItem('isApp', 'pwa|Desktop');
        } else if (isMobile === 'movil') {
            if (/Android/i.test(navigator.userAgent)) {
                window.localStorage.setItem('isApp', 'pwa|Android');
            } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                window.localStorage.setItem('isApp', 'pwa|iOS');
            } else {
                window.localStorage.setItem('isApp', 'pwa|Movil');
            }
        }
    }, [isMobile]);

    useEffect(() => {
        datalayerMedia(decodeStorage('darkMode'));
    }, []);

    return (
        <>
            {isMobile
                ? isMobile === 'desktop'
                    ? <ErrorBoundary>
                        <HeaderDesktop
                            isLoged={isLoged}
                            totalItems={totalItems}
                            profile={profileData}
                            changeDirectionBar={changeDirectionBar}
                            searchWork={searchWork}
                        />
                    </ErrorBoundary>
                    : isMobile === 'movile'
                        ? null
                        : <ErrorBoundary>
                            <HeaderMobile
                                isLoged={isLoged}
                                totalItems={totalItems}
                                page={page}
                                profile={profileData}
                            />
                        </ErrorBoundary>
                : null
            }
        </>
    )
}