import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { ErrorBoundary } from 'components/Error/ErrorBoundary'
import { useIsMobile } from 'hooks/useIsMobile'

import Footer from 'pages/Footer/FooterDesktop'
import Header from 'pages/Header/HeaderNavegacion'
import Loading from 'components/Loading'
import BarraCP from 'components/BarCP/BarHeaderCP'
import { encodeStorage } from 'functions/storageBase64'

function useWebview(){

    const [typeNav, setTypeNav] = useState()

    useEffect(()=>{
        if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            setTypeNav('desktop')
            encodeStorage('isMobile','desktop')
        }else{
            if(navigator.userAgent.indexOf('wv') !==  -1){
                setTypeNav('app')
                encodeStorage('isMobile','app')
                document.querySelector('#root').classList.add('webviewApp')
            }else if(navigator.userAgent.indexOf('Version') !==  -1){
                    setTypeNav('app')
                    encodeStorage('isMobile','app')
                    document.querySelector('#root').classList.add('webviewApp')
                // }
            }
            else{
                setTypeNav('movil')
                encodeStorage('isMobile','movil')
            }
        }
    },[])

    return{
        typeNav
    }
}


export function TemplateLandingAD(props) {
    const {
        children,
        title,
        loading
    } = props
    const { isMobile } = useIsMobile()

   //recibe la direccion del CP
   const [updateCPBar, setUpdateCPBar] = useState()
   const reciveDirection = (val) =>{ setUpdateCPBar(val) }
   const [upCP, setUpCP] = useState()
   useEffect(()=>{ if(upCP){ /*console.log('')*/ } },[upCP, updateCPBar])
   const { typeNav } = useWebview()

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>

            { loading && <Loading /> }
            {isMobile === 'desktop'
                ?
                    <>
                        <ErrorBoundary>
                            <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection}/>
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <section className="barCpResponsive">
                                <BarraCP changeCP={reciveDirection} clase="barHeaderCP"/>	
                            </section>
                        </ErrorBoundary>
                        <main>
                            <section className="container">
                                <div className="contCardLandingAD">                            
                                    {children}
                                </div>
                            </section>
                        </main>
                        <Footer />
                    </>
                :  
                    <>
                        <ErrorBoundary>
                            <Header page='pageHome' setUpCP={setUpCP}/>
                            <Link className="fakeSearch" to="/buscador">
                                <span>¿Qué es lo que buscas?</span>
                            </Link>
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <BarraCP changeCP={reciveDirection} clase="barHeaderCP"/>
                        </ErrorBoundary>
                        <main>
                            <section className="container">
                                <div className="contCardLandingAD">
                                    {children}
                                </div>
                            </section>
                        </main>
                    </>
            }
        </>
    )
}

function useClickActive(){

    const [tabView, setTabView] = useState(false);
    const [clase, setClase] = useState('cardLandingAD')
    
    const toggleC = () => {
        setTabView( !tabView );
    }

    useEffect(()=>{
        if(tabView){
            setClase("cardLandingAD active")
        }else{
            setClase("cardLandingAD")
        }
    },[tabView])
    
    return { clase, toggleC }
}

export function CardLandingAD(props){
    const {
        tituloLanding, 
        children
    } = props

    const { clase, toggleC } = useClickActive()

    return (
        <dl className={clase}>
            <dt onClick={toggleC} className="cardLandingTitleAD">{tituloLanding}</dt>
            <dd className="cardLandingDescripAD">
                {children}
            </dd>
        </dl>
    )
}