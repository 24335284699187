import React, { useEffect, useState } from 'react';
import { decodeStorage, encodeStorage, encodeStorageLatin, decodeStorageLatin } from 'functions/storageBase64';
import { useHistory } from 'react-router-dom';
import {repareTextLatin} from "functions/repareDataService" 
import './style.sass';

export default function HistorySearch({ ...props }) {
    const { sebusco, setTypeSearch } = props;

    var arrayBusquedas  = decodeStorageLatin('busquedas')
    
    try{
        //arrayBusquedas = decodeStorage('busquedas');
        arrayBusquedas = decodeStorageLatin('busquedas');
    }catch(e){
        encodeStorage('busquedas', []);
    }
    

    const [deleteWorld, setDeleteWorld] = useState();
    const history = useHistory();
    const url = history.location.pathname;

    // si las busquedas estan vacias , vacia el storage
    if (arrayBusquedas === '') {
        encodeStorage('busquedas', []);
    }else if(typeof(arrayBusquedas) === "string"){
        arrayBusquedas = decodeStorage('busquedas');
        encodeStorageLatin('busquedas', arrayBusquedas)
    }

    // Agrega las busquedas al storage para que se visualicen en todo el buscador
    useEffect(() => {
        if (sebusco != '') {
            if (arrayBusquedas.includes(String( repareTextLatin(sebusco) ))) {
                var indiceAeliminar = arrayBusquedas.indexOf(String( repareTextLatin(sebusco) ));
                arrayBusquedas.splice(indiceAeliminar, 1);
                arrayBusquedas.reverse().push(String( repareTextLatin(sebusco) ));
                try{
                    encodeStorage('busquedas', arrayBusquedas);
                }catch(e){
                    encodeStorageLatin('busquedas', arrayBusquedas)
                }
            }
            else {
                if (arrayBusquedas.length < 31) {
                    arrayBusquedas.reverse().push(String( repareTextLatin(sebusco) ));
                    try{
                        encodeStorage('busquedas', arrayBusquedas);
                    }catch(e){
                        encodeStorageLatin('busquedas', arrayBusquedas)
                    }
                }
                else if (arrayBusquedas.length === 31) {
                    var primero = arrayBusquedas.shift();
                    var indiceAeliminar1 = arrayBusquedas.indexOf(String(primero));
                    arrayBusquedas.splice(indiceAeliminar1, 1);
                    arrayBusquedas.push(String(sebusco));
                    try{
                        encodeStorage('busquedas', arrayBusquedas);
                    }catch(e){
                        encodeStorageLatin('busquedas', arrayBusquedas)
                    }
                }
            }
        }
    }, [sebusco, deleteWorld])

    // elimina palabra al presionar el boton "x"
    function EliminarPalabra(e) {
        var indiceAeliminar = arrayBusquedas.indexOf(String(e.target.value));
        arrayBusquedas.splice(indiceAeliminar, 1)
        encodeStorage('busquedas', arrayBusquedas.reverse());
        // try{
        // }catch(e){
        //     encodeStorageLatin('busquedas', arrayBusquedas.reverse());
        // }
        setDeleteWorld(indiceAeliminar);
    }

    // agrega el valor de la palabra seleccionada en el input de busqueda
    function ReplicarEnBuscador(e) {
        e.preventDefault();
        let valor = String(e.target.value);
        setTypeSearch( repareTextLatin(valor) );
        console.log(':    ', valor)
    }

    // realiza la busqueda directamente con la palabra clave seleccionada
    const realizarBusquedaHistorial = (e) => {
        e.preventDefault();
        let buscarTexto = String(e.target.id);
        setTypeSearch( repareTextLatin(buscarTexto) );

        setTimeout(() => {
            if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                // window.location.href = `/resultados/q=${encodeURIComponent( repareTextLatin(buscarTexto) )}/pagina=1`;
                window.location.href = `/resultados?query=${encodeURIComponent(String(buscarTexto.replace("%","")))}`
            } else {
                // history.replace(url, null);
                // history.push(`/resultados/q=${encodeURIComponent( repareTextLatin(buscarTexto) )}/pagina=1`);
                window.location.href = `/resultados?query=${encodeURIComponent(String(buscarTexto.replace("%","")))}`
            }
        }, 200);
    }

    return (
        <ul className="listaBusqueda">
            {arrayBusquedas &&
                arrayBusquedas.reverse().map((elemento, indice) =>
                    <li key={indice}>
                        <button className="cerrar" onClick={(e) => EliminarPalabra(e)} value={elemento} type="button">X</button>
                        <span onClick={(e) => realizarBusquedaHistorial(e)} id={elemento} className="span" data-id={indice}>{elemento}</span>
                        <button className="ir" onClick={(e) => ReplicarEnBuscador(e)} value={elemento} type="button"></button>
                    </li>
                )
            }
        </ul>
    )
}