import { useState, useEffect } from 'react';
import { encodeStorage, decodeStorage } from 'functions/storageBase64'
import { getCookie } from 'functions/getCookie'

export function useValidateSession(){

    const [sessionCookie, ] = useState( getCookie('token_login') )
    const [cartCookie, ] = useState( getCookie('token_cart') )
    const [tokenCookie, ] = useState( getCookie('access_token') )
    const [tokenRCookie, ] = useState( getCookie('refresh_token') )

    const [sessionStore, ] = useState( decodeStorage('session_token') )
    const [cartStore, ] = useState( decodeStorage('idCarrito') )
    const [tokenStore, ] = useState( decodeStorage('access_token') )
    const [tokenRStore, ] = useState( decodeStorage('refresh_token') )

    const [profileData, ] = useState( decodeStorage('profileData') )

    const [tokenTime, setTokenTime ] = useState( decodeStorage('access_token_exp') )
    const [tokenTimeR, setTokenTimeR ] = useState( decodeStorage('refresh_token_exp') )

    const [updateFunction, setUpdateFunction] = useState(false)

    const [actTime, setActTime ] = useState( Date.now() )
    const [atExp, setAtExp ] = useState( tokenTime * 1000 )
    const [rtExp, setRtExp ] = useState(  tokenTimeR * 1000 )


    const domain = window.location.host
    //let dominio = window.location.host
    //const domain = dominio.replace(dominio.split('.')[0], '')

    /*  Si existe storage toma los datos, si no existe busca datos de la cookie y los guarda al storage  */
    useEffect(()=>{

        setActTime( Date.now() )
        
        setAtExp( decodeStorage('access_token_exp') * 1000 )
        setRtExp( tokenTimeR * 1000 )

        // console.log('----------------------------------')
        // console.log('sessionStore: ',sessionStore)
        // console.log('cartStore: ',cartStore)
        // console.log('tokenStore: ',tokenStore)
        // console.log('tokenRStore: ',tokenRStore)
        // console.log('----------------------------------')

        if(sessionStore && cartStore){
            // console.log('Existe storage')
            setUpdateFunction(false)
            if(!sessionCookie && !cartCookie){
                // console.log('Existe storage pero no las cookies de sesion')
                document.cookie = `token_cart=${cartStore};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                document.cookie = `token_login=${sessionStore};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
            }
            if(tokenStore && tokenRStore){
                if(!tokenCookie && !tokenRCookie){
                    // console.log('Existe storage pero no las cookies de token')
                    document.cookie = `access_token=${tokenStore};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                    document.cookie = `refresh_token=${tokenRStore};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`

                }
            }
        }
        else if(sessionCookie && cartCookie){
            // console.log('No existe storage, busca datos de la cookie y los guarda al storage login')
            encodeStorage('session_token', sessionCookie )
            encodeStorage('idCarrito', cartCookie )
            if(tokenCookie && tokenRCookie){
                // console.log('No existe storage, busca datos de la cookie y los guarda al storage token')

                encodeStorage('access_token', tokenCookie )
                encodeStorage('refresh_token', tokenRCookie )

                let tokenJWT = JSON.parse( atob(tokenCookie.split('.')[1]) )
                let tokenRefreshJWT = JSON.parse( atob(tokenRCookie.split('.')[1]) )

                let profileData = {
                    nombre: tokenJWT.preferred_username,
                    correo: tokenJWT.email,
                    idClaro: tokenJWT.userid
                }
                
                encodeStorage('access_token_exp', tokenJWT.exp)
                encodeStorage('refresh_token_exp', tokenRefreshJWT.exp)
                encodeStorage('profileData', profileData)

                // console.log('profileData: ', profileData)

                setTokenTime( decodeStorage('access_token_exp') )
                setTokenTimeR( decodeStorage('refresh_token_exp') )
                setUpdateFunction(false)
            }
        }
        else{
            // console.log('No existe ni cookie ni storage')
            setUpdateFunction(false)
        }

    },[updateFunction])

    const checkValidateSession = () => setUpdateFunction(true)

    return { checkValidateSession, atExp, rtExp, actTime }
}