import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStorage64 } from 'hooks/useStorageBase64';
import { useRepairResponse } from 'hooks/useRepairJson'

import { decodeStorage, encodeStorage, removeStorage } from 'functions/storageBase64';
import { dataLayerLogin, dataLayerLogout } from 'functions/dataLayerFunction'

import { postWishlist } from 'services/Wishlist';
import { postLogin, postLoginOldPortal } from 'services/Login';
import { postLoginFacebook, getLoginFacebook } from 'services/postLoginFacebook'
import { postRegister } from 'services/postRegister'
// import { getVerificacionUsuarioTelmex } from 'services/MiTelmexServices';
// import { postLogoutOldPortal } from 'services/postLogin';

import getUserProfile from 'services/UserProfile'
import { dataLayerUserInfo } from 'functions/dataLayerFunction'
import { postMiddleware } from 'services/Login'

// nuevo login SSO
import {getLogoutSSO, getTokenSSO} from 'services/SSO'
import { useToken } from 'hooks/useToken'
import MD5 from "crypto-js/md5"


export function useLoginSSO(){

    let isLoged = decodeStorage('session_token')
    const stateSSO = decodeStorage('stateSSO')
    const nonceSSO = decodeStorage('nonceSSO' )
    const domain = window.location.hostname
    let redirectURL = decodeURIComponent( decodeStorage('redirectURL') )
    
    let code = window.location.hash?.split('&code=')[1]?.split('&')?.[0]
    let stateURL = window.location.hash?.split('state=')[1]?.split('&')?.[0]
    let nonceURL = window.location.hash?.split('nonce=')[1]?.split('&')?.[0]
    
    const tokenUD = useToken()

    const [isTrueWish, setIsTrueWish] = useStorage64('session_token', '');
    const [, setIsCart] = useStorage64('idCarrito', '');
    const [isTrueProd, setIsTrueProd] = useStorage64('idCarrito', '');
    const wishlistAfter = decodeStorage('wishlistAfter');
    const productAfter = decodeStorage('productAfter');
    const typeButton = decodeStorage('typeButton');
    
    const [urlRedirect, getUrlRedirect] = useState(false)
    const [response, setResponse] = useState(false)
    const [jwt, setJwt] = useState(false)
    const [okLoginResponse, setOkLoginResponse] = useState(false)
    const [loginFinish, setLoginFinish] = useState(false)


    // Valida el stateURL sea el mismo que regresa keycloak 
    useEffect(()=>{
        if(code){
            if(stateSSO === stateURL){
                getTokenSSO({'code': code})
                .then(setResponse)
            }else{
                window.location.href = `${window.location.origin}/login`
            }
        }else{
            window.location.href = window.location.origin
        }
    },[])

    useEffect(()=>{
        if(response){
            if(response.statusCode === 200){
                // console.log(response)
                // console.log(response.data.access_token)
                // console.log(response.data.refresh_token)

                encodeStorage('access_token',response.data.access_token)
                encodeStorage('refresh_token',response.data.refresh_token)
                document.cookie = `access_token=${response.data.access_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                document.cookie = `refresh_token=${response.data.refresh_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                
                
                let tokenJWT = window.atob(response.data.access_token?.split('.')[1])
                let tokenRefreshJWT = window.atob(response.data.refresh_token?.split('.')[1])
                
                document.cookie = `token_login=${response.data.access_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                document.cookie = `token_cart=${ MD5(`${JSON.parse(tokenJWT)?.email}`).toString()  };Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`

                // console.log('asdasdasdas: ',tokenJWT)
                // console.log('asdasdasdas: ',tokenRefreshJWT)
                
                encodeStorage('access_token_exp', JSON.parse(tokenJWT)?.exp)
                encodeStorage('refresh_token_exp', JSON.parse(tokenRefreshJWT)?.exp)

                encodeStorage('session_token', response.data.access_token)
                encodeStorage('idCarrito', MD5(`${JSON.parse(tokenJWT)?.email}`).toString() )

                setTimeout(function(){
                    setJwt(response.data.access_token)
                },700)
                
            }else if(response.statusCode === 400){
                isLoged = decodeStorage('session_token')
                let redirURL = redirectURL.replace(window.location.origin,'')
                if(isLoged){
                    if(redirURL){
                        window.location.href = redirURL
                    }
                    else{
                        window.location.href = window.location.origin
                    }
                }else{
                    window.location.href = `${window.location.origin}/login`
                }
                
            }
        }
    },[response])

    useEffect(()=>{
        if(jwt){
            setOkLoginResponse(true)
        }
    },[jwt])


    //- Evalua si viene desde un producto o wishlist
    const [responseFav, setResponseFav] = useState(false)
    useEffect(() => {
        if (okLoginResponse) {
            if (wishlistAfter) {
                setIsTrueWish( decodeStorage('session_token') )
                postWishlist({ idProduct: wishlistAfter })
                .then(setResponseFav)
            }
            else if (productAfter) {
                setIsCart( decodeStorage('idCarrito') )
                setLoginFinish(true)
                if (typeButton === 'buttonBuyNow') {
                    encodeStorage('okBuyProductAfter', true)
                } else if (typeButton === 'buttonAddToCart') {
                    encodeStorage('okCartProductAfter', true)
                }
            } else {
                // console.log('nada de wish ni prodxuto')
                setLoginFinish(true)
            }
        }
    }, [okLoginResponse])

    //- agrega al wishlist despues del login cuando vienen desde alli 
    useEffect(() => {
        if (responseFav) {
            setLoginFinish(true)
            encodeStorage('productsWishlist', [String(wishlistAfter)])
            removeStorage('wishlistAfter')
        }
    }, [responseFav])

    const [profileGet, setProfleGet] = useState(false);
    const [profileResponse, setProfleResponse] = useState();
    useEffect(() => {
        if(loginFinish){
            getUserProfile()
            .then(setProfleResponse)
            setLoginFinish(false)
        }
    }, [loginFinish]);

    useEffect(() => {
        if (profileResponse) {
            if(profileResponse?.statusCode === 200) {
                if(profileResponse?.data) {
                    if(profileResponse?.data?.data) {
                        const {
                            name,
                            email,
                            id,
                            last_name,
                            second_last_name,
                            gender,
                            birthday
                        } = profileResponse?.data?.data
                        
                        let userDataProfile = {
                            idClaro: id,
                            nombre: name,
                            apellidoP: last_name,
                            apellidoM: second_last_name,
                            nombreCompleto: name+' '+last_name+' '+second_last_name,
                            genero: gender,
                            cumpleanios: birthday,
                            correo: email
                        }
                        dataLayerUserInfo({idUser: userDataProfile.idClaro, userInfo: userDataProfile })
                        setProfleGet(userDataProfile);
                        window.ineum('meta', 'Id_Sears', id);
                        window.ineum('user', id, name, email);
                        window.ineum('meta', 'LoginDate', Date.now() );
                        encodeStorage('LoginDate', Date.now() )
                        window.ineum('meta', 'Logout', 'null');
                    }
                }
            }
            else if(profileResponse.statusCode === 401){
                setLoginFinish(true)
            }
            else{
                console.log('error: ', profileResponse.statusCode )
            }
        }
    }, [profileResponse]);


    useEffect(()=>{
        if(profileGet){
            isLoged = decodeStorage('session_token')
            encodeStorage('profileData', profileGet)
            encodeStorage('backAutenticate', true)
            let redirURL = redirectURL.replace(window.location.origin,'')
            // console.log('redirURL: ',redirURL)
            if(isLoged){
                if(redirURL){
                    window.location.href = redirURL
                }else{
                    window.location.href = window.location.origin
                }
                //setLoading(false)
            }
        }
    },[profileGet])


    // recibe los datos del formulario
    const reciveRedirect = (val) => {
        encodeStorage('backAutenticate', true)
        setTimeout(function(){
            getUrlRedirect(val)
        },700)
    }
    
    const dataLogin = {  }

    return {
        dataLogin,
        reciveRedirect
    }
}


function autorizeRem(){
    removeStorage('tokenOTP')
    removeStorage('validateType')
}

export function usePostLogin(){

    const [successForm, getSuccessForm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isLoged, setIsLoged] = useStorage64('session_token', '');
    const [isTrueWish, setIsTrueWish] = useStorage64('session_token', '');
    const [, setIsCart] = useStorage64('idCarrito', '');
    const [, setProfileData] = useStorage64('profileData', '');
    const [errorMessage, setErrorMessage] = useState();
    const [statusCode, setStatusCode] = useState();
    const wishlistAfter = decodeStorage('wishlistAfter');
    const productAfter = decodeStorage('productAfter');
    const typeButton = decodeStorage('typeButton');
    const location = useLocation();
    const history = useHistory();
    const redir = location.search;
    const domain = window.location.hostname


    // recibe la respuesta del servicio de Login
    const [okLoginResponse, setOkLoginResponse] = useState(false)
    const [susccessUsuarioTieneTelmex, getsusccessUsuarioTieneTelmex] = useState([]);
    const [tokenService, setTokenService] = useState()
    useEffect(() => {
        if (successForm) {
            if (successForm?.statusCode === 200) {
                autorizeRem()
                if (successForm?.data) {
                    if (successForm?.data?.data) {
                        if (successForm?.data?.data?.session_token) {

                            removeStorage('telefono_usuario')
                            removeStorage('saldoTelmexService')
                            // getVerificacionUsuarioTelmex({ token: successForm.data.data.session_token })
                            // .then(getsusccessUsuarioTieneTelmex)

                            if (successForm?.data?.data?.access_token) {
                                setTokenService('access_token', successForm?.data?.data?.access_token)
                                encodeStorage('access_token', successForm?.data?.data?.access_token)
                                encodeStorage('refresh_token', successForm?.data?.data?.refresh_token)
                                //document.cookie = `jwt=${successForm.data.data.access_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                                document.cookie = `access_token=${successForm?.data?.data?.access_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
                                document.cookie = `refresh_token=${successForm?.data?.data?.refresh_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`

                                let tokenJWT = JSON.parse(window.atob(successForm?.data?.data?.access_token.split('.')[1]))
                                let tokenRefreshJWT = JSON.parse(window.atob(successForm?.data?.data?.refresh_token.split('.')[1]))

                                let profileData = {
                                    nombre: tokenJWT?.preferred_username,
                                    correo: tokenJWT?.email,
                                    idClaro: tokenJWT?.userid
                                }

                                encodeStorage('access_token_exp', tokenJWT?.exp)
                                encodeStorage('refresh_token_exp', tokenRefreshJWT?.exp)
                                encodeStorage('profileData', profileData)
                            }

                            setTimeout(function () {
                                setOkLoginResponse(true)
                            }, 1000)

                        }
                    }
                }
            }
            else if (successForm.statusCode === 401) {
                autorizeRem()
                setLoading(false)
                errorLogin()
            }
            else if (successForm.statusCode === 403) {
                autorizeRem()
                setLoading(false)
                errorLogin('Su Usuario no esta autorizado para acceder a este sitio')
            }
            else if (successForm.statusCode === 412) {
                setLoading(false)
                if (successForm?.data) {
                    encodeStorage('tokenOTP', successForm?.data?.access_token)
                    encodeStorage('validateType', successForm?.data?.validate)
                    history.push(`/autorizar${redir}`)
                }
            }
            else if (successForm.statusCode === 500) {
                autorizeRem()
                setLoading(false)
                setStatusCode(500)
            }
        }
    }, [successForm])

    // Bloque de facebook
    const [dataFB, setDataFB] = useStorage64('dataFacebook', '')
    const [successFB, getSuccessFB] = useState(false)
    useEffect(() => {
        if (successFB) {
            if (successFB?.data) {
                if (successFB?.data?.session_token) {
                    getSuccessForm({ token: successFB?.data?.session_token, idCarrito: successFB?.data?.hash_cart })
                    removeStorage('telefono_usuario')
                    removeStorage('saldoTelmexService')
                    // getVerificacionUsuarioTelmex({ token: successFB.data.session_token })
                    // .then(getsusccessUsuarioTieneTelmex)

                    setTimeout(function () {
                        setOkLoginResponse(true)
                    }, 1000)
                }
            }
            else if (successForm.statusCode === 412) {
                setLoading(false)
                if (successForm?.data) {
                    encodeStorage('tokenOTP', successForm?.data?.access_token)
                    encodeStorage('validateType', successForm?.data?.validate)
                    history.push(`/autorizar${redir}`)
                }
            }
            else if (successFB?.error >= 400 || successFB?.error <= 499) {
                getSuccessForm({ message: successFB?.message })
                errorLogin()
                setLoading(false)
            }
            else if (successFB.error === 500) {
                autorizeRem()
                getSuccessForm({ message: successFB?.message })
                setLoading(false)
                setStatusCode(500)
            }
        }
    }, [successFB])


    // guarda el numero de telefono del usuario 
    useEffect(() => {
        if (susccessUsuarioTieneTelmex != undefined && susccessUsuarioTieneTelmex) {
            for (const property in susccessUsuarioTieneTelmex?.data) {
                if (susccessUsuarioTieneTelmex?.data[property].phone != undefined) {
                    encodeStorage('telefono_usuario', susccessUsuarioTieneTelmex?.data[property].phone)
                }
            }
        }
    }, [susccessUsuarioTieneTelmex])


    const [finishL, setFinishL] = useState(false)
    //- Evalua si viene desde un producto o wishlist
    const [responseFav, setResponseFav] = useState(false)
    useEffect(() => {
        if (okLoginResponse) {
            if (wishlistAfter) {
                setIsTrueWish(successForm?.data?.data?.session_token)
                postWishlist({ idProduct: wishlistAfter })
                .then(setResponseFav)
            } else if (productAfter) {
                setIsCart(successForm?.data?.data?.cart_hash)
                setFinishL(true)
                if (typeButton === 'buttonBuyNow') {
                    encodeStorage('okBuyProductAfter', true)
                } else if (typeButton === 'buttonAddToCart') {
                    encodeStorage('okCartProductAfter', true)
                }
                setLoading(false)
            } else {
                //console.log('nada de wish ni prodxuto')
                setFinishL(true)
                setLoading(false)
            }
        }
    }, [okLoginResponse])


    //- agrega al wishlist despues del login cuando vienen desde alli 
    useEffect(() => {
        if (responseFav) {
            setFinishL(true)
            encodeStorage('productsWishlist', [String(wishlistAfter)])
            removeStorage('wishlistAfter')
            setLoading(false)
        }
    }, [responseFav])

    const [profileResponse, setProfleResponse] = useState();
    useEffect(()=>{
        if(finishL){
            setFinishL(false)
            setLoading(true)
            if(successForm?.data?.data?.session_token){
                getUserProfile({token: successForm?.data?.data?.session_token})
                .then(setProfleResponse)
            }else if(successFB?.data?.session_token){
                getUserProfile({token: successFB?.data?.session_token})
                .then(setProfleResponse)
            }

        }
    },[finishL])

    //- guarda los datos del usuario despues de la respuesta del servicio
    const [profileGet, setProfleGet] = useState(false);
    useEffect(() => {
        if (profileResponse) {
            if(profileResponse?.statusCode === 200) {
                if(profileResponse?.data) {
                    if(profileResponse?.data?.data) {
                        const {
                            name,
                            email,
                            id,
                            last_name,
                            second_last_name,
                            gender,
                            birthday
                        } = profileResponse?.data?.data
                        
                        let userDataProfile = {
                            idClaro: id,
                            nombre: name,
                            apellidoP: last_name,
                            apellidoM: second_last_name,
                            nombreCompleto: name+' '+last_name+' '+second_last_name,
                            genero: gender,
                            cumpleanios: birthday,
                            correo: email
                        }
                        dataLayerUserInfo({idUser: userDataProfile?.idClaro, userInfo: profileResponse?.data?.data })
                        setProfleGet(userDataProfile);
                    }
                }
            }
            else{
                console.log('error: ', profileResponse.statusCode )
            }
        }
    }, [profileResponse]);

    const [tokenMiddel, setTokenMiddel] = useState(false);
    useEffect(()=>{
        if(profileGet){
            setProfileData(profileGet);
            //console.log('profile: ', profileGet)
            if(profileGet?.correo){
                if(successForm?.data?.data?.session_token){
                    postMiddleware({email: profileGet?.correo, idSession: successForm?.data?.data?.session_token })
                    .then(setTokenMiddel)
                }
                else if(successFB?.data?.session_token){
                    postMiddleware({email: profileGet?.correo, idSession: successFB?.data?.session_token })
                    .then(setTokenMiddel)
                }
            }
        }
    },[profileGet])

    useEffect(()=>{
        if(tokenMiddel?.statusCode === 200){
            if(tokenMiddel?.data?.access_token){
                let tokenJWT = JSON.parse( window.atob(tokenMiddel?.data?.access_token.split('.')[1]) )
                encodeStorage('access_token', tokenMiddel?.data?.access_token)
                encodeStorage('refresh_token', tokenMiddel?.data?.access_token)
                encodeStorage('access_token_exp', (tokenJWT.exp) )
                encodeStorage('refresh_token_exp', (tokenJWT.exp + (60 * 60 * 24 * 92)) )
            }
        }
    },[tokenMiddel])

    useEffect(() => {
        if (tokenMiddel) {
            login()
        }
    }, [tokenMiddel]);

    const [successLogin, setSuccessLogin] = useState(false)
    // funcion cuando todo sale OK al login
    const login = () => {
        // localStorage.clear()
        removeStorage('session_token')
        removeStorage('idCarrito')
        removeStorage('sendDirections')
        removeStorage('total_items')
        removeStorage('profileData')
        removeStorage('productsWishlist')
        removeStorage('cartData')
        removeStorage('selectId')
        removeStorage('shipping')
        removeStorage('dirPredeterminada')
        removeStorage('codigoPostal')
        removeStorage('existDirPredeterminada')
        removeStorage('telefono_usuario')
        removeStorage('saldoTelmexService')
        removeStorage('cartFormEm')
        removeStorage('busquedas')
        removeStorage('dataFacebook')
        removeStorage('wishlistAfter')

        /*-- Telmex variables --*/
        removeStorage("modalTPC")
        removeStorage("modalTPC_D")
        window.sessionStorage.removeItem("modalTPC")
        window.sessionStorage.removeItem("modalTPC_D")

        // VARIABLES CONSULTA CREDITO CS HOME / DETALLE PRODUCTO
        removeStorage('cardending')
        removeStorage("sears_numero_internet");
        removeStorage("montoCredito");
        removeStorage("creditoDisponible");
        removeStorage("fechaManana");
        removeStorage("estadoCredito");
        window.localStorage.removeItem("closebtnCCSdetalle");
        window.localStorage.removeItem("closebtnCCShome");

        // removeStorage('typeButton')
        // removeStorage('productAfter')
        // removeStorage('okCartProductAfter')
        // removeStorage('okBuyProductAfter')
        if(successForm?.data?.data?.session_token){
            document.cookie = `token_cart=${successForm?.data?.data?.cart_hash};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
            document.cookie = `token_login=${successForm?.data?.data?.session_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
            setIsLoged(successForm?.data?.data?.session_token)
            setIsCart(successForm.data.data.cart_hash)
        }
        else if(successFB?.data?.session_token){
            document.cookie = `token_cart=${successFB?.data?.hash_cart};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
            document.cookie = `token_login=${successFB?.data?.session_token};Max-Age=${60 * 60 * 24 * 365};Path=/;Domain=${domain};`
            setIsLoged(successFB?.data?.session_token)
            setIsCart(successFB?.data?.hash_cart)
        }

        window.localStorage.removeItem("closebtnMT-detalle")
        window.localStorage.removeItem("closebtnMT-home")
        setProfileData(profileGet)
        dataLayerLogin()
        setSuccessLogin(true)
    }


    // funcion cuando regresa algun error con el login
    const errorLogin = (message = 'Usuario y/o Contraseña no validos') => {

        setErrorMessage(message)
    }

    const reciveResponseLogin = (value) =>{
        getSuccessForm(value)
        setLoading(true)
        //console.log('reciveResponseLogin: ', value)
    }
    const reciveResponseFBLogin = (value) =>{
        getSuccessFB(value)
        setLoading(true)
        //console.log('reciveResponseFBLogin: ', value)
    }


    return {
        reciveResponseLogin,
        reciveResponseFBLogin,
        isLoged,
        loading,
        statusCode,
        errorMessage
    }

}


export function useLogin() {

    const [successForm2, getSuccessForm2] = useState(false);
    const [dataForm, getDataForm] = useState(false);
    const [loading, setLoading] = useState(true);

    const location = useLocation();
    const history = useHistory();
    const redir = location.search;
    const hostName = window.location.origin;

    const {
        reciveResponseLogin,
        reciveResponseFBLogin,
        isLoged,
        loading: loading2,
        statusCode,
        errorMessage
    } = usePostLogin()

    useEffect(()=>{
        if(String(loading2) === 'true'){
            setLoading(loading2)
        }else{
            setLoading(false)
        }
    },[loading2])


    // valida si tienes sesion activa y regresa 1 al historico
    useEffect(() => {
        if (isLoged) {
            if (redir.split('=')[0] === '?redirect') {
                if (redir.split('=')[1].split('/')[2] === "mis-pedidos" || redir.split('=')[1].split('/')[3] === "mis-pedidos") {
                    setTimeout(function () {
                        window.location.href = redir.split('=')[1].replace(hostName, '')
                    }, 500)
                }
                else if (redir.split('=')[1].split('/')[1] === "Caja" || redir.split('=')[1].split('/')[2] === "Caja" || redir.split('=')[1].split('/')[3] === "Caja") {
                    setTimeout(function () {
                        window.location.href = redir.split('=')[1].replace(hostName, '')
                    }, 500)
                }
                else if (redir.split('=')[1].split('/')[2] === "mis-datos" || redir.split('=')[1].split('/')[3] === "mis-datos") {
                    setTimeout(function () {
                        window.location.href = redir.split('=')[1].replace(hostName, '')
                    }, 500)
                }
                else if (redir?.indexOf('/Mesa-de-Regalos/') >= 1 || redir?.indexOf('/Mesa-de-Regalos') >= 1) {
                    setTimeout(function () {
                        window.location.replace(redir.split('=')[1].replace(hostName, ''))
                    }, 500)
                }
                else if (redir?.indexOf('/credito-claroshop/') >= 1) {
                    setTimeout(function () {
                        window.location.replace(redir.split('=')[1].replace(hostName, ''))
                    }, 500)
                }
                else if (redir?.indexOf('/c/') >= 1) {
                    setTimeout(function () {
                        window.location.href = redir.split('=')[1].replace(hostName, '')
                    }, 500)
                }
                else {
                    setTimeout(function () {
                        history.replace(redir.replace(`?redirect=${hostName}`, ''))
                    }, 500)
                }
            }
            else {
                setTimeout(function () {
                    history.replace('/')
                }, 500)
            }
        } else {
            setLoading(false)
        }
    }, [isLoged])

    // manda la peticion al servicio de login
    useEffect(() => {
        if (dataForm) {
            setTimeout(function () {
                postLogin({ raw: { password: dataForm?.contrasena, email: dataForm?.usuario } })
                .then(getSuccessForm2)
            }, 100)
        }
        return
    }, [dataForm])

    useEffect(() => {
        if (successForm2) {
            reciveResponseLogin(successForm2)
        }
        return
    }, [successForm2])


    // // Bloque de facebook
    const [flaglogin, setFlagLogin] = useState(false);
    const [respFace, setRespFace] = useState()
    const [dataFB, setDataFB] = useStorage64('dataFacebook', '')
    const [, setErrorFacebook] = useState(false)
    const reciveDataFacebook = (response) => {
        setLoading(true)
        setRespFace(response)
    }
    useEffect(() => {
        if (respFace) {
            if (respFace.accessToken && respFace.email) {
                if ((typeof (respFace.accessToken) === 'string') && typeof (respFace.email) === 'string') {
                    removeStorage('telefono_usuario')
                    removeStorage('saldoTelmexService')
                    setFlagLogin(true)
                    setDataFB(respFace)
                    setErrorFacebook(false)
                } else {
                    setFlagLogin(false);
                    getSuccessForm2({ message: 'El usuario no tiene los permisos necesarios para iniciar sesión con facebook' })
                }
            } else {
                setFlagLogin(false);
                getSuccessForm2({ message: 'El usuario no tiene los permisos necesarios para iniciar sesión con facebook' })
            }
        }
    }, [respFace])

    const [successFB2, getSuccessFB2] = useState(false)
    useEffect(() => {
        if (flaglogin === true) {
            if (dataFB) {
                setTimeout(function () {
                    postLoginFacebook({ raw: { "email": dataFB?.email, "access_token": dataFB?.accessToken } })
                    .then(getSuccessFB2)
                }, 100)
            }
        }
        return
    }, [flaglogin, dataFB])

    useEffect(() => {
        if (successFB2) {
            reciveResponseFBLogin(successFB2)
        }
        return
    }, [successFB2])

    // recibe los datos del formulario
    const reciveDataLogin = (val) => {
        getDataForm(val)
        setLoading(true)
    }

    const dataLogin = {
        isLoged,
        loading,
        errorMessage,
        redir,
        statusCode
    }

    return {
        dataLogin,
        reciveDataLogin,
        reciveDataFacebook
    }
}


export function useLogout() {

    const history = useHistory();
    const domain = window.location.hostname
    //let dominio = window.location.host
    //const domain = dominio.replace(dominio.split('.')[0], '')

    const [logoutClick, setLogoutClick] = useState(false)
    
    const [finish, setFinish] = useState(false)
    const [respLogO, setRespLogO] = useState(false)
    const [alter, setAlter] = useState(false)
    
    useEffect(()=>{
        if(logoutClick){
            getLogoutSSO()
            .then(setRespLogO)
            setTimeout(function(){
                setAlter(true)
            },700)
        }
    },[logoutClick])
    
    useEffect(()=>{
        // console.log('****************')
        // console.log('logoutClick:  ',logoutClick)
        // console.log(':::  ',respLogO)
        // console.log(':::  ',respLogO.statusCode)
        // console.log('alter:  ',alter)
        // console.log('finish:  ',finish)
        if(respLogO){
            if(respLogO?.statusCode === 204){
                setFinish(true)
                setAlter(false)
                setLogoutClick(false)
            }
            else if(respLogO?.statusCode === 400){
                setFinish(true)
                setAlter(false)
                setLogoutClick(false)
            }else{
                setFinish(false)
                setAlter(false)
                setLogoutClick(false)
            }
        }else if(alter){
            setFinish(true)
            setAlter(false)
            setLogoutClick(false)
        }
    },[respLogO, alter])


    useEffect(()=>{
        if(finish){

            console.log('useLogin - logOut')
            removeStorage('session_token')
            removeStorage('idCarrito')
            removeStorage('sendDirections')
            removeStorage('total_items')
            removeStorage('profileData')
            removeStorage('productsWishlist')
            removeStorage('cartData')
            removeStorage('selectId')
            removeStorage('shipping')
            removeStorage('dirPredeterminada')
            removeStorage('codigoPostal')
            removeStorage('existDirPredeterminada')
            removeStorage('telefono_usuario')
            removeStorage('saldoTelmexService')
            removeStorage('cartFormEm')
            removeStorage('busquedas')
            removeStorage('dataFacebook')
            removeStorage('typeButton')
            removeStorage('wishlistAfter')
            removeStorage('productAfter')
            removeStorage('okCartProductAfter')
            removeStorage('okBuyProductAfter')
            removeStorage('access_token')
            removeStorage('refresh_token')
            removeStorage('refresh_token_exp')
            removeStorage('access_token_exp')

            /*-- Telmex variables --*/
            removeStorage("modalTPC")
            removeStorage("modalTPC_D")
            window.sessionStorage.removeItem("modalTPC")
            window.sessionStorage.removeItem("modalTPC_D")


            removeStorage('stateSSO')
            removeStorage('nonceSSO')
            removeStorage('redirectURL')

            
            /*-- Credito variables --*/
            removeStorage('sears_numero_internet')
            removeStorage('cardending')
            removeStorage('nombreTitular')
            removeStorage('email')
            removeStorage('associatedCards')
            removeStorage('lastConsultData')
            removeStorage('payCardRelation')
            removeStorage('addCardPreLogin')
            removeStorage('clientT1')
            removeStorage("creditoCS_cupon")
            window.localStorage.removeItem('dateModalCCS_PC')

            // VARIABLES CONSULTA CREDITO CS HOME / DETALLE PRODUCTO
            removeStorage("montoCredito");
            removeStorage("creditoDisponible");
            removeStorage("fechaManana");
            removeStorage("estadoCredito");
            removeStorage("flagAceptoCredito");
            window.localStorage.removeItem("closebtnCCSdetalle");
            window.localStorage.removeItem("closebtnCCShome");
            window.localStorage.removeItem("closebtnMT-detalle")
            window.localStorage.removeItem("closebtnMT-home")

            /*-- Credito variables --*/


            document.cookie = `SSID=;Max-Age=${0};Path=/;Domain=${domain};`
            document.cookie = `SCID=;Max-Age=${0};Path=/;Domain=${domain};`
            document.cookie = `jwt=;Max-Age=${0};Path=/;Domain=${domain};`
            document.cookie = `access_token=;Max-Age=${0};Path=/;Domain=${domain};`
            document.cookie = `refresh_token=;Max-Age=${0};Path=/;Domain=${domain};`

            document.cookie = `token_login=;Max-Age=${0};Path=/;Domain=${domain};`;
            document.cookie = `token_cart=;Max-Age=${0};Path=/;Domain=${domain};`;
            document.cookie = `token_api_manager=;Max-Age=${0};Path=/;Domain=${domain};`;

            //postLogoutOldPortal();
            //removeStorage('LoginDate')
            encodeStorage('LogoutDate', Date.now() )
            dataLayerLogout();
            setTimeout(function () {
                if (history.location.pathname === "/")
                    window.location.reload();
                else if (!history.location.pathname.includes("login")) {
                    history.replace("/");
                }
                else {
                    window.location.reload();
                }
            }, 700)
            setFinish(false)
        }
    },[finish])


    const logOut = () => {
        setLogoutClick(true)
    }
    return {
        logOut
    }
}

export function useRegistro() {

    const {
        dataLogin,
        reciveDataLogin,
        reciveDataFacebook
    } = useLogin()

    const history = useHistory();

    const [isLoged,] = useState(dataLogin.isLoged)
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const [redir,] = useState(dataLogin.redir)
    const [statusCode, setStatusCode] = useState()

    const [successForm, getSuccessForm] = useState(false)
    const [successRegister, getSuccessRegister] = useState(false)
    const [dataForm, getDataForm] = useState(false)
    const {
        responseData,
        setData
    } = useRepairResponse()

    // manda la peticion al servicio de Registro
    useEffect(() => {
        if (dataForm) {
            postRegister({ raw: dataForm })
            .then(getSuccessForm)
        }
        return
    }, [dataForm])

    // recibe la respuesta del servicio de registro
    useEffect(() => {
        if (successForm) {
            if (successForm?.statusCode === 200) {
                if (typeof (successForm?.data) === 'object') {
                    if (successForm?.data) {
                        if (successForm?.data?.code == 10) {
                            setErrorMessage(successForm?.data?.msg)
                            setLoading(false)
                            window.scrollTo(0, 0)
                        } else if (successForm?.data?.code == 11) {
                            getSuccessRegister(true)
                            setLoading(true)
                        }
                    }
                }
                if (typeof (successForm?.data) === 'string') {
                    setData(successForm?.data)
                    setLoading(false)
                }
                else {
                    setLoading(false)
                }
            }
            else if (successForm.statusCode === 412) {
                setLoading(false)
                if (successForm?.data) {
                    encodeStorage('tokenOTP', successForm?.data?.access_token)
                    encodeStorage('validateType', successForm?.data?.validate)
                    history.push(`/autorizar${redir}`)
                }
            }
            else if (successForm?.statusCode >= 500) {
                setStatusCode(successForm?.statusCode)
                setLoading(false)
            }
        }
    }, [successForm])

    //corrige respuesta si regresa un string
    useEffect(() => {
        if (responseData) {
            if (responseData?.code == 10) {
                getSuccessRegister(true)
                setErrorMessage(responseData?.msg)
                setLoading(false)
                window.scrollTo(0, 0)
            } else if (responseData?.code == 11) {
                getSuccessRegister(true)
                setLoading(true)
            }
        }
    }, [responseData])


    // manda los datos del registro al login
    const [respFace, setRespFace] = useState()
    useEffect(() => {
        let raw = { usuario: dataForm?.correo, contrasena: dataForm?.password }
        if (successRegister) {
            reciveDataLogin(raw)
        } else if (respFace) {
            reciveDataFacebook(respFace)
        }
    }, [successRegister, respFace])


    // recibe los datos del formulario
    const reciveDataRegistro = (val) => {
        getDataForm(val)
        setLoading(true)
    }

    const reciveDataRegistroFacebook = (response) => {
        setLoading(true)
        setRespFace(response)
    }

    const dataRegistro = {
        isLoged,
        loading,
        errorMessage,
        redir,
        statusCode
    }

    return {
        dataRegistro,
        reciveDataRegistro,
        reciveDataRegistroFacebook
    }
}