import React, {useState}  from 'react'
import './style.sass'

export default function BoxContacto(){

    const [menuView, setMenuView] = useState(false)
    const toggleClass = () => {
        setMenuView( !menuView )
    }
    
    return(
        <div className="boxContacto">
            <p onClick={toggleClass} className={menuView ? 'active' : null} >Contáctanos</p>
            <ul>
                {/*<li>República Mexicana</li>
                <li><a href="tel:8008906566">800-890-6566</a></li>
                <li>CDMX y área metropolitana</li>
                <li><a href="tel:5512030502">55-1203-0502</a></li>
    <li>Correo: <a href="mailto: clientes@claroshop.com">clientes@claroshop.com</a></li>*/}
                <li>WhatsApp</li>
                <li><a href='https://api.whatsapp.com/send?phone=+525512030502&text=Hola,%20necesito%20ayuda' target="_blank" >55-12-03-05-02</a></li>
            </ul>
        </div>
    )
}