import { useState } from 'react'

/* Storage base64 Prod */
export function useStorage64 (key, initalValue){
    
    const [storageValue, setStoredValue] = useState( () => {
        try{
            const item = window.localStorage.getItem( btoa(key) )
            return item ? JSON.parse(atob(item)) : initalValue
        }catch(error){
            return initalValue
        }
    })

    const setValue = value =>{
        try{
            setStoredValue(value)
            window.localStorage.setItem( btoa(key) , btoa( JSON.stringify(value) ) )
        }catch(error){
            console.error(error)
        }
    }

    const removeItem = value =>{
        //console.log(value)
        if(value){
            window.localStorage.removeItem(key)
            window.localStorage.removeItem( btoa(key) )
        }
    }

    return [storageValue, setValue, removeItem]
}


/* Storage base64 Latin Prod */
export function useStorage64Latin (key, initalValue){
    
    const [storageValue, setStoredValue] = useState( () => {
        try{
            const item = window.localStorage.getItem( btoa(unescape(encodeURIComponent( key) ) ) )
            return item ? JSON.parse( decodeURIComponent( escape( unescape( atob(item) ) ) ) ) : initalValue
        }catch(error){
            return initalValue
        }
    })

    const setValue = value =>{
        try{
            setStoredValue(value)
            window.localStorage.setItem( btoa(unescape(encodeURIComponent( key) ) ) , btoa(unescape(encodeURIComponent(  JSON.stringify(value) ) ) ) )
        }catch(error){
            console.error(error)
        }
    }

    const removeItem = value =>{
        //console.log(value)
        if(value){
            window.localStorage.removeItem(key)
            window.localStorage.removeItem( btoa(unescape(encodeURIComponent( key) ) ) )
        }
    }

    return [storageValue, setValue, removeItem]
}





/* Storage base64 Develop */

// export function useStorage64 (key, initalValue){
    
//     const [storageValue, setStoredValue] = useState( () => {
//         try{
//             const item = window.localStorage.getItem( key )
//             return item ? JSON.parse(item) : initalValue
//         }catch(error){
//             return initalValue
//         }
//     })

//     const setValue = value =>{
//         try{
//             setStoredValue(value)
//             window.localStorage.setItem(key , JSON.stringify(value) )
//         }catch(error){
//             console.error(error)
//         }
//     }

//     const removeItem = value =>{
//         //console.log(value)
//         if(value){
//             window.localStorage.removeItem(key)
//         }
//     }

//     return [storageValue, setValue, removeItem]
// }