import React, { useEffect, useState } from 'react'
import { Link, useHistory } from "react-router-dom"
import { useStorage64 } from 'hooks/useStorageBase64'
import { BASE_PATH_PORTAL } from 'constants/general'
import { useLogout } from 'hooks/useLogin'
import './styleheaderLogin.sass'

export function HeaderMiCuenta({ ...props }) {
    const {
        ruta = '/login',
        profile
    } = props
    const [isLoged] = useStorage64('session_token', '')
    const [profileData] = useStorage64('profileData', '')
    const [dataFace] = useStorage64('dataFacebook', '')
    const [nombre, setNombre] = useState(profileData.nombre)
    const history = useHistory()
    const hostName = window.location.href
    const hostNameReturn = window.location.pathname
    const [nombre0, setnombre0] = useState("")

    let nombreArray = []

    const { logOut } = useLogout()

    useEffect(() => {

        if (isLoged && (!profileData || !dataFace)) {
            setNombre(profile.nombre)
            if (profile.nombre) {
                nombreArray = profile.nombre.split(' ')
                setnombre0(nombreArray[0])
            }
        }
    }, [profile, nombre])

    useEffect(() => {
        if (isLoged && dataFace.name) {
            nombreArray = dataFace.name.split(' ')
            setnombre0(nombreArray[0])
        }
    }, [dataFace])


    // const logOut = () => {
    //     postLogoutOldPortal()
    //     removeStorage('session_token')
    //     removeStorage('idCarrito')
    //     removeStorage('sendDirections')
    //     removeStorage('total_items')
    //     removeStorage('profileData')
    //     removeStorage('productsWishlist')
    //     removeStorage('cartData')
    //     removeStorage('selectId')
    //     removeStorage('shipping')
    //     removeStorage('dirPredeterminada')
    //     removeStorage('codigoPostal')
    //     removeStorage('existDirPredeterminada')
    //     removeStorage('telefono_usuario')
    //     removeStorage('saldoTelmexService')
    //     removeStorage('cartFormEm')
    //     removeStorage('busquedas')
    //     removeStorage('dataFacebook')
    //     removeStorage('typeButton')
    //     removeStorage('wishlistAfter')
    //     removeStorage('productAfter')
    //     removeStorage('okCartProductAfter')
    //     removeStorage('okBuyProductAfter')
    //     document.cookie = `token_login=;Max-Age=${0};Path=/;Domain=claroshop.com;`
    //     document.cookie = `token_cart=;Max-Age=${0};Path=/;Domain=claroshop.com;`
    //     document.cookie = `token_api_manager=;Max-Age=${0};Path=/;Domain=claroshop.com;`
    //     dataLayerLogout()
    //     window.localStorage.removeItem("closebtnMT-detalle")
    //     window.localStorage.removeItem("closebtnMT-home")
    //     setTimeout(function(){

    //         if (history.location.pathname === "/")
    //             window.location.reload()
    //         else if (!history.location.pathname.includes("login")) {
    //             history.replace("/")
    //         }
    //         else {
    //             //history.replace(hostNameReturn)
    //             window.location.reload()
    //         }
    //     }, 300)

    // }

    return (
        <div className="login icoMiCuenta">
            {/* <Link
                to={ruta}
                className="icoMiCuenta"
                aria-label="Ir a Wishlist"
            > */}

            {isLoged && (nombre || nombre0)
                ? <>
                    <span className="textReference">
                        <span className="smallText">Bienvenido</span> {String(nombre0).length > 8 ? <>{String(nombre0).substring(0, 7)} ...</> : String(nombre0)}
                    </span>

                    <div className="box__login" id="box__login">
                        <dl>
                            <dt>
                                <Link to={"/mi-cuenta"}>Entrar a mi cuenta</Link>
                            </dt>
                            <dt>
                                {/* <Link to={"/mi-cuenta/mis-pedidos/dias=30/pagina=1"}>Revisar mis pedidos</Link> */}
                                {/* {ruta === '/login'
                                    ? <Link to={`/login?redirect=${BASE_PATH_PORTAL}/mi-cuenta/mis-pedidos/1/estatus/2/`}>Revisar mis pedidos</Link>
                                    : <a href={`${BASE_PATH_PORTAL}/mi-cuenta/mis-pedidos/1/estatus/2/`}>Revisar mis pedidos</a>
                                } */}
                                {ruta === '/login'
                                    ? <Link to={`/login?redirect=${BASE_PATH_PORTAL}/mi-cuenta/mis-pedidos/dias=30/pagina=1`}>Revisar mis pedidos</Link>
                                    : <Link to={`${BASE_PATH_PORTAL}/mi-cuenta/mis-pedidos/dias=30/pagina=1`}>Revisar mis pedidos</Link>
                                }

                            </dt>
                        </dl>
                        <dl className="logOut">
                            <dt>
                                <p>¿ No eres {nombre0} ?</p>
                                <div className="cerrar" onClick={logOut}>Cerrar mi sesión</div>
                            </dt>
                        </dl>
                    </div>
                </>
                : <>
                    <span className="textReference">
                        <span className="smallText">Hola</span> Registrate
                    </span>
                    <div className="box__login" id="box__login">
                        <dl className="logIn">
                            <dt>
                                <Link className="redBtn btn-login" to={`/login?redirect=${hostName}`} >Ingresar</Link>
                            </dt>
                            <dd>
                                <p>¿Eres Nuevo?</p>
                                <Link className="standardBtn btn-registro" to={`/registro?redirect=${hostName}`}>Regístrate</Link>
                            </dd>
                        </dl>
                    </div>
                </>
            }

            {/* </Link> */}
        </div>
    )
}