import {useState, useEffect} from 'react'
import { useStorage64 } from 'hooks/useStorageBase64'
import { removeStorage, encodeStorage, decodeStorage } from 'functions/storageBase64'
import getUserProfile from 'services/UserProfile'
import { dataLayerUserInfo } from 'functions/dataLayerFunction'
import { postMiddleware } from 'services/Login' 


export function useUserInfo(){

    const [isLoged] = useStorage64('session_token', '');
    const [profileData, setProfileData] = useStorage64('profileData', '');
    const [udData, setUdData] = useState(false)

    //- Servicio que va por los datos del usuario si no los encuentra en el storage
    const [profileGet, setProfleGet] = useState(profileData);
    const [profileResponse, setProfleResponse] = useState();
    useEffect(() => {
        if (isLoged) {
            if (!profileData) {
                getUserProfile()
                .then(setProfleResponse)
            }
        }
        setUdData(false)
    }, [isLoged, profileData, udData]);

    //- guarda los datos del usuario despues de la respuesta del servicio
    useEffect(() => {
        if (profileResponse) {
            if(profileResponse?.statusCode === 200) {
                if(profileResponse?.data) {
                    if(profileResponse?.data?.data) {
                        const {
                            name,
                            email,
                            id,
                            last_name,
                            second_last_name,
                            gender,
                            birthday
                        } = profileResponse?.data?.data
                        
                        let userDataProfile = {
                            idClaro: id,
                            nombre: name,
                            apellidoP: last_name,
                            apellidoM: second_last_name,
                            nombreCompleto: name+' '+last_name+' '+second_last_name,
                            genero: gender,
                            cumpleanios: birthday,
                            correo: email
                        }
                        dataLayerUserInfo({idUser: userDataProfile.idClaro, userInfo: JSON.stringify(userDataProfile) })
                        setProfleGet(userDataProfile);
                    }
                }
            }
            else{
                console.log('error: ', profileResponse.statusCode )
            }
        }
    }, [profileResponse]);

    const [tokenMiddel, setTokenMiddel] = useState(false);
    useEffect(()=>{
        if(profileGet){
            if(profileGet?.correo){
                setProfileData(profileGet);
            }
        }
    },[profileGet])

    
    useEffect(() => {
        if (profileData) {
            if(profileGet?.nombre?.indexOf('@') >= 1){
                removeStorage('profileData')
                setUdData(true)
            }
        }
    }, [profileData]);

    const udPersonalData = () => setUdData(true)

    return{
        profileGet,
        udPersonalData
    }
}