import React, { useEffect, Suspense } from 'react';
import loadable from '@loadable/component'

import { useClearCacheCtx } from 'react-clear-cache';
import { Router, Route, Redirect, Switch } from "react-router-dom";
import PrivateRoute from 'routers/privateRouter';
import BrowserHistory from 'routers/history';
import Provider from 'context/storeProvider';
import { BASE_FIRE_CONFIG } from 'constants/general';


import Afiliados from 'pages/Afiliados'
import Livestream from 'pages/Livestream'
import NecesitasAyuda from 'pages/Landings/AyudaDinamica/NecesitasAyuda';
import PoliticaDev from 'pages/Landings/AyudaDinamica/PoliticaDevolucion';
import PoliticaDev2 from 'pages/Landings/AyudaDinamica/PoliticaDevolucion2';
import PoliticaDev3 from 'pages/Landings/AyudaDinamica/PoliticaDevolucion3';
import PoliticaDev4 from 'pages/Landings/AyudaDinamica/PoliticaDevolucion4';

const Home = loadable( () => import (/* webpackChunkName: "Page_Home", webpackPrefetch: true */ 'pages/Home/indexSuspense') )
const Authenticated = loadable( () => import (/* webpackChunkName: "Page_Authenticated" */ 'pages/LoginRegistro/Authenticated') )
const Login = loadable( () => import (/* webpackChunkName: "Page_Login" */ 'pages/LoginRegistro/Login/indexSuspense') )
const Registro = loadable( () => import (/* webpackChunkName: "Page_Registro" */ 'pages/LoginRegistro/Registro/indexSuspense') )
const VerificacionOTP = loadable( () => import (/* webpackChunkName: "Page_VerificacionOTP" */ 'pages/LoginRegistro/VerificacionOTP') )
const RecuperarContrasenia = loadable( () => import (/* webpackChunkName: "Page_RecuperarContrasenia" */ 'pages/LoginRegistro/Password') )
const MenuCategoria = loadable( () => import (/* webpackChunkName: "Page_MenuCategoria" */ 'pages/Categorias/MenuCategoria/indexSuspense') )
const Buscador = loadable( () => import (/* webpackChunkName: "Page_Buscador" */ 'pages/Buscador/Search') )
const Resultados = loadable( () => import (/* webpackChunkName: "Page_Resultados", webpackPrefetch: true */ 'pages/Buscador/Results/indexSuspense') )
const DetalleProducto = loadable( () => import (/* webpackChunkName: "Page_DetalleProducto", webpackPrefetch: true */ 'pages/Producto/indexSuspense') )
const Landings = loadable( () => import (/* webpackChunkName: "Page_Landings" */ 'pages/Landings') )
const CategoriaPage = loadable( () => import (/* webpackChunkName: "Page_CategoriaPage" */ 'pages/Categorias/SeccionCategoria/indexSuspense') )
const Carrito = loadable( () => import (/* webpackChunkName: "Page_Carrito" */ 'pages/Carrito/indexSuspense') )
const TiendaPage = loadable( () => import (/* webpackChunkName: "Page_TiendaPage" */ 'pages/Tienda/ResultsTiendas/indexSuspense') )
const TiendasOficiales = loadable( () => import (/* webpackChunkName: "Page_TiendasOficiales" */ 'pages/Tienda/SeccionT/indexSuspense') )
const CornerPage = loadable( () => import (/* webpackChunkName: "Page_CornerPage" */ 'pages/Corner/indexSuspense') )
const Contacto = loadable( () => import (/* webpackChunkName: "Page_Contacto" */ 'pages/Contacto/indexSuspense') )
const ServicioCliente = loadable( () => import (/* webpackChunkName: "Page_ServicioCliente" */ 'pages/ServicioCliente/indexSuspense') )
const MailsCorreoCompleto = loadable( () => import (/* webpackChunkName: "Page_MailsCorreoCompleto" */ 'pages/Mails/VerCorreoCompleto/indexSuspense') )
const ReviewsWrite = loadable( () => import (/* webpackChunkName: "Page_ReviewsWrite" */ 'pages/Reviews/indexSuspense') )
const LoginMiTelmex = loadable( () => import (/* webpackChunkName: "Page_LoginMiTelmex" */ 'pages/MiCuenta/MiTelmex/loginTelmexPageSuspense') )
const MiTelmexComponent = loadable( () => import (/* webpackChunkName: "Page_MiTelmexComponent" */ 'components/MiTelmex/MiTelmex/MiTelmexComponent') )
const QuestionsMiTelmexPage = loadable( () => import (/* webpackChunkName: "Page_QuestionsMiTelmexPage" */ 'pages/MiCuenta/MiTelmex/questionsMiTelmex') )
const MiCuenta = loadable( () => import (/* webpackChunkName: "Page_MiCuenta" */ 'pages/MiCuenta/MiCuenta/indexSuspense') )
const DeleteAccount = loadable( () => import (/* webpackChunkName: "Page_DeleteAccount" */ 'pages/MiCuenta/DeleteAccount/indexSuspense') )

const MisDirecciones = loadable( () => import (/* webpackChunkName: "Page_MisDirecciones" */ 'pages/MiCuenta/MisDirecciones/SeleccionarDireccion/indexSuspense') )
const MisDireccionesEdit = loadable( () => import (/* webpackChunkName: "Page_MisDireccionesEdit" */ 'pages/MiCuenta/MisDirecciones/EditarDireccion/indexSuspense') )
const MisDireccionesAdd = loadable( () => import (/* webpackChunkName: "Page_MisDireccionesAdd" */ 'pages/MiCuenta/MisDirecciones/CrearDireccion/indexSuspense') )

const MisPedidos = loadable( () => import (/* webpackChunkName: "Page_MisPedidos" */ 'pages/MiCuenta/MisPedidos/indexSuspense') )
const DetallePedido = loadable( () => import (/* webpackChunkName: "Page_DetallePedido" */ 'pages/MiCuenta/MisPedidos/detallePedido/indexSuspense') )
const MiWishlist = loadable( () => import (/* webpackChunkName: "Page_MiWishlist" */ 'pages/MiCuenta/MiWishlist/indexSuspense') )
const Ajustes = loadable( () => import (/* webpackChunkName: "Page_Ajustes" */ 'pages/Ajustes/indexSuspense') )
const MenuPrincipal = loadable( () => import (/* webpackChunkName: "Page_MenuPrincipal" */ 'components/Menu/indexSuspense') )
const FormasPagoDesktop = loadable( () => import (/* webpackChunkName: "Page_FormasPagoDesktop" */ 'pages/ServicioCliente/FormasPago/indexSuspense') )
const ContactoClaro = loadable( () => import (/* webpackChunkName: "Page_ContactoClaro" */ 'pages/ServicioCliente/AvisoPrivacidad/contactoSuspense') )
const AvisoPrivacidad = loadable( () => import (/* webpackChunkName: "Page_AvisoPrivacidad" */ 'pages/ServicioCliente/AvisoPrivacidad/aviso-privacidadSuspense') )
const AvisoPrivacidadProspectos = loadable( () => import (/* webpackChunkName: "Page_AvisoPrivacidadProspectos" */ 'pages/ServicioCliente/AvisoPrivacidad/aviso-privacidad-prospectosSuspense') )
const PoliticaReembolso = loadable( () => import (/* webpackChunkName: "Page_PoliticaReembolso" */ 'pages/ServicioCliente/AvisoPrivacidad/politica-reembolsoSuspense') )
const PoliticaDevolucion = loadable( () => import (/* webpackChunkName: "Page_PoliticaDevolucion" */ 'pages/ServicioCliente/AvisoPrivacidad/politica-devolucionesSuspense') )
const GarantiaProductos = loadable( () => import (/* webpackChunkName: "Page_GarantiaProductos" */ 'pages/ServicioCliente/AvisoPrivacidad/garantia-productosSuspense') )
const TerminosCondiciones = loadable( () => import (/* webpackChunkName: "Page_TerminosCondiciones" */ 'pages/ServicioCliente/AvisoPrivacidad/terminos-condicionesSuspense') )
const PolticaReciboTelmex = loadable( () => import (/* webpackChunkName: "Page_PolticaReciboTelmex" */ 'pages/ServicioCliente/AvisoPrivacidad/politica-recibo-telmexSuspense') )
const PoliticaGarantia = loadable( () => import (/* webpackChunkName: "Page_PoliticaGarantia" */ 'pages/ServicioCliente/AvisoPrivacidad/politica-garantiaSuspense') )
const ContratoCredito = loadable( () => import (/* webpackChunkName: "Page_ContratoCredito" */ 'pages/ServicioCliente/AvisoPrivacidad/contrato-creditoSuspense') )
const ServicioConexion = loadable( () => import (/* webpackChunkName: "Page_ServicioConexion" */ 'pages/ServicioCliente/AvisoPrivacidad/servicio-conexionSuspense') )
const Pruebas = loadable( () => import (/* webpackChunkName: "Page_Pruebas" */ 'pages/PruebaModules') )
const Error404 = loadable( () => import (/* webpackChunkName: "Page_Error404" */ 'pages/Error/Error404') )
const SectionEnqueNecesitasAyuda = loadable( () => import (/* webpackChunkName: "Page_SectionEnqueNecesitasAyuda" */ 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista1-vista2_Suspense') )
const MotivoCancelacion = loadable( () => import (/* webpackChunkName: "Page_MotivoCancelacion" */ 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista3-motivo_Suspense') )
const CancelarPedido = loadable( () => import (/* webpackChunkName: "Page_CancelarPedido" */ 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista5-pedidoCancelado_Suspense') )
const FormularioDatosReembolso = loadable( () => import (/* webpackChunkName: "Page_FormularioDatosReembolso" */ 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista35-datoReembolso_Suspense') )
const SolicitarCambioDevolucion = loadable( () => import (/* webpackChunkName: "Page_SolicitarCambioDevolucion" */ 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista23-solicitarCambioDevolucion_Suspense') )
const AdjuntarFotografias = loadable( () => import (/* webpackChunkName: "Page_AdjuntarFotografias" */ 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista36-adjuntarFotografias_Suspense') )
const SectionProcesoRecoleccionEnCamino = loadable( () => import (/* webpackChunkName: "Page_SectionProcesoRecoleccionEnCamino" */ 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista6-nopuedeCancelar_Suspense') )
const CuentanosTuProblema = loadable( () => import (/* webpackChunkName: "Page_CuentanosTuProblema" */ 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista21-cuentanosTuProblema_Suspense') )
const NoFunciona = loadable( () => import (/* webpackChunkName: "Page_NoFunciona" */ 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista7-noFunciona_Suspense') )
const CambioDevolucion = loadable( () => import (/* webpackChunkName: "Page_CambioDevolucion" */ 'pages/MiCuenta/MisPedidos/AyudaDinamica/vista22-CambioDevolucion_Suspense') )


BrowserHistory.listen(location => {
  //console.log('Set page to', location.pathname);
  // eslint-disable-next-line no-undef
  //ineum('page', location.pathname);
  // Note that the above can result in many useless pages when you are making use of path parameters.
  // In these cases you will have to define the page via different means, e.g. by creating a custom
  // Route component which accepts a 'pageName' property.
});

const loadScript = () => {
  const script = document.createElement("script")
  script.type = "module"
  script.innerHTML = `
    import { initializeApp } from "https://www.gstatic.com/firebasejs/10.7.2/firebase-app.js";
    import { getAnalytics, logEvent, setUserId, setUserProperties } from "https://www.gstatic.com/firebasejs/10.7.2/firebase-analytics.js";

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    window.firebaseConfig = ${JSON.stringify(BASE_FIRE_CONFIG)};

    // Initialize Firebase
    window.appFirebase = initializeApp(firebaseConfig)
    window.analytics = getAnalytics(appFirebase)
    window.logEvent = logEvent
    window.setUserId = setUserId
    window.setUserProperties = setUserProperties
  `
  document.head.appendChild(script)
}

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

  

  return (
    <>
    <Provider>
      <Router history={BrowserHistory}>
        <Switch>
          <Route path="/" exact><Suspense fallback={<><header><div className="headerSup"></div><div className='headerInf'></div></header><main className="sectionHome"></main></>}><Home /></Suspense></Route>
          <Route path="/home" exact><Suspense fallback={<><header><div className="headerSup"></div><div className='headerInf'></div></header><main className="sectionHome"></main></>}><Home /></Suspense></Route>
          <Route path={[
            "/resultados/q=:busqueda?",
            "/preciomenor=:mp?",
            "/preciomayor=:pm?",
            "/marca=:idMarca?",
            "/categoria=:idCategoria?",
            "/estrellas=:estrella?",
            "/enviogratis=:ship?",
            "/fulfillment=:ful?",
            "/descuento=:dis?",
            "/orden=:ord?"
          ]} ><Suspense fallback={<><header><div className="headerSup"></div><div className='headerInf'></div></header><main className="templateSearchResult"></main></>}><Resultados /></Suspense></Route>
          
          <Route path="/buscador"  ><Suspense fallback={<div></div>}><Buscador /></Suspense></Route>
          
          <Route path="/categoria" exact ><Suspense fallback={<div></div>}><MenuCategoria /></Suspense></Route>
          <Route path="/categoria/:id_cat?/:seo?/:page" ><Suspense fallback={<div></div>}><CategoriaPage /></Suspense></Route>
          
          <Route path="/producto/:id?/:seo?" ><Suspense fallback={<><header><div className="headerSup"></div><div className='headerInf'></div></header><main className="viewDetailProduct"></main></>}><DetalleProducto /></Suspense></Route>
          
          <Route path="/tiendas" ><Suspense fallback={<div></div>}><TiendasOficiales /></Suspense></Route>
          <Route path="/tienda/:id_Tienda?/:seo?" ><Suspense fallback={<div></div>}><TiendaPage /></Suspense></Route>
          <Route path="/corner/:id_Corner?/:seo?" ><Suspense fallback={<div></div>}><CornerPage /></Suspense></Route>

          <Route path="/afiliados/:hash_cart" component={Afiliados} />
          <Route path="/livestream/:hash_cart" component={Livestream} />
          <Route path="/referidos/:hash_cart" component={Livestream} />
          <Route path="/external_cart/:hash_cart" component={Livestream} />
          
          <Route path="/landing/:landingName"><Suspense fallback={<div></div>}><Landings /></Suspense></Route>
          
          <Route path="/login"><Suspense fallback={<div></div>}><Login /></Suspense></Route>
          <Route path="/registro"><Suspense fallback={<div></div>}><Registro /></Suspense></Route>
          <Route path="/recuperar-contrasena/" ><Suspense fallback={<div></div>}><RecuperarContrasenia /></Suspense></Route>
          <Route exact path="/autorizar" ><Suspense fallback={<div></div>}><VerificacionOTP /></Suspense></Route>
          <Route path="/authenticated"><Suspense fallback={<div></div>}><Authenticated /></Suspense></Route>
          <Route path="/contacto" ><Suspense fallback={<div></div>}><Contacto /></Suspense></Route>
          <Route path="/servicio-cliente" ><Suspense fallback={<div></div>}><ServicioCliente /></Suspense></Route>
          

          <Route path="/verCorreoCompleto" ><Suspense fallback={<div></div>}><MailsCorreoCompleto /></Suspense></Route>
          <Route path="/escribir-opinion" ><Suspense fallback={<div></div>}><ReviewsWrite /></Suspense></Route>
          <Route path="/write-a-review" ><Suspense fallback={<div></div>}><ReviewsWrite /></Suspense></Route>
          
          {/* <Route path="/loginMiTelmex/" ><Suspense fallback={<div></div>}><LoginMiTelmex /></Suspense></Route>
          <Route path="/mi-cuenta/mitelmex/" ><Suspense fallback={<div></div>}><MiTelmexComponent /></Suspense></Route> */}

          {/* <PrivateRoute path="/mi-cuenta/questions/" ><Suspense fallback={<div></div>}><QuestionsMiTelmexPage /></Suspense></PrivateRoute> */}
          <PrivateRoute exact path="/mi-cuenta/direcciones-envio/" ><Suspense fallback={<div></div>}><MisDirecciones /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/direcciones-envio/agregar"  ><Suspense fallback={<div></div>}><MisDireccionesAdd /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/direcciones-envio/editar/:id"  ><Suspense fallback={<div></div>}><MisDireccionesEdit /></Suspense></PrivateRoute>
          
          <PrivateRoute exact path="/pedido/:idPedido" ><Suspense fallback={<div></div>}><DetallePedido /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/pedido/:idPedido/product=:idProducto?" ><Suspense fallback={<div></div>}><DetallePedido /></Suspense></PrivateRoute>
          
          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/0/formaCompra/:idPedido" ><Suspense fallback={<div></div>}><DetallePedido /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/0/formaCompra/:idPedido/product=:idProducto?" ><Suspense fallback={<div></div>}><DetallePedido /></Suspense></PrivateRoute>
          
          <PrivateRoute path="/mi-cuenta/mis-pedidos/dias=:days/pagina=:pages" ><Suspense fallback={<div></div>}><MisPedidos /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido" ><Suspense fallback={<div></div>}><DetallePedido /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto?" ><Suspense fallback={<div></div>}><DetallePedido /></Suspense></PrivateRoute>

          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/necesitas-ayuda" ><Suspense fallback={<div></div>}><SectionEnqueNecesitasAyuda /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/necesitas-ayuda" ><Suspense fallback={<div></div>}><SectionEnqueNecesitasAyuda /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/motivo-cancelacion"  ><Suspense fallback={<div></div>}><MotivoCancelacion /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/motivo-cancelacion"  ><Suspense fallback={<div></div>}><MotivoCancelacion /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/cancelacion-exitosa"  ><Suspense fallback={<div></div>}><CancelarPedido /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/cancelacion-exitosa"  ><Suspense fallback={<div></div>}><CancelarPedido /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/datos-bancarios" ><Suspense fallback={<div></div>}><FormularioDatosReembolso /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/datos-bancarios" ><Suspense fallback={<div></div>}><FormularioDatosReembolso /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/no-puede-cancelar" ><Suspense fallback={<div></div>}><SectionProcesoRecoleccionEnCamino /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/que-quieres-hacer" ><Suspense fallback={<div></div>}><CambioDevolucion /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/solicitar-cambio-devolucion" ><Suspense fallback={<div></div>}><SolicitarCambioDevolucion /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/cuentanos-tu-problema" ><Suspense fallback={<div></div>}><CuentanosTuProblema /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/no-funciona" ><Suspense fallback={<div></div>}><NoFunciona /></Suspense></PrivateRoute>
          <PrivateRoute exact path="/mi-cuenta/mis-pedidos/:idPedido/product=:idProducto/adjuntar-fotografias" ><Suspense fallback={<div></div>}><AdjuntarFotografias /></Suspense></PrivateRoute>

          <Route path="/necesitas-ayuda/FAQDevolucion"><Suspense fallback={<div></div>}><NecesitasAyuda /></Suspense></Route>
          <Route path="/necesitas-ayuda/FAQCancelaciones1"><Suspense fallback={<div></div>}><PoliticaDev /></Suspense></Route>
          <Route path="/necesitas-ayuda/FAQCancelaciones2"><Suspense fallback={<div></div>}><PoliticaDev2 /></Suspense></Route>
          <Route path="/necesitas-ayuda/FAQCancelaciones3"><Suspense fallback={<div></div>}><PoliticaDev3 /></Suspense></Route>
          <Route path="/necesitas-ayuda/PoliticasDevolucion"><Suspense fallback={<div></div>}><PoliticaDev4 /></Suspense></Route>

          <PrivateRoute path="/mi-cuenta/listadeseos/" ><Suspense fallback={<div></div>}><MiWishlist /></Suspense></PrivateRoute>
          <PrivateRoute path="/mi-cuenta/borrar-cuenta" ><Suspense fallback={<div></div>}><DeleteAccount /></Suspense></PrivateRoute>
          <PrivateRoute path="/mi-cuenta/" ><Suspense fallback={<div></div>}><MiCuenta /></Suspense></PrivateRoute>
          <PrivateRoute path="/carrito/" exact ><Redirect to="/carrito/detalle" /></PrivateRoute>
          <PrivateRoute path="/carrito/detalle" ><Suspense fallback={<div></div>}><Carrito /></Suspense></PrivateRoute>
          <Route path="/ajustes"><Suspense fallback={<div></div>}><Ajustes /></Suspense></Route>
          <Route path="/menu-mas"><Suspense fallback={<div></div>}><MenuPrincipal /></Suspense></Route>
          <Route path="/formas-pago"><Suspense fallback={<div></div>}><FormasPagoDesktop /></Suspense></Route>
          <Route path="/aviso-privacidad"><Suspense fallback={<div></div>}><AvisoPrivacidad /></Suspense></Route>
          <Route path="/aviso-privacidad-prospectos"><Suspense fallback={<div></div>}><AvisoPrivacidadProspectos /></Suspense></Route>
          <Route path="/politica-reembolso"><Suspense fallback={<div></div>}><PoliticaReembolso /></Suspense></Route>
          <Route path="/politica-devoluciones"><Suspense fallback={<div></div>}><PoliticaDevolucion /></Suspense></Route>
          <Route path="/garantia-productos"><Suspense fallback={<div></div>}><GarantiaProductos /></Suspense></Route>
          <Route path="/terminos-y-condiciones"><Suspense fallback={<div></div>}><TerminosCondiciones /></Suspense></Route>
          {/*<Route path="/politica-recibo-telmex"><Suspense fallback={<div></div>}><PolticaReciboTelmex /></Suspense></Route>*/}
          <Route path="/politica-garantia"><Suspense fallback={<div></div>}><PoliticaGarantia /></Suspense></Route>
          <Route path="/contacto-claroshop"><Suspense fallback={<div></div>}><ContactoClaro /></Suspense></Route>
          <Route path="/contrato-credito"><Suspense fallback={<div></div>}><ContratoCredito /></Suspense></Route>
          <Route path="/servicio-de-conexion"><Suspense fallback={<div></div>}><ServicioConexion /></Suspense></Route>
          <Route path="/testModules"><Suspense fallback={<div></div>}><Pruebas /></Suspense></Route>
          <Route path="/:anything*"><Suspense fallback={<div></div>}><Error404 /></Suspense></Route>

        </Switch>
      </Router>
    </Provider>
    </>
  );
}

export default App;