import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import BarraCP from 'components/BarCP/BarHeaderCP';
import MenuPrincipal from 'components/Menu';
import CartHeaderCount from 'components/Cart/CartHeaderCount';
import SearchModule from 'components/SearchHeader';
import { HeaderWishlist } from 'components/Header/HeaderWishlist';
import { HeaderMisPedidos } from 'components/Header/HeaderMisPedidos';
import { HeaderMiCuenta } from 'components/Header/HeaderMiCuenta';
import { CategoryMenuHeader } from 'components/Header/CategoryMenuHeader';
import { HeaderTiendasMenu } from 'components/Header/MenuTiendas';
import { HeaderPromo } from 'components/Header/PromoHeader';
import {ErrorBoundary} from 'components/Error/ErrorBoundary';

import { HeaderBarMovile } from 'pages/Header/HeaderNavegacion/HeaderBarMovile'

export function HeaderDesktop(props){
    const {
        isLoged,
        totalItems,
        profile,
        changeDirectionBar,
        searchWork
    } = props; 

    const [showModal, SetShowModal] = useState(false);
    const hostName = window.location.origin; 

    //- Funciones para activar o desactivar el modal del menu lateral
    const showMenu = () =>{  SetShowModal(true); }
    const handleClose = () =>{ SetShowModal(false);}

    const desktopView = () => document.querySelector('#root').classList.add('desktopView');

    useEffect(() =>{
        desktopView();
    },[])

    return(
        <>
            <header>
                <div className="headerSup">
                    <div className="container">
                        <Link to="/" className="logoClaroshop" aria-label="logoClaroshop"></Link>

                        <ErrorBoundary>
                            <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
                        </ErrorBoundary>

                        <ErrorBoundary>
                            <SearchModule {...props} searchWork={searchWork}/>
                        </ErrorBoundary>

                        <div className="menuPrincipal">
                            <div className="menuDesk">
                                <ErrorBoundary>
                                    <HeaderWishlist ruta={isLoged ? '/mi-cuenta/listadeseos/' : `/login?redirect=${hostName}/mi-cuenta/listadeseos/`} />
                                </ErrorBoundary>

                                <ErrorBoundary>
                                    <HeaderMisPedidos ruta={isLoged ? '/mi-cuenta/mis-pedidos/dias=30/pagina=1' : `/login?redirect=${hostName}/mi-cuenta/mis-pedidos/dias=30/pagina=1`} />
                                    {/* <HeaderMisPedidos ruta={isLoged ? `${BASE_PATH_PORTAL}/mi-cuenta/mis-pedidos/1/estatus/2/` : `/login?redirect=/mi-cuenta/mis-pedidos/1/estatus/2/`}  type="anchor"/> */}
                                </ErrorBoundary>

                                <ErrorBoundary>
                                    <HeaderMiCuenta ruta={isLoged ? '/mi-cuenta' : `/login?redirect=${hostName}/mi-cuenta`} profile={profile}/>
                                </ErrorBoundary>
                                
                                <ErrorBoundary>
                                    <CartHeaderCount ruta={isLoged ? '/carrito/detalle' : `/login?redirect=${hostName}/carrito/detalle`} cantidad={isLoged ? totalItems : 0} /> 
                                </ErrorBoundary>
                            </div>
                            <HeaderBarMovile isLoged={isLoged} profile={profile} />
                        </div>

                    </div>
                </div>
                <div className="headerInf">
                    <div className="container">
                        <ErrorBoundary>
                            <CategoryMenuHeader />
                        </ErrorBoundary>
                        
                        {/* <ErrorBoundary>
                            <BarraCP changeCP={changeDirectionBar} clase="barHeaderCP" />
                        </ErrorBoundary> */}

                        <HeaderPromo />
                        <HeaderTiendasMenu />
                    </div>
                </div>
            </header>
            {showModal && <MenuPrincipal onClose={handleClose} />}
        </>
    )
}