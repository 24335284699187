import React from 'react';
import { decodeStorage } from 'functions/storageBase64';
import { Link } from "react-router-dom";
import { BASE_PATH_PORTAL, VARIANTS_PER_PORTAL } from 'constants/general';


export function HeaderPromo() {

    const hostName = window.location.origin;
    const profileData = decodeStorage('profileData');
    //const {isLoged, card_ending, no_internet, montoCredito, creditoDisponible, flagAceptoCredito} = useConsultarCredito()
    const creditoCS = decodeStorage("cardending");
    // console.log("HeaderPromo creditoCS", creditoCS);
    return (
        <nav className="promoHeader">
            <ul>
                {VARIANTS_PER_PORTAL.moduleCreditoActivo === true
                    ?
                        creditoCS ? <li><a href={`${BASE_PATH_PORTAL}/credito-claroshop/home`} className="bgRed">Mi Crédito Claroshop</a></li>
                        : <li><a href={`${BASE_PATH_PORTAL}/credito-claroshop/beneficios`} className="bgRed">Mi Crédito Claroshop</a></li>
                    : null
                }
                <li><a href="/c/ofertas/" className="fontYellow" >Ofertas</a></li>
                <li><Link to="/categoria/21827/videojuegos/">Gamers</Link></li>
                <li><Link to="/categoria/21988/hasta-20--supermercado/pagina=1" >Supermercado</Link></li>
                {VARIANTS_PER_PORTAL.moduleCreditoActivo !== true
                    ? <li><Link to="/loginMiTelmex/" className="bgBlue">Mi crédito Claroshop</Link></li>
                    : null
                }
                <li><a href="https://blog.claroshop.com/" >Blog Claroshop</a></li>
            </ul>
        </nav>
    )
}