import React, { useState, useEffect } from 'react'
import './style.sass'
import '../../pages/Home/style.sass'
import { NavLink, useLocation, Link } from 'react-router-dom'
import { useStorage64 } from 'hooks/useStorageBase64'
import { decodeStorage, encodeStorage } from 'functions/storageBase64'
import { BASE_PATH_PORTAL, PORTAL_URL, VARIANTS_PER_PORTAL } from 'constants/general'

import Switch from 'components/Forms/Switch'
import Header from 'pages/Header/HeaderNavegacion'

function ContentMenuPrincipal({ onClose }) {
    const { pathname } = useLocation()
    const [isLoged] = useStorage64('session_token', '')
    const [profileData] = useStorage64('profileData', '')
    const [telefono, setTelefono] = useStorage64('telefono_usuario', '')
    const [successVerificacion, setSuccessVerificacion] = useState(false)
    const [siredireccionar, setSiRedireccionar] = useState(false)
    const [swipe, setSwipe] = useState({ x: null, y: null })
    const hostName = window.location.origin
    //credito CS
    const creditoCS = decodeStorage("cardending");

    const touchStart = (evt) => {
        const firstTouchEvent = evt.changedTouches[0]
        const location = {
            x: firstTouchEvent.clientX,
            y: firstTouchEvent.clientY
        }
        setSwipe(location)
    }

    const touchEnd = (evt) => {
        const firstTouchEvent = evt.changedTouches[0]
        const location = {
            x: firstTouchEvent.clientX,
            y: firstTouchEvent.clientY
        }
        const final = {
            x: swipe.x - location.x,
            y: swipe.y - location.y
        }

        if (final.x >= 50) {
            onClose()
        }
    }

    // envia la peticion al servicio verificar si usuario ya ha comprado en claroshop
    // useEffect(() => {
    //     if (isLoged) {
    //     getVerificacionUsuarioTelmex()
    //         .then(setSuccessVerificacion)
    //     }
    //     console.log(" sucess verificacion en is loged: ", successVerificacion)
    //     return
    // }, [isLoged])

    // recibe la respuesta del servicio de verificacion de usuario ( ha comprado antes o no)
    // useEffect(() => {
    //     const resultado = []
    //     const tele = []

    //     console.log(" sucess verificacion: ", successVerificacion)
    //     if (successVerificacion) {
    //         // guarda el valor para verificar si el usuario ya ha comprado con telmex
    //         // for (let [key, value] of Object.entries(successVerificacion)) {
    //         //     if (key == "data") {
    //         //         for (let [key1, value1] of Object.entries(value)) {
    //         //             if (key1 === "data") {
    //         //                 for (let [key2, value2] of Object.entries(value1)) {
    //         //                     if (key2 === "registered_with_tmx") {
    //         //                         resultado.push(value2)
    //         //                     }
    //         //                     if (key2 === 'phone') {
    //         //                         tele.push(value2)
    //         //                     }
    //         //                 }
    //         //             }
    //         //         }
    //         //     }
    //         // }
    //         // if (resultado[0] === true) { //esta registrado true
    //         //     setTelefono(tele[0])
    //         //     setSiRedireccionar(true)
    //         //     // history.push("/mi-cuenta/mitelmex/")
    //         // }
    //         // if (resultado[0] === false) { // no ha comprado antes false
    //             setSiRedireccionar(false)
    //             // history.push("/loginMiTelmex/")
    //         // }
    //     }
    // }, [successVerificacion])

    //- Evalua si es dark mode dependiendo de la seleccion del usuario
    useEffect(() => {
        if (document.body.className === "is-dark-mode") {
            document.querySelector('#darkMode').checked = true
        } else {
            document.querySelector('#darkMode').checked = false
        }
    }, [])

    const valChange = () => {
        let darkModeEval = document.querySelector('#darkMode').checked
        encodeStorage('darkMode', darkModeEval)
        if (decodeStorage('darkMode')) { document.body.classList.add('is-dark-mode') }
        else { document.body.classList.remove('is-dark-mode') }
        sessionStorage.setItem('SelectUser', true)
    }

    return (
        <div className="moduleMenu">
            <div className="contentMenu" onTouchStart={touchStart} onTouchEnd={touchEnd}>
                <div className="headerModule">
                    {isLoged && profileData
                        ? <div>
                            <p>Hola {profileData.nombre}</p>
                            <span>{profileData.correo}</span>
                        </div>
                        : <div>
                            <NavLink
                                to="/login"
                                className="goToLogin"
                                title="Iniciar Sesión"
                            >
                                Iniciar Sesión
                            </NavLink>
                        </div>
                    }
                </div>

                <div className="contentSubSeccionMenu">
                    <div className="contSectionMenu">
                        <ul>
                            {pathname !== '/'
                                ? <li><NavLink to="/" className="icoInicio" title="Inicio">Inicio</NavLink></li>
                                : <li><NavLink to="/" className="icoInicio" onClick={onClose} title="Inicio">Inicio</NavLink></li>
                            }
                            {isLoged
                                ?
                                pathname !== '/micuenta'
                                    ? <li><NavLink to="/mi-cuenta" className="icoMiCuenta" title="Mi Cuenta">Mi Cuenta</NavLink></li>
                                    : <li><NavLink to="/mi-cuenta" className="icoMiCuenta" onClick={onClose} title="Mi Cuenta">Mi Cuenta</NavLink></li>

                                : null
                            }
                            {pathname !== '/c/ofertas/'
                                ? <li><a href="/c/ofertas/" className="icoOfertas" title="Ofertas">Ofertas</a></li>
                                : <li><a href="/c/ofertas/" className="icoOfertas" onClick={onClose} title="Ofertas">Ofertas</a></li>
                            }

                            {isLoged
                                ?
                                pathname !== '/mi-cuenta/mis-pedidos/'
                                    // ? <li><a href={`${BASE_PATH_PORTAL}/mi-cuenta/mis-pedidos/1/estatus/2/`} className="icoPedidos">Mis pedidos</a></li>
                                    // : <li><a href={`${BASE_PATH_PORTAL}/mi-cuenta/mis-pedidos/1/estatus/2/`} className="icoPedidos">Mis pedidos</a></li>
                                    ? <li><NavLink to="/mi-cuenta/mis-pedidos/dias=30/pagina=1" className="icoPedidos" title="Mis pedidos">Mis pedidos</NavLink></li>
                                    : <li><NavLink to="/mi-cuenta/mis-pedidos/dias=30/pagina=1" className="icoPedidos" onClick={onClose} title="Mis pedidos">Mis pedidos</NavLink></li>
                                :
                                null
                            }
                            {isLoged
                                ?
                                pathname !== '/mi-cuenta/listadeseos/'
                                    ? <li><NavLink to="/mi-cuenta/listadeseos/" className="icoWishlist" title="Lista de deseos">Lista de deseos</NavLink></li>
                                    : <li><NavLink to="/mi-cuenta/listadeseos/" className="icoWishlist" onClick={onClose} title="Lista de deseos">Lista de deseos</NavLink></li>
                                : <li><NavLink to={`/login?redirect=${hostName}/mi-cuenta/listadeseos/`} className="icoWishlist" title="Lista de deseos">Lista de deseos</NavLink></li>
                            }

                            {VARIANTS_PER_PORTAL.moduleCreditoActivo === true
                                ? isLoged
                                    ? pathname !== '/credito-claroshop'
                                        ? <li><a href={PORTAL_URL + creditoCS ? "/credito-claroshop/home" : "/credito-claroshop/beneficios"} className="icoCredito" title="Mi crédito ClaroShop">Mi crédito ClaroShop</a></li>
                                        : <li><a href={PORTAL_URL + creditoCS ? "/credito-claroshop/home" : "/credito-claroshop/beneficios"} className="icoCredito" onClick={onClose} title="Mi crédito ClaroShop">Mi crédito ClaroShop</a></li>
                                    : <li><a href={`${PORTAL_URL}/credito-claroshop/beneficios`} className="icoCredito" title="Mi crédito ClaroShop">Mi crédito ClaroShop</a></li>
                                : null
                            }


                            {isLoged
                                ?
                                pathname !== '/mi-cuenta/direcciones-envio/'
                                    ? <li><NavLink to="/mi-cuenta/direcciones-envio/" className="icoDireccion" title="Mis direcciones">Mis direcciones</NavLink></li>
                                    : <li><NavLink to="/mi-cuenta/direcciones-envio/" className="icoDireccion" onClick={onClose} title="Mis direcciones">Mis direcciones</NavLink></li>
                                :
                                null
                            }

                        </ul>
                    </div>

                    <div className="contSectionMenu">
                        <ul>
                            {pathname !== '/categoria'
                                ? <li><NavLink to="/categoria" className="icoCategoria" title="Categorías">Categorías</NavLink></li>
                                : <li><NavLink to="/categoria" className="icoCategoria" onClick={onClose} title="Categorías">Categorías</NavLink></li>
                            }
                            <li><a href="https://blog.claroshop.com/" className="icoBlog" >Blog Claroshop</a></li>
                            {pathname !== '/tiendas'
                                ? <li><NavLink to="/tiendas" className="icoTienda" title="Tiendas oficiales">Tiendas oficiales</NavLink></li>
                                : <li><NavLink to="/tiendas" className="icoTienda" onClick={onClose} title="Tiendas oficiales">Tiendas oficiales</NavLink></li>
                            }
                            {pathname !== '/tiendas'
                                ? <li><a href={PORTAL_URL + "/l/vender-en-internet"} rel="noopener noreferrer" className="icoVendeEn" title="Vende en Claroshop.com">Vende en Claroshop.com</a></li>
                                : <li><a href={PORTAL_URL + "/l/vender-en-internet"} rel="noopener noreferrer" className="icoVendeEn" title="Vende en Claroshop.com">Vende en Claroshop.com</a></li>
                                // ? <li><NavLink to="/tiendas" className="icoVendeEn" title="Vende en Claroshop.com">Vende en Claroshop.com</NavLink></li>
                                // : <li><NavLink to="/tiendas" className="icoVendeEn" onClick={onClose} title="Vende en Claroshop.com">Vende en Claroshop.com</NavLink></li>
                            }
                        </ul>
                    </div>

                    <div className="contSectionMenu">
                        <ul>
                            {/* /testModules */}
                            <li><NavLink to="/contacto" className="icoWhatshapp" title="¿Necesitas ayuda?">¿Necesitas ayuda?</NavLink></li>
                            <li><NavLink to="/servicio-cliente" className="icoServicio" title="Servicio al cliente">Servicio al cliente</NavLink></li>
                            <li>
                                <Switch title="Modo oscuro" name="darkMode" position="right" type="darkMode" change={valChange} />
                            </li>
                            {isLoged
                                ?
                                pathname !== '/ajustes'
                                    ? <li><NavLink to="/ajustes" className="icoAjustes" title="Ajustes">Ajustes</NavLink></li>
                                    : <li><NavLink to="/ajustes" className="icoAjustes" onClick={onClose} title="Ajustes">Ajustes</NavLink></li>

                                : null
                            }
                        </ul>
                    </div>
                </div>

            </div>
            <div className="backgroundMenu" onClick={onClose}></div>
        </div>
    )
}

function MenuPrincipal({ onClose }) {

    //recibe la direccion del CP
	const [updateCPBar, setUpdateCPBar] = useState()
	const reciveDirection = (val) => {
		setUpdateCPBar(val)
	}
	const [upCP, setUpCP] = useState()
	useEffect(() => {
		if (upCP) {
			//console.log('')
		}
	}, [upCP, updateCPBar])

    return(
        <>
            <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection} />
            <Link className="fakeSearch" to="/buscador">
                <span>¿Qué estás buscando?</span>
            </Link>
            <ContentMenuPrincipal onClose={onClose} />
        </>
    )
}

export default React.memo(MenuPrincipal)