import React from 'react'
import './style.sass'

export default function InputType({ ...params }) {

    const {
        type,
        name,
        id,
        placeholder,
        value,
        change,
        focus,
        errorMessage,
        max,
        min,
        refs,
        readonly,
        disabled,
        autocomplete,
        children,
        clase,
        maxLength,
        onFocus,
        onBlur,
        flag,
        texto,
        onKeyDown,
    } = params

    return (<>
        {clase == 'AddressPage'
            ? < div className="fileset1">
                <input
                    type={type}
                    name={name}
                    id={id}
                    // placeholder={placeholder}
                    onChange={change}
                    onFocus={focus}
                    value={value}
                    max={max}
                    min={min}
                    readOnly={readonly}
                    disabled={disabled}
                    autoComplete={autocomplete}
                    data-input={autocomplete}
                    ref={refs}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    flag={flag}
                    texto={texto}
                    onKeyDown={onKeyDown}
                />
                <label htmlFor={id} for={id}>{placeholder}</label>
                {/* {children} */}

                {errorMessage
                    ? <div className="error">{errorMessage}</div>
                    : null
                }

                {flag && !errorMessage && <p className="copyHelp">{texto}</p>}
            </div>
            : <label className="fileset">
                <input
                    type={type}
                    name={name}
                    id={id}
                    placeholder={placeholder}
                    onChange={change}
                    onFocus={focus}
                    value={value}
                    max={max}
                    min={min}
                    readOnly={readonly}
                    disabled={disabled}
                    autoComplete={autocomplete}
                    data-input={autocomplete}
                    ref={refs}
                    maxLength={maxLength}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    flag={flag}
                    texto={texto}
                    onKeyDown={onKeyDown}
                    maxLength={maxLength}
                />
                {children}

                {errorMessage
                    ? <div className="error">{errorMessage}</div>
                    : null
                }
                {flag && !errorMessage && <p className="copyHelp">{texto}</p>}
            </label>
        }
    </>
    )
}

// Input tipo android
export function InputTypeAndroid({ ...params }) {

    const {
        type,
        name,
        id,
        placeholder,
        value,
        change,
        focus,
        errorMessage,
        max,
        min,
        refs,
        readonly,
        disabled,
        autocomplete,
        children,
        maxLength
    } = params

    return (
        < div className="fieldset2">
            <input
                type={type}
                name={name}
                id={id}
                placeholder=" "
                onChange={change}
                onFocus={focus}
                value={value}
                max={max}
                min={min}
                readOnly={readonly}
                disabled={disabled}
                autoComplete={autocomplete}
                data-input={autocomplete}
                ref={refs}
                maxLength={maxLength}
            />
            <label htmlFor={id} for={id}>{placeholder}</label>
            {/* {children} */}

            {errorMessage
                ? <div className="error">{errorMessage}</div>
                : null
            }
        </div>
    )
}

// Input tipo android
export function InputTypeAndroidCredit({ ...params }) {

    const {
        type,
        name,
        id,
        placeholder,
        value,
        change,
        focus,
        click,
        errorMessage,
        max,
        min,
        refs,
        readonly,
        disabled,
        autocomplete,
        children,
        maxLength,
        setNumero,
        className = "",
        onKeyDown,
        onKeyUp,
        minLength,
        pattern,
        numero
    } = params


    const handleNumero = (e) => {
        e.preventDefault();

        let numero = e.target.value;

        if (pattern === "number") {
            if (numero.length != 0) {
                setNumero(numero.replace(/[^\d]+/g, ''));
            } else {
                setNumero('');
            }
        } else {
            setNumero(numero);
        }
    }

    const onKeyDown1 = (e) => {
    // console.log("key: ", e.keyCode)
        if (String(pattern) === "number") {
            if (e.keyCode != 8 && 
                e.keyCode != 186 && 
                (e.keyCode < 48 || e.keyCode > 57) &&
                (e.keyCode < 96 || e.keyCode > 105 )
            ) {
                e.preventDefault();
            }
        }
    }


    return (
        <div className={`fieldset2 ${className}`}>
            <input
                type={type}
                name={name}
                id={id}
                placeholder=" "
                // onChange={change}
                onFocus={focus}
                onClick={click}
                value={value}
                max={max}
                min={min}
                readOnly={readonly}
                disabled={disabled}
                autoComplete={autocomplete}
                data-input={autocomplete}
                ref={refs}
                maxLength={maxLength}
                minLength={minLength}
                onChange={handleNumero}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown1}
            />
            <label htmlFor={id} for={id}>{placeholder}</label>
            {/* {children} */}

            {errorMessage
                ? <div className="error">{errorMessage}</div>
                : null
            }
        </div>
    )
}


export function InputTypeDesktop({ ...params }) {

    const {
        type,
        name,
        id,
        placeholder,
        value,
        change,
        focus,
        errorMessage,
        max,
        min,
        refs,
        readonly,
        disabled,
        autocomplete,
        children,
        clase,
        maxLength,
        onFocus,
        onBlur,
        flag,
        texto,
        onKeyDown,
    } = params

    return (<>
        < div className="fileset desktInput">
            <label htmlFor={id} for={id}>{placeholder}</label>

            <input
                type={type}
                name={name}
                id={id}
                // placeholder={placeholder}
                onChange={change}
                onFocus={focus}
                value={value}
                max={max}
                min={min}
                readOnly={readonly}
                disabled={disabled}
                autoComplete={autocomplete}
                data-input={autocomplete}
                ref={refs}
                onFocus={onFocus}
                onBlur={onBlur}
                flag={flag}
                texto={texto}
                onKeyDown={onKeyDown}
                maxLength={maxLength}
            />

            {errorMessage ? <div className="error">{errorMessage}</div> : null}

            {flag && !errorMessage && <p className="copyHelp">{texto}</p>}
        </div>
    </>
    )
}