import { BASE_PATH_API, BASE_PATH_PORTAL, TIMEOUT_TIME } from 'constants/general'
import axios from 'axios'
import {SHA1} from 'functions/generateSHA1'

export async function postLoginFacebook({ raw } = {}) {
    const apiURL = BASE_PATH_API + '/app/v1/login-facebook'

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        mode: 'no-cors',
        crossdomain: true,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8'
        },
        data: raw
    };

    return await axios(config)
        .then(res => {
            const {
                data
            } = res
            return data
        })
        .catch(error => {
            console.log(error.request.status)
            if (error.message === 'Network Error') {
                console.log('Error de conexión')
            }
            else if (error.request) {
                console.log(error.request.responseText)
            }
            if (error.request.status === 0) {
                console.log('request 0')
            } else {
                if (error.response.status === 401) {
                    console.log(error.message)
                    return { error: error.response.status, message: error.message }
                }
                else if (error.response.status === 404) {
                    console.error(error.response);
                    console.log('request 404')
                }
                else if (error.response.status === 440) {
                    console.log('request 440')
                }
                else if (error.response.status === 500) {
                    console.error(error.response);
                    console.log('request 500')
                }
                else {
                    console.error(error.response.status);
                    console.error(error.response);
                }
            }
        })
}


export async function getLoginFacebook(props) {
    const {
        email
    } = props
    
    const dateActual = new Date()
    const apiURL = `${BASE_PATH_PORTAL}/login/login/checkLogin?tklgn=${SHA1(email+'|'+dateActual)}`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        mode: 'no-cors',
        crossdomain: true,
    };

    return await axios(config)
    .then(res => {
        const {
            data
        } = res
        return data
    })
    .catch(error => {
        console.log(error.request.status)
        if (error.message === 'Network Error') {
            console.log('Error de conexión')
        }
        else if (error.request) {
            console.log(error.request.responseText)
        }
        if (error.request.status === 0) {
            console.log('request 0')
        } else {
            if (error.response.status === 401) {
                console.log(error.message)
                return { error: error.response.status, message: error.message }
            }
            else if (error.response.status === 404) {
                console.error(error.response);
                console.log('request 404')
            }
            else if (error.response.status === 440) {
                console.log('request 440')
            }
            else if (error.response.status === 500) {
                console.error(error.response);
                console.log('request 500')
            }
            else {
                console.error(error.response.status);
                console.error(error.response);
            }
        }
    })
}