import { useEffect, useState } from 'react'

export function useRepairResponse(stringJson){
    const [data, setData] = useState(stringJson)
    const [removeError, setRemoveError] = useState()
    const [responseOk, setResponseOk] = useState()
    const [responseData, setResponseData] = useState()

    useEffect(() => {
        if(data){
            setRemoveError( data.indexOf('{') )
        }
    },[data])

    useEffect(() => {
        if(removeError){
            setResponseOk( data.substring(removeError) )
        }
    },[removeError])

    useEffect(() => {
        if(responseOk){
            setResponseData( JSON.parse(responseOk) )
        }
    },[responseOk])

    return {
        responseData,
        setData
    }
}