import React from 'react'
import './style.sass'

export default function Switch({...props}){
    const {
        title,
        type,
        position = 'left',
        viewForm ='round',
        name,
        refs,
        change
    } = props
    return(
        <label className="swicthComponent" aria-label={title}>
            {position === "left" 
                ? <p className="titleLeft">{title}</p> 
                : ''
            }
            <div className={type ? `${type} switch` : 'switch'}>
                <input type="checkbox" name={name} id={name} onChange={change} ref={refs}/>
                <span className={`slider ${viewForm}`}></span>
            </div>
            {position === "right" 
                ? <p className="titleRight">{title}</p>  
                : ''
            }
        </label>
    )
}