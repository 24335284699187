import React from 'react';
import './style.sass';
import LazyLoad from 'react-lazy-load'
import { BASE_PATH_RESOURCES, LAZY_L } from 'constants/general'

export default function BoxContacto() {
    return (
        <div className="boxRedes">
            <div className="redesLogos">
                <span className="sigTitle">Síguenos:</span>
                <ul>
                    <li>
                        <a href="https://www.facebook.com/claroshopmx/?ref=hl" target="_blank" rel="noopener noreferrer" aria-label="icon-face">
                            {LAZY_L
                                ?
                                    <LazyLoad height={15} width={15} offset={100} threshold={0.95} >
                                        <img src={`${BASE_PATH_RESOURCES}/img/newHome/icon-face.svg`} loading="lazy" alt="icon-face" width="15" height="15" fetchpriority="low" />
                                    </LazyLoad>
                                :    
                                    <img src={`${BASE_PATH_RESOURCES}/img/newHome/icon-face.svg`} loading="lazy" alt="icon-face" width="15" height="15" fetchpriority="low" />
                            }
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/claroshop_com/" target="_blank" rel="noopener noreferrer" aria-label="icon-twitter">
                            {LAZY_L
                                ?
                                    <LazyLoad height={15} width={15} offset={100} threshold={0.95} >
                                        <img src={`${BASE_PATH_RESOURCES}/img/newHome/icon-twitter.svg`} loading="lazy" alt="icon-twitter" width="15" height="15" fetchpriority="low" />
                                    </LazyLoad>
                                :<img src={`${BASE_PATH_RESOURCES}/img/newHome/icon-twitter.svg`} loading="lazy" alt="icon-twitter" width="15" height="15" fetchpriority="low" />
                            }
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCNEr65Oyg6W6fn9Dm_4pcLQ" target="_blank" rel="noopener noreferrer">
                            {LAZY_L
                                ?
                                    <LazyLoad height={15} width={15} offset={100} threshold={0.95} >
                                        <img src={`${BASE_PATH_RESOURCES}/img/newHome/icon-youtube.svg`} loading="lazy" alt="icon-youtube" width="15" height="15" fetchpriority="low" />
                                    </LazyLoad>
                                :
                                    <img src={`${BASE_PATH_RESOURCES}/img/newHome/icon-youtube.svg`} loading="lazy" alt="icon-youtube" width="15" height="15" fetchpriority="low" />
                       
                            }
                         </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/claroshop/?igshid=89m1gcdqfcih" target="_blank" rel="noopener noreferrer">
                            {LAZY_L
                                ?
                                    <LazyLoad height={15} width={15} offset={100} threshold={0.95} >
                                        <img src={`${BASE_PATH_RESOURCES}/img/newHome/icon-instagram.svg`} loading="lazy" alt="icon-instagram" width="15" height="15" fetchpriority="low" />
                                    </LazyLoad>    
                                :
                                    <img src={`${BASE_PATH_RESOURCES}/img/newHome/icon-instagram.svg`} loading="lazy" alt="icon-instagram" width="15" height="15" fetchpriority="low" />
                            }
                        </a>
                    </li>
                </ul>
            </div>

            <div className="downloadApp">
                <span className="sigTitle">Descarga la app en:</span>
                <ul>
                    <li>
                        <a href="https://play.google.com/store/apps/details?id=com.americamovil.claroshop" target="_blank" rel="noopener noreferrer">
                            {LAZY_L
                                ?
                                    <LazyLoad height={15} width={15} offset={100} threshold={0.95} >
                                        <img src={`${BASE_PATH_RESOURCES}/img/newHome/google_play.svg`} loading="lazy" alt="google_play" width="112" height="37" fetchpriority="low" />
                                    </LazyLoad>
                                :    
                                    <img src={`${BASE_PATH_RESOURCES}/img/newHome/google_play.svg`} loading="lazy" alt="google_play" width="112" height="37" fetchpriority="low" />
                            }
                        </a>
                    </li>
                    <li>
                        <a href="https://itunes.apple.com/mx/app/claro-shop/id1057314145?mt=8" target="_blank" rel="noopener noreferrer">
                            {LAZY_L
                                ?
                                    <LazyLoad height={15} width={15} offset={100} threshold={0.95} >
                                        <img src={`${BASE_PATH_RESOURCES}/img/newHome/app_store.svg`} loading="lazy" alt="app_store" width="112" height="37" fetchpriority="low" />
                                    </LazyLoad>
                                :
                                    <img src={`${BASE_PATH_RESOURCES}/img/newHome/app_store.svg`} loading="lazy" alt="app_store" width="112" height="37" fetchpriority="low" />
                            }            
                        </a>
                    </li>
                </ul>
            </div>

            <div className="certificacionApp">
                <span className="sigTitle">Certificación en:</span>
                <ul className="ulCertificacion">
                    <li>
                        <a href="https://www.sellosdeconfianza.org.mx/MuestraCertificado.php?NUMERO_SERIE=MD_v100" target="_blank" rel="follow noopener noreferrer">
                            {LAZY_L
                                ?
                                    <LazyLoad height={15} width={15} offset={100} threshold={0.95} >
                                        <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/claroshop/claroshop/landings/cintillo/Sello_Datos.jpg`} loading="lazy" alt="Amipci" width="80" height="30" fetchpriority="low" />
                                    </LazyLoad>
                                :
                                    <img src={`${BASE_PATH_RESOURCES}/medios-plazavip/swift/v1/claroshop/claroshop/landings/cintillo/Sello_Datos.jpg`} loading="lazy" alt="Amipci" width="80" height="30" fetchpriority="low" />
                            }        
                        </a>
                    </li>
                    <li>
                        {LAZY_L
                            ?
                                <LazyLoad height={15} width={15} offset={100} threshold={0.95} >            
                                    <img src={`${BASE_PATH_RESOURCES}/css/img/paypalCompraSegura.jpg`} loading="lazy" alt="Paypal Compra Segura" width="80" height="30" />
                                </LazyLoad>
                            :
                                <img src={`${BASE_PATH_RESOURCES}/css/img/paypalCompraSegura.jpg`} loading="lazy" alt="Paypal Compra Segura" width="80" height="30" />
                        }
                    </li>
                    <li>
                        <a href="https://distintivodigital.profeco.gob.mx/consulta.php?serie=DA21JYV3021239" target="_blank" rel="follow noopener noreferrer">
                            {LAZY_L
                                ?
                                    <LazyLoad height={15} width={15} offset={100} threshold={0.95} >
                                        <img src={`/img/distintivo.svg`} alt="logo-profeco" width="100" height="70" loading="lazy" fetchpriority="low" />
                                    </LazyLoad>    
                                :
                                    <img src={`/img/distintivo.svg`} alt="logo-profeco" width="100" height="70" loading="lazy" fetchpriority="low" />
                            }
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}