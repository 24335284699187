import React from 'react'
import './style.sass'

import BoxClaroshop from 'components/Footer/Upperfooter/BoxClaroShop'
import BoxServicioCliente from 'components/Footer/Upperfooter/BoxServicioCliente'
import BoxRedes from 'components/Footer/Upperfooter/BoxRedes'
import BoxContacto from 'components/Footer/Upperfooter/BoxContacto'


export default function UpperFooter(){
    return(
        <div className="upperfooter">
            <BoxClaroshop></BoxClaroshop>
            <BoxServicioCliente></BoxServicioCliente>
            <BoxRedes></BoxRedes>
            <BoxContacto></BoxContacto>
        </div>
    )
}