import TagManager from 'react-gtm-module-defer';
import SHA256 from "crypto-js/sha256"
import { repareTextDlayer } from 'functions/repareDataService'

// Datalayer Media type
export function datalayerMedia(colorSelectUser){
    let colorModeSystemSel, colorModeUserSel, displayModeSel
    if(window.matchMedia('(prefers-color-scheme: dark)').matches){ colorModeSystemSel = "darkMode" }
    else{ colorModeSystemSel = "lightMode" }
    if(colorSelectUser){ colorModeUserSel = "darkMode" }
    else{ colorModeUserSel = "lightMode" }
    if(navigator.standalone || window.matchMedia('mqStandAlone').matches){ displayModeSel = "standalone" }
    else{ displayModeSel = "browser" }
    

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'configMedia',
            colorModeSystem: colorModeSystemSel,
            colorModeUser: colorModeUserSel,
            displayMode: displayModeSel
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}

// Datalayer Home
export function dataLayerHome(){
    //console.log('Home-DL: ', props)
    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'viewHome',
            PageType: 'HomePage',
            seccion: 'Home'
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}

// Datalayer Producto
export function dataLayerProduct(props){
    //console.log('Producto-DL: ', props)
    const {
        id,
        title,
        sale_price,
        price,
        store,
        brand,
        categories,
        producto_estrellas,
        producto_fulfillment,
        producto_digital,
        sku,
        stage,
        catPadre,
        catHija,
        catNieta,
        variante,
        index,
        estatus,
        quantity
    } = props


    const tagManArg = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'view_item',
            PageType: 'ProductPage',
            idProducto: id,
            precioProducto: sale_price,
            categoria: categories,
            ecomm_pagetype: 'product',
            ecomm_prodid: id,
            ecomm_totalvalue: price,
            ecomm_category: categories,
            ProductID: id,
            productoFB: title,
            stageFB: stage,
            ecommerce: {
                items:[
                    {
                        item_name: String(title),
                        item_id: String(id),
                        price: String(sale_price),
                        item_brand: String(brand),
                        item_category: String(catPadre),
                        item_category2: String(catHija),
                        item_category3: String(catNieta),
                        item_variant: String(variante),
                        quantity: String(quantity),
                        index: index
                    }
                ],
                detail: {
                    actionField: {
                        list: 'Product'
                    },
                    products:[
                        {
                            name: title,
                            id: id,
                            price: sale_price,
                            brand: brand,
                            category: categories,
                            affiliation: store,
                            producto_estrellas: producto_estrellas,
                            producto_fulfillment: producto_fulfillment,
                            producto_digital: producto_digital,
                            dimension6: 'disponible',
                            dimension7: sale_price,
                            dimension8: sku,
                            dimension19: store,
                            dimension20: price,
                            dimension21: store,
                            dimension22: brand,
                            dimension23: categories,
                            dimension24: sku,
                            dimension25: id,
                            dimension26: title,
                            dimension27: sale_price,
                            dimension28: store,
                            dimension38: producto_estrellas,
                            dimension39: producto_fulfillment,
                            dimension40: producto_digital,
                            item_name: title,
                            item_id: id,
                            item_brand: brand,
                            item_category: catPadre,
                            item_category2: catHija,
                            item_category3: catNieta,
                            item_variant: variante,
                            index: index,
                            quantity: quantity,
                            estatus: estatus
                        }
                    ]
                }
            }
        }  
    }
    TagManager.dataLayer( tagManArg )
}

export function dataLayerOutProduct(props){
    const {
        id,
        stock
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'producto_agotado',
            id: id,
            stock: stock
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}

// Datalayer Categoria
export function dataLayerCategory(props){
    //console.log('Categoria-DL: ', props)
    const {
        idCat,
        categories,
        listIdProducts,
        results,
        listProducts
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'view_category',
            PageType: "ListingPage",
            idCategoria: idCat,
            ecomm_pagetype: "category",
            ecomm_category: categories,
            ProductIDList: listIdProducts,
            numResultados20: results,
            categoriaClaro20: idCat,
            ecommerce:{
                currencyCode: "MXN",
                impressions: listProducts
            },
            firstResults: listIdProducts
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}

// Datalayer wishlist Agrgar
export function dataLayerWishlistAdd(props){   
    //console.log('WishlistAdd-DL: ', props)
    const {
        item
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'add_to_wishlist',
            ecommerce: {
                items: item
            }
        }  
    }
    TagManager.dataLayer(tagManagerArgs )
}

// Datalayer Carrito
export function dataLayerCart(props){   
    //console.log('Carrito-DL: ', props)
    const {
        valueCart,
        items
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'view_cart',
            ecommerce: {
                value: valueCart,
                currencyCode: 'MXN',
                view: {
                    products: items
                },
                items: items
            }
        }  
    }
    TagManager.dataLayer(tagManagerArgs )
}

// Datalayer Carrito Agregar
export function dataLayerCartAdd(props){   
    //console.log('CarritoAdd-DL: ', props)
    const {
        item
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'add_to_cart',
            ecommerce: {
                currencyCode: 'MXN',
                add: {
                    products: item
                },
                items: item
            }
        }  
    }
    TagManager.dataLayer(tagManagerArgs )
}
export function dataLayerCartAddOld(props){   
    //console.log('CarritoAdd-DL: ', props)
    const {
        item
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'addToCart',
            idProducto: item[0].id,
            ProductID: item[0].id,
            ecomm_prodid: item[0].id,
            categoria:  item[0].category,
            ecomm_category:  item[0].category,
            ecommerce: {
                currencyCode: 'MXN',
                add: {
                    products: item
                }
            }
        }  
    }
    TagManager.dataLayer(tagManagerArgs )
}

// Datalayer Carrito Remover
export function dataLayerCartRemove(props){   
    //console.log('CarritoRemove-DL: ', props)
    const {
        item
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'remove_from_cart',
            ecommerce: {
                currencyCode: 'MXN',
                remove: {
                    products: item
                },
                items: item
            }
        }  
    }
    TagManager.dataLayer(tagManagerArgs )
}

// Datalayer checkout Agrgar
export function dataLayerCheckout(props){   
    //console.log('Checkout-DL: ', props)
    const {
        valueCart,
        items
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'begin_checkout',
            ecommerce: {
                value: valueCart,
                currencyCode: 'MXN',
                products: items,
                items: items
            }
        }  
    }
    TagManager.dataLayer(tagManagerArgs )
}

// Datalayer Login
export function dataLayerLogin(){
    //console.log('Login-DL: ', props)

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'checkoutStep1',
            ecommerce: {
                checkout: {
                    actionField: {
                        step: 1,
                        option: 'Web'
                    },
                    products: []
                }
            }
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}

export function dataLayerLogout(){
    //console.log('Login-DL: ', props)

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'checkoutOption',
            ecommerce: {
                checkout: {
                    actionField: {
                        step: 5,
                        option: 'logout'
                    },
                    products: []
                }
            }
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}

// Datalayer Login
export function dataLayerUserInfo(props){
    const {
        idUser,
        userInfo
    } = props

    
    /*var userInfoData     = [];
    userInfoData.push({
        idClaro: userInfo.id,
        nombre: SHA256(userInfo.name).toString(),
        apellidoP: userInfo.last_name,
        apellidoM: userInfo.second_last_name,
        nombreCompleto: userInfo.name+' '+userInfo.last_name+' '+userInfo.second_last_name,
        genero: userInfo.gender,
        cumpleanios: userInfo.birthday,
        correo: userInfo.email
    });   
*/
    
    
    let userInfoData = {
        idClaro: userInfo.id,
        nombre: repareTextDlayer(userInfo.name),
        apellidoP: repareTextDlayer(userInfo.last_name),
        apellidoM: repareTextDlayer(userInfo.second_last_name),
        nombreCompleto: repareTextDlayer(userInfo.name)+' '+repareTextDlayer(userInfo.last_name)+' '+repareTextDlayer(userInfo.second_last_name),
        genero: userInfo.gender !=="" ? userInfo.gender : "",
        cumpleanios: userInfo.birthday!=="" && userInfo.birthday!=null ? userInfo.birthday.replace(/\-/ig, ''): "",
        correo: SHA256(userInfo.email).toString()
    }
    
   
    console.log("props dl userInfo: ", userInfoData)

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'loginUserid',
            userId: String(idUser),            
            //userData: window.btoa(userInfo)
            userData: JSON.stringify(userInfoData)
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}


/* faltantes */
// Datalayer Search
export function dataLayerSearch(props){
    //console.log('Categoria-DL: ', props)
    const {
        idCat,
        categories,
        listIdProducts,
        results,
        listProducts
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'view_Search',
            PageType: "ListingPage",
            idCategoria: idCat,
            ecomm_pagetype: "category",
            ecomm_category: categories,
            ProductIDList: listIdProducts,
            numResultados20: results,
            categoriaClaro20: idCat,
            ecommerce:{
                currencyCode: "MXN",
                impressions: listProducts
            },
            firstResults: listIdProducts
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}

// Datalayer Store
export function dataLayerStore(props){
    //console.log('Categoria-DL: ', props)
    const {
        idCat,
        categories,
        listIdProducts,
        results,
        listProducts
    } = props

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'view_Search',
            PageType: "ListingPage",
            idCategoria: idCat,
            ecomm_pagetype: "category",
            ecomm_category: categories,
            ProductIDList: listIdProducts,
            numResultados20: results,
            categoriaClaro20: idCat,
            ecommerce:{
                currencyCode: "MXN",
                impressions: listProducts
            },
            firstResults: listIdProducts
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}

// Datalayer Store
export function onPromoClick(id, name, creative, position){

    const tagManagerArgs = {
        dataLayerName: 'PageDataLayer',
        dataLayer: {
            event: 'promotionClick',
            ecommerce: {
                promoClick: {
                    promotions: [
                        {
                            id: id,
                            name: name,
                            creative: creative,
                            position: position
                        }
                    ]
                }
            }
        }
    }
    TagManager.dataLayer(tagManagerArgs)
}
