import React, {useState} from 'react'
import './style.sass';
import { BASE_PATH_PORTAL } from 'constants/general'
import { Link } from 'react-router-dom';

export default function BoxServiceClient(){
    const [menuView, setMenuView] = useState(false)

    const toggleClass = () => {
        setMenuView( !menuView )
    }

    return (
        <div className="boxServicioCliente">
            <p onClick={toggleClass} className={menuView ? 'active' : null}>Servicio al Cliente</p>
            <ul>
                <li><a href="http://facturacionelectronica.claroshop.com/" target="_blank" rel="noopener noreferrer">Facturación electrónica</a></li>
                <li><a href={`${BASE_PATH_PORTAL}/c/claroPromo/`} >Promociones diversas vigentes</a></li>
                {/* <li><a href={`${BASE_PATH_PORTAL}/c/Claroshop/claroPromo/index.html`} >Promociones diversas vigentes</a></li> */}
                {/* https://www.claroshop.com/c/Claroshop/claroPromo/index.html {`${BASE_PATH_PORTAL}/c/claroPromo/`} */}
                <li><Link to={`/formas-pago/`} >Formas de pago</Link></li>
                <li><Link to={`/politica-devoluciones/`} >Cambios y devoluciones</Link></li>
                <li><a href={`${BASE_PATH_PORTAL}/l/faqs/`} >Preguntas frecuentes</a></li>
            </ul>
        </div>
    )
}
;