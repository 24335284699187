import React,{ useState } from 'react';
import './style.sass';

function LowerFooter(){

    const [terminos ] = useState({ title: "Términos y condiciones", url: "/terminos-y-condiciones/"});
    const [politicas ] = useState({ title: "Políticas de privacidad", url: "/aviso-privacidad/"});

    return (
        <div className="lowerfooter">
            <div className="legales">
                <a href={`${terminos.url}`} title={terminos.title}  >{terminos.title}</a>
                <span>|</span>
                <a href={`${politicas.url}`} title={politicas.title} >{politicas.title}</a>
            </div>
        </div>
    )
}

export default React.memo(LowerFooter)