import {BASE_PATH_API,TIMEOUT_TIME} from 'constants/general'
import { decodeStorage } from 'functions/storageBase64'
import axios from 'axios'


//- Get Wishlist
export async function getWishlist (){
    const isLoged = decodeStorage('session_token')
    const accessToken = "Bearer " + decodeStorage("access_token");
    // const apiURL = `${BASE_PATH_API}/app/v1/wish-list?token=${isLoged}`
    const apiURL = `${BASE_PATH_API}/app/v1/wish-list`

    const config = {
        method: 'get',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
            // 'Cache-Control': 'no-cache',
            // 'Pragma': 'no-cache',
            // 'Expires': '0'
        }
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.response.data,
            'error': error.response.data.msg
        }
    })
}


//- Post Wishlist
export async function postWishlist ({idProduct} = {}){
    const isLoged = decodeStorage('session_token')
    const accessToken = "Bearer " + decodeStorage("access_token");
    // const apiURL = `${BASE_PATH_API}/app/v1/wish-list?token=${isLoged}`
    const apiURL = `${BASE_PATH_API}/app/v1/wish-list`

    const data = JSON.stringify({"token": isLoged, "products":[idProduct]});

    const config = {
        method: 'post',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
        data: data
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.response.data,
            'error': error.response.data.msg
        }
    })
}


//- Delete Wishlist
export async function deleteWishlist ({idProduct} = {}){
    const isLoged = decodeStorage('session_token')
    const accessToken = "Bearer " + decodeStorage("access_token");
    // const apiURL = `${BASE_PATH_API}/app/v1/wish-list?token=${isLoged}`
    const apiURL = `${BASE_PATH_API}/app/v1/wish-list`

    const data = JSON.stringify({"token": isLoged, "products":[idProduct]});

    const config = {
        method: 'delete',
        url: apiURL,
        timeout: TIMEOUT_TIME,
        withCredentials: false,
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Authorization': accessToken
        },
        data: data
    };

    return await axios(config)
    .then(res => {
        const {
            headers, 
            status, 
            data
        } = res
        return {
            'headers': headers,
            'statusCode': status,
            'data': data,
            'error': ''
        }
    })
    .catch(error =>{
        return {
            'headers': '',
            'statusCode': error.request.status,
            'data': error.request.response,
            'error': error.response.data.msg
        }
    })
}