import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import MenuPrincipal from 'components/Menu'
import CartHeaderCount from 'components/Cart/CartHeaderCount'
import HeadeWishlist from 'components/CardProduct/CardWishlist/headerWishlist'
import { useStorage64 } from 'hooks/useStorageBase64'

import {ErrorBoundary} from 'components/Error/ErrorBoundary'
import { HeaderBarMovile } from 'pages/Header/HeaderNavegacion/HeaderBarMovile'

export function HeaderMobile(props) {
    const {
        isLoged,
        profile,
        totalItems,
        page = null
    } = props

    const [showModal, SetShowModal] = useState(false)
    const [dataFace] = useStorage64('dataFacebook', '')
    const [nombre0, setnombre0] = useState("")
    const hostName = window.location.origin
    const urlLink = window.location.href

    //- Funciones para activar o desactivar el modal del menu lateral
    const showMenu = () => { SetShowModal(true) }
    const handleClose = () => { SetShowModal(false) }

    let nombreArray = []
    useEffect(() => {
        if (profile.nombre) {
            nombreArray = profile.nombre.split(' ')
            setnombre0(nombreArray[0])
        }
    }, [profile])
    
    useEffect(() => {
        if (isLoged && dataFace.name) {
            nombreArray = dataFace.name.split(' ')
            setnombre0(nombreArray[0])
        }
    }, [dataFace])

    return (
        <>
            <header className={page}>
                <div className="headerMobile">
                    <div onClick={showMenu} className="menuLateral" aria-label="Menu Principal"></div>
                    <Link to="/" className="logoClaroshop" aria-label="logoClaroshop"></Link>
                    
                    <ErrorBoundary>
                        {page !== "pageHome" &&
                            <>
                                <Link to="/buscador" className="searchIcon" aria-label="Icono Buscador"></Link>
                            </>
                        }
                    </ErrorBoundary>

                    <ErrorBoundary>
                        {page === "pageHome" &&
                            <HeadeWishlist ruta={isLoged ? '/mi-cuenta/listadeseos/' : `/login?redirect=${hostName}/mi-cuenta/listadeseos/`} />
                        }
                    </ErrorBoundary>

                    <ErrorBoundary>
                        {isLoged && (profile || dataFace)
                            ?
                            <Link to="/mi-cuenta" className="icoMiCuenta navBar" title="Mi Cuenta"><span>{String(nombre0).length > 8 ? <>{String(nombre0).substring(0, 7)} ...</> : String(nombre0)}</span></Link>
                            :
                            <Link to={`/login?redirect=${urlLink}`} className="icoMiCuenta navBar" title="Mi Cuenta"></Link>
                        }
                    </ErrorBoundary>

                    <ErrorBoundary>
                        <CartHeaderCount ruta={isLoged ? '/carrito/detalle' : `/login?redirect=${hostName}/carrito/detalle`} cantidad={isLoged ? totalItems : 0} />
                    </ErrorBoundary>
                </div>
                <div className="container">
                    <div className="menuPrincipal">
                        <HeaderBarMovile isLoged={isLoged} profile={profile} />
                    </div>
                </div>
            </header>
            {showModal && <MenuPrincipal onClose={handleClose} />}
        </>
    )
}