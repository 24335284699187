import React from 'react'
import { TemplateLandingAD, CardLandingAD } from '../TemplateLanding'
import '../style.sass'

export default function NecesitasAyuda() {
    
    return (
        <>
            <TemplateLandingAD
                title={"¿Cómo podemos ayudarte?"}
                landing={true}
            >
                <CardLandingAD
                    tituloLanding={"No recibí mi producto"}
                >
                    <p>Si tu producto aparece como Entregado pero todavía no has recibido te recomendamos:</p>
                    <ul className="dotedList">
                        <li>Cerciorate de que no lo haya recibido por ti algún familiar, amigo, vecino o personal de la dirección.</li>
                        <li>Comunicarte con la paquetería para verificar el estatus de tu envío.</li>
                        <li>Comprobar la dirección de entrega que seleccionaste en tu compra.</li>
                    </ul>
                    <p>Si ya has verificado estos puntos por favor <strong className="textRed">comunícate con nosotros</strong>.</p>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿Cómo facturo mi compra?"}
                >
                    <p>El proceso de facturación en Claroshop.com va dirigido en proveedor:</p>
                    <ul className="dotedList">
                        <li>Para productos de proveedores como Amigo Kit, Sexy & Sombres, puedes generar tu factura en nuestro portal en la sección de <strong className="textRed">facturación</strong>.</li>
                        <li>Para productos de proveedores de MarketPlace, el proveedor genera tu factura, solicítala al momento de tu orden. Debes proporcionar RFC, Razón Social, Dirección Fiscal, Número de Pedido y Artículo a facturar.</li>
                    </ul>
                    <p>Recibirás tu factura en un plazo de 30 días naturales después de realizar el primer pago.</p>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿En cuánto tiempo recibo mi reembolso?"}
                >
                    <p>Tipos de reembolso:</p>
                    <ul className="miniTalbe">
                        <li><div className="contTitleMiniT">Tarjeta de crédito:</div> Entre 3 a 10 días hábiles.</li>
                        <li><div className="contTitleMiniT">OXXO:</div> De inmediato.</li>
                        <li><div className="contTitleMiniT">Tarjeta Claroshop:</div> 24hrs después del reflejo y 2 notificaciones siguientes.</li>
                        <li><div className="contTitleMiniT">Paypal:</div> De inmediato.</li>
                        <li><div className="contTitleMiniT">Otros, transferencia bancaria:</div> Hasta 48 hrs hábiles.</li>
                        <li><div className="contTitleMiniT">Pago en tienda con referencia a nombre del cliente:</div> Hasta 48 hrs hábiles.</li>
                        <li><div className="contTitleMiniT">Pago en tienda sin referencia:</div> Hasta 72 hrs hábiles.</li>
                        <li><div className="contTitleMiniT">Mastercard Saldazo:</div> Hasta 48 hrs hábiles.</li>
                        <li><div className="contTitleMiniT">Comprobante Santander:</div> Hasta 72 hrs hábiles.</li>
                    </ul>
                </CardLandingAD>

            </TemplateLandingAD>
        </>
    )
}