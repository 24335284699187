import { BASE_PATH_RESOURCES, NO_IMAGE } from 'constants/general'

export function repareImage(urlImage){
    let urlRepairImage = urlImage
    let urlImageOK
    if(urlRepairImage?.length >=1){
        urlImageOK = urlRepairImage.replace('https://resources.claroshop.com', `${BASE_PATH_RESOURCES}`).replace('https://www.claroshop.com', `${BASE_PATH_RESOURCES}`).replace('http://medios.plazavip.com/', `${BASE_PATH_RESOURCES}/medios-plazavip/`).replace('http://imagenes.sanborns.com.mx/', `${BASE_PATH_RESOURCES}/imagenes-sanborns/`).replace('http://img.plazavip.com/', `${BASE_PATH_RESOURCES}/img-plazavip/`).replace('https://medios-plazavip.sears.com.mx/',`${BASE_PATH_RESOURCES}/medios-plazavip/`).replace('/publicidad/', '/mkt/').replace('http://', 'https://').replace('beta.dev.','resources.')
    }else{
        urlImageOK = NO_IMAGE
    }
    //console.log(urlImageOK)
    return urlImageOK
}

export function repareTitleSEO(text){
    let stringRepairTextSEO = String(text)
    let textOK = stringRepairTextSEO.toLowerCase().trim().replace(/[^a-zA-Z 0-9.]+/g, '').replace(/\|/ig, "").replace(/[\/\\#,()~%.:*?<>{}]/ig, '').replace(/\./ig, '').replace(/\,/ig, '').replace(/\%/ig, '').replace(/\//ig, '').replace(/\"/ig, '').replace(/\:/ig, '').replace(/ /ig, '-').replace(/\ñ/ig, 'n').replace(/\á/ig, 'a').replace(/\é/ig, 'e').replace(/\í/ig, 'i').replace(/\ó/ig, 'o').replace(/\ú/ig, 'u').replace(/\ü/ig, 'u').replace(/\%7C/ig, "")
    //console.log(textOK)
    return textOK
}

export function repareTextSimple(text){
    let stringRepairTextSimple = String(text)
    let textOK = stringRepairTextSimple.toLowerCase().trim().replace(/\á/ig, 'a').replace(/\é/ig, 'e').replace(/\í/ig, 'i').replace(/\ó/ig, 'o').replace(/\ú/ig, 'u').replace(/\ü/ig, 'u')
    //console.log(textOK)
    return textOK
}

export function repareTextLatin(text){
    let stringRepairTextSimple = String(text)
    let textOK = stringRepairTextSimple.replace(/\*/g,'').replace(/\“/g,'').replace(/\”/g,'')
    //console.log(textOK)
    return textOK
}

export function repareTextHtml(text){
    let stringRepairTextSimple = String(text)
    let textOK = stringRepairTextSimple.replace(/\&ntilde;/g, 'ñ').replace(/\&Ntilde;/g, 'Ñ').replace(/\&amp;/g, '&').replace(/\&Ntilde;/g, 'Ñ').replace(/\&ntilde;/g, 'ñ').replace(/\&Ntilde;/g, 'Ñ').replace(/\&Agrave;/g, 'À').replace(/\&Aacute;/g, 'Á').replace(/\&Acirc;/g,'Â').replace(/\&Atilde;/g,'Ã').replace(/\&Auml;/g,'Ä').replace(/\&Aring;/g,'Å').replace(/\&AElig;/g,'Æ').replace(/\&Ccedil;/g,'Ç').replace(/\&Egrave;/g,'È').replace(/\&Eacute;/g,'É').replace(/\&Ecirc;/g, 'Ê').replace(/\&Euml;/g,'Ë').replace(/\&Igrave;/g, 'Ì').replace(/\&Iacute;/g, 'Í').replace(/\&Icirc;/g, 'Î' ).replace(/\&Iuml;/g, 'Ï').replace(/\&ETH;/g, 'Ð').replace(/\&Ntilde;/g, 'Ñ').replace(/\&Ograve;/g, 'Ò').replace(/\&Oacute;/g, 'Ó').replace(/\&Ocirc;/g, 'Ô' ).replace(/\&Otilde;/g, 'Õ').replace(/\&Ouml;/g, 'Ö'  ).replace(/\&Oslash;/g, 'Ø').replace(/\&Ugrave;/g ,'Ù').replace(/\&Uacute;/g, 'Ú').replace(/\&Ucirc;/g, 'Û').replace(/\&Uuml;/g, 'Ü').replace(/\&Yacute;/g, 'Ý').replace(/\&THORN;/g, 'Þ').replace(/\&szlig;/g, 'ß').replace(/\&agrave;/g, 'à').replace(/\&aacute;/g, 'á').replace(/\&acirc;/g, 'â').replace(/\&atilde;/g, 'ã').replace(/\&auml;/g, 'ä').replace(/\&aring;/g, 'å').replace(/\&aelig;/g, 'æ').replace(/\&ccedil;/g, 'ç').replace(/\&egrave;/g, 'è').replace(/\&eacute;/g, 'é').replace(/\&ecirc;/g, 'ê').replace(/\&euml;/g, 'ë').replace(/\&igrave;/g, 'ì').replace(/\&iacute;/g, 'í').replace(/\&icirc;/g, 'î').replace(/\&iuml;/g, 'ï').replace(/\&eth;/g, 'ð').replace(/\&ntilde;/g, 'ñ').replace(/\&ograve;/g,'ò').replace(/\&oacute;/g,'ó').replace(/\&ocirc;/g,'ô').replace(/\&otilde;/g,'õ').replace(/\&ouml;/g,'ö').replace(/\&oslash;/g,'ø').replace(/\&ugrave;/g,'ù').replace(/\&uacute;/g,'ú').replace(/\&ucirc;/g,'û').replace(/\&uuml;/g , 'ü').replace(/\&yacute;/g, 'ý').replace(/\&thorn;/g, 'þ').replace(/\&yuml;/g, 'ÿ').replace(/\&quot;/g, '\"').replace(/\&acute;/g, "\'");
    //console.log(textOK)
    return textOK
}

export function repareSectionURL(url){
    let urlSection = String(url)
    let urlOK = urlSection.replace('/landing/categorias', '/categoria').replace('/l/', '/landing/').replace('/p/', '/landing/').replace(/\/$/g, '')
    //console.log(urlOK)
    return urlOK
}

export function repareTextDlayer(text){
    let stringRepairTextSimple = String(text).normalize("NFD").replace(/[\u0300-\u036f]/ig, "")    
    let textOK = stringRepairTextSimple.toLowerCase().trim().replace(/\á/ig, 'a').replace(/\é/ig, 'e').replace(/\í/ig, 'i').replace(/\ó/ig, 'o').replace(/\ú/ig, 'u').replace(/\ü/ig, 'u').replace(/\&amp;/ig, '').replace(/\&ntilde;/ig, '').replace(/\&acute;/ig, '').replace(/\&quot;/ig, '').replace(/\%/ig, '').replace(/\´/ig, '').replace(/\"/ig, '').replace(/\//ig, '').replace(/\″/ig, '').replace(/\″/ig, '').replace(/\:/ig, '').replace(/\ñ/ig, 'n').replace(/\'/ig, '').replace(/[\u00B4]/ig, "").replace(/[\u2033]/ig, "").replace(/[\u0027]/ig, "").replace(/[\u0022]/ig, "").replace(/[\u0025]/ig, "").replace(/[\u0026]/ig, "");
    //console.log("entra: ", text, " sale: ", textOK)
    //console.log(textOK)    
    return textOK
}

export function capitalizeFirstLetter(texto) {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
}