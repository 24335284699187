import React, { useState, useEffect } from 'react'
import './style.sass'
import Modal from 'components/Modal'
import { decodeStorage, encodeStorage } from 'functions/storageBase64'
import { removeStorage } from 'functions/storageBase64'
// import {useStorage64} from 'hooks/useStorageBase64'
import ModuleIsLogin from 'components/BarCP/ModuleIsLogin'
import ModuleNotLogin from 'components/BarCP/ModuleNotLogin'
import { useHistory, useLocation } from 'react-router-dom'

export default function BarraCP(props) {
    const {
        changeCP,
        clase,
        fechaEstimada
    } = props

    // const [isLoged] = useStorage64('session_token','')
    // const [codigoP] = useStorage64('codigoPostal','')
    // const [shipping] = useStorage64('shipping','')
    // const [dirPredeterminada] = useStorage64('dirPredeterminada','')

    const history = useHistory()
    const location = useLocation()
    const hashLink = window.location.hash
    const urlLink = window.location.href

    const isLoged = decodeStorage('session_token')
    const codigoP = decodeStorage('codigoPostal')
    const shipping = decodeStorage('shipping')
    //const sendDirections = decodeStorage('sendDirections')
    const dirPredeterminada = decodeStorage('dirPredeterminada')

    //console.log('sendDirections: ',sendDirections)

    const [showModal, setShowModal] = useState(false)
    const [codigoPostal, setCodigoPostal] = useState(codigoP)


    const showModalCP = () => {
        setShowModal(true)
    }
    useEffect(()=>{
        if(hashLink === "#modalPostalCode"){
            if(!decodeStorage('modalPostalCode')){
                encodeStorage('modalPostalCode', true)
                showModalCP()
            }
        }else{
            removeStorage('modalPostalCode')
            setShowModal(false)
        }
    },[hashLink, urlLink])


    //- funcion para visualizar el modal de cp
    const handleClose = () => {
        history.push(location.pathname)
    }
    const showMenu = () => { 
        history.push('#modalPostalCode')
    }

    //- Funcion que devuelve el cp y lo agrega al storage
    const changeDirectionBar = (val) => {
        // console.log('val', val)
        changeCP(val)
        encodeStorage('codigoPostal', val)
        setCodigoPostal(val)
        history.push(location.pathname)
    }

    //efecto para re-renderizar cuando cambia el CP o la direccion
    useEffect(() => {
        if (codigoPostal) {
            // console.log('codigoPostal ',codigoPostal)
            // console.log('codigoP ',codigoP)
            setCodigoPostal(codigoP)
        }
        if (dirPredeterminada) {
            //console.log('dirPredeterminada ',dirPredeterminada)
            if(!codigoPostal){
                // console.log('codigoP ',codigoP)
                setCodigoPostal(codigoP)
            }
        }
    }, [codigoPostal, dirPredeterminada, codigoP])

    return (
        <div className={clase}>
            <div className="titleCP" onClick={showMenu}>
                {codigoP
                    ? clase === 'barHeaderCP'
                        ?
                            <p>
                                <span className="firstTextCP">{`Enviar a ${dirPredeterminada ? dirPredeterminada?.nombre + ',' : ''} `} </span>
                                <span className="lastTextCP">{`${codigoPostal}, ${dirPredeterminada ? dirPredeterminada?.calle : shipping?.municipio}`}</span>
                            </p>
                        : null

                    : (clase === 'barHeaderCP'
                        ? <p>Introduce un Código Postal</p>
                        : clase === "cpDetalle"
                            ? <div className="etiquetaP"><p className="dateDelivery">Conoce cuando te llega</p></div>
                            : <p className="cp">Introduce un C.P</p>
                    )
                }

                {codigoP
                    ? (clase !== 'barHeaderCP' && clase !== 'fechaEstimada' && clase !== "cpDelivery") && <p className="textoDireccion">{`${dirPredeterminada ? dirPredeterminada?.nombre + ',' : ''} CP ${codigoPostal}, ${dirPredeterminada ? dirPredeterminada?.municipio : shipping?.municipio}`}</p>
                    : null
                }

                {codigoP
                    ? clase === 'fechaEstimada' && <p className="icono"></p>
                    : clase === "fechaEstimada" && <p className="icono"></p>
                }

                {codigoP
                    ? clase === "cpDelivery" && fechaEstimada
                        ? <p className="dateDeliveryConFecha">{fechaEstimada}</p>
                        : null
                    : null
                }
            </div>
            {showModal &&
                <Modal onClose={handleClose}>
                    {isLoged
                        ? <ModuleIsLogin returnDirection={changeDirectionBar} onClose={handleClose} />
                        : <ModuleNotLogin returnDirection={changeDirectionBar} onClose={handleClose} />
                    }
                </Modal>
            }
        </div>
    )
}