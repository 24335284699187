import React, {useState, useEffect} from 'react'
import ReactDOM from 'react-dom'
import Header from 'pages/Header/HeaderNavegacion'
import './style.sass'

function Modal({...props}){
    const {
        children,
        onClose,
        type,
        closeBody,
        title
    } =props

    //recibe la direccion del CP
	const [updateCPBar, setUpdateCPBar] = useState()
	const reciveDirection = (val) =>{
		setUpdateCPBar(val)
	}
	const [upCP, setUpCP] = useState()

	useEffect(()=>{
		if(upCP){
			//console.log('')
		}
	},[upCP, updateCPBar])

    return(
        closeBody !== "closeBody"
            ?
                <div className={`modal ${type}`}>
                    <div className="contentModal" >
                        {type === "fullHeader"
                            ? <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection}/>
                            :
                                <div className="contentClose">
                                    <div className="btn close" onClick={onClose}></div>
                                    {title ? <p className="title">{title}</p> : null }
                                </div>
                        }        
                        <div className="dataModal">
                            {children}
                        </div>
                    </div>
                </div>
            :
                <div className={`modal ${type}`} onClick={onClose}>
                    <div className="contentModal">
                        {type === "fullHeader"
                            ? <Header setUpCP={setUpCP} changeDirectionBar={reciveDirection}/>
                            :
                                <div className="contentClose">
                                    <div className="btn close" onClick={onClose}></div>
                                    {title ? <p className="title">{title}</p> : null }
                                </div>
                        }        
                        <div className="dataModal">
                            {children}
                        </div>
                    </div>
                </div>
            
    )
}

export default function ModalPortal ({...props}){
    const {
        children,
        onClose,
        type,
        closeBody,
        title
    } = props

    return ReactDOM.createPortal(
        <Modal onClose={onClose} type={type} title={title} closeBody={closeBody}>
            {children}
        </Modal>,
        document.getElementById('modal-root')
    )
}