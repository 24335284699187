import React from 'react'
import { TemplateLandingAD, CardLandingAD } from '../TemplateLanding'
import '../style.sass'

export default function PoliticaDev() {

    return (
        <>
            <TemplateLandingAD
                title={"¿Cómo podemos ayudarte?"}
                landing={true}
            >
                <CardLandingAD
                    tituloLanding={"¿Cómo sé si mi pedido fue autorizado?"}
                >
                    <p>Recibirás un correo electrónico con la confirmación de tu compra. También puedes consultar el estatus y fecha estimada de entrega en la sección de Mis pedidos dentro de <span className='textRed'>Mi Cuenta</span>.</p>
                    <a href="https://www.claroshop.com/mi-cuenta" target="_blank" rel="noopener noreferrer" className='linkLandingAD'>https://www.claroshop.com/mi-cuenta/</a>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿Cuánto tiempo tardan en autorizar mis compras?"}
                >
                    <p>En un máximo de 48 hrs después de tu compra, podrás conocer el estatus de tu pedido en el apartado de Mis pedidos, ubicado dentro de tu cuenta en el portal  <span className='textRed'>ClaroShop.com</span>.</p>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿Cuánto tiempo tardo en autorizar mi pedido?"}
                >
                    <p>El tiempo para autorizar tu pedido depende de la forma de pago que elijas al realizar tu compra.</p>
                    <ul className="miniTalbe">
                        <li><div className="contTitleMiniT">Tarjetas de crédito/débito:</div> Se llevan a cabo en un máximo de 24 horas para recibir la confirmación del pedido.</li>
                        <li><div className="contTitleMiniT">Paypal:</div> Los pedidos se aprueban o rechazan en automáticamente y podrás recibir información de manera inmediata después de tu compra. Si tienes problemas con la información que cargas, el área de pagos se pondrá en contacto con ellos.</li>
                        <li><div className="contTitleMiniT">Depósitos en efectivo:</div> Se procesan de manera inmediata al ingresar el comprobante de pago correspondiente. Si el proceso de autorización o haber realizado el ingreso del pedido no se lleva a cabo en un marco de 72 horas hábiles, si no lo hiciste de esa manera no recibiras el dinero pero el comprobante de pago o la siguiente dirección electrónica indicando en el motivo del correo el número de pedido: <a href="mailto:depositos@claroshop.com" target="_blank" rel="noopener noreferrer" className='linkLandingAD1'>depositos@claroshop.com</a></li>
                        <li><div className="contTitleMiniT">Pago en tienda:</div> El pedido será autorizado en un máximo de 24 horas hábiles, favor de consultar tu correo.</li>
                        <li><div className="contTitleMiniT">Certificados Santander: </div> Los pedidos se aprueban o rechazan en automático y deberás esperar la respuesta del banco, que será en un máximo de 24 horas. Si tienes problemas con la autorización, el área de pagos se pondrá en contacto contigo.</li>
                    </ul>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿A qué dirección de correo electrónico puedo mandar mi recibo de pago?"}
                >
                    <p>El voucher debe ser enviado al correo de <a href="mailto:depositos@claroshop.com" target="_blank" rel="noopener noreferrer" className='linkLandingAD'>depositos@claroshop.com</a>.</p>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿A qué cuenta de correo debo mandar mis documentos Telmex para que se autorice mi pedido?"}
                >
                    <p>Los documentos para validación y proceder con una autorización deben enviar a <a href="mailto:contacto@claroshop.com" target="_blank" rel="noopener noreferrer" className='linkLandingAD'>contacto@claroshop.com</a>.</p>
                </CardLandingAD>

            </TemplateLandingAD>
        </>
    )
}