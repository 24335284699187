import React from 'react'
import { TemplateLandingAD, CardLandingAD } from '../TemplateLanding'
import '../style.sass'

export default function PoliticaDev4() {

    return (
        <>
            <TemplateLandingAD
                title={"Causas"}
                landing={true}
            >
                <CardLandingAD
                    tituloLanding={"Las causas de devolución aceptadas y sin costo o penalización para el cliente son:"}
                >
                    <ul className="dotedList">
                        <li>En caso de que la mercancía recibida no fuera la que se adquirió, Claroshop.com enviará el producto adecuado que el cliente compró, si esto no es posible enviará un reemplazo del mismo precio, monto o del mismo y el cliente dará la oportunidad para cumplir con dicha responsabilidad.</li>
                        <li>En caso de que la mercancía recibida este dañada, maltratada o incompleta y se de aviso oportunamente a Claroshop.com.</li>
                        <li>Si la devolución de la mercancía es por razones personales, ésta se deberá encontrar en perfectas condiciones con empaque original, sin abrir y tal como fue recibido.</li>
                        <li>La solicitud de devolución y aclaración deberá ser dentro de los primeros treinta (30) días posteriores a la recepción del pedido.</li>
                    </ul>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"No hay Cambios ni Devoluciones en los siguientes artículos o departamentos:"}
                >
                    <ul className="dotedList">
                        <li>Joyería fina y de fantasía, Lencería, perfumería, cosméticos, tratamientos dermatológicos, telefonía celular, y Software.</li>
                        <li>En Computación, Tablets, iPods, Telefonía celular, la garantía es directamente con el proveedor.</li>
                    </ul>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"Requisitos"}
                >
                    <p>Para realizar una devolución de producto se solicitará la siguiente información, la cual puedes hacer llegar por Correo Electrónico, Chat o bien vía Telefónica:</p>
                    <ul className="dotedList">
                        <li>Número de pedido con el que compraste tu producto. (Se puede obtener en la sección de MI CUENTA).</li>
                        <li>Descripción del artículo y/o pedido.</li>
                        <li>Fecha en la que se recibió el pedido.</li>
                        <li>Motivo de le devolución.</li>
                    </ul>
                </CardLandingAD>                
            </TemplateLandingAD>
        </>
    )
}