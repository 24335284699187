import React from 'react'
import { TemplateLandingAD, CardLandingAD } from '../TemplateLanding'
import '../style.sass'

export default function PoliticaDev3() {

    return (
        <>
            <TemplateLandingAD
                title={"¿Cómo podemos ayudarte?"}
                landing={true}
            >
                <CardLandingAD
                    tituloLanding={"¿Puedo solicitar el cambio de mi dirección de entrega?"}
                >
                    <p>Una vez tu pedido ha sido procesado este cambio de dirección no es posible. Sin embargo, antes de que tu pedido haya sido procesado y haya sido enviado, puedes ingresar en el apartado de mi cuenta y en mis direcciones de envío puedes modificar las direcciones de envío.</p>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿En cuánto tiempo me entregan mi pedido?"}
                >
                    <p>Puedes consultar la fecha estimada de entrega dentro de tu pedidos ingresando a <span className='textRed'>Mi Cuenta</span>. También recibirás el número de guía y los mensajes con el progreso de tus pedidos y el seguimiento a cada uno de tus productos.</p>
                    <a href="https://www.claroshop.com/mi-cuenta" target="_blank" rel="noopener noreferrer" className='linkLandingAD'>https://www.claroshop.com/mi-cuenta/</a>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿Cómo me van a entregarme mi pedido?"}
                >
                    <p>Enviamos los productos por diversos mensajeros, para consultar cuál es el que llevará tus pedidos puedes ingresar a la sección de Mis Pedidos en <span className='textRed'>Mi Cuenta</span>. Aquí podrás conocer el número de rastreo con el que podrás dar seguimiento a la entrega.</p>
                    <a href="https://www.claroshop.com/mi-cuenta" target="_blank" rel="noopener noreferrer" className='linkLandingAD'>https://www.claroshop.com/mi-cuenta/</a>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿Qué hago si un envío excedió el tiempo de entrega?"}
                >
                    <p>Te invitamos a comunicarte a nuestro CAT para ayudarte con tu solicitud.</p>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿Con qué guía van a entregar mi pedido?"}
                >
                    <p>La guía se te hará llegar a través de correo electrónico, con ese número de rastreo podrás dar seguimiento por medio de los portales de las paqueterías.</p>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿Cómo facturo mi compra?"}
                >
                    <p>El proceso de facturación en Claroshop.com varía según el proveedor:</p>
                    <ul className="dotedList">
                        <li>Para productos de proveedores como Amigo Kit, Setory y Sanborns, puedes generar tu factura en nuestro portal en <strong className="textRed">Facturación electrónica</strong>.</li>
                        <li>Para productos de proveedores de Marketplace, el proveedor generará tu factura. Solicita al proveedor tu facturación en línea presentando RFC, Razón Social, Dirección Fiscal, Número de Pedido y Artículo a facturar.</li>
                    </ul>
                    <p>Recibirás tu factura dentro de un plazo de 30 días naturales después de realizar el primer pago.</p>
                </CardLandingAD>

                <CardLandingAD
                    tituloLanding={"¿Si deseo cancelar ¿En cuánto tiempo recibo mi reembolso?"}
                >
                    <ul className="miniTalbe">
                        <li><div className="contTitleMiniT">Tarjetas de crédito/débito:</div> Entre 3 a 10 días hábiles bancarios</li>
                        <li><div className="contTitleMiniT">Claroshop:</div> De inmediato</li>
                        <li><div className="contTitleMiniT">Crédito Telmex:</div> 24hrs posteriores al reflejo del pago y hasta 2 facturaciones siguientes</li>
                        <li><div className="contTitleMiniT">Paypal:</div> De inmediato</li>
                        <li><div className="contTitleMiniT">Otras transferencias:</div> Hasta 48 hrs hábiles</li>
                        <li><div className="contTitleMiniT">Pago en tienda con referencia o en ventanilla:</div> Hasta 48 hrs hábiles</li>
                        <li><div className="contTitleMiniT">Pago en tienda con datapad:</div> Hasta 72 hrs hábiles</li>
                        <li><div className="contTitleMiniT">Monedero Sears:</div> Hasta 48 hrs hábiles</li>
                        <li><div className="contTitleMiniT">Certificado Sanborns:</div> Hasta 72 hrs hábiles</li>
                    </ul>
                </CardLandingAD>

            </TemplateLandingAD>
        </>
    )
}