import React from 'react'
import './style.sass'

function DisclaimerNotice(props){
    const {
        descripDisclamer = '*La promoción aplica sólo a productos participantes. Precios y ofertas sujetos a cambios sin previo aviso. Hasta agotar existencias.'
    } = props

    return (
        <div className="disclaimerNotice">
            <p>{descripDisclamer}</p>
        </div>
    )
}

export default React.memo(DisclaimerNotice)